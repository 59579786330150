import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import ModalInfo from "../../components/screens/ModalInfo";
import "../screens/css/create.css";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [men_nombre, setMen_nombre] = useState("");
  const [men_descripcion, setMen_descripcion] = useState("");
  const [men_icono, setMen_icono] = useState("");
  const [men_modulo, setmen_modulo] = useState("");
  const [dataMenu, setDataMenu] = useState([]);
  const [getRowsModal, setRowsModal] = useState([]);
  const [menu, setMenu] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataTable, setdataTable] = useState([]);
  const [index, setIndex] = useState();
  const [showModal, setshowModal] = useState(false);
  const [getRowsModulos, setRowsModulos] = useState([]);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const columns = [
    {
      dataField: "MEN_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "MEN_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "MEN_ICONO",
      text: "Icono",
    },
    {
      dataField: "MEN_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "MEN_ITEMS",
      text: "Agregar",
      formatter: (cell, row) => addItem(row),
      sort: true,
    },
    {
      dataField: "MEN_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    descripcion: "",
    icono: "",
  });

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.MEN_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editMenu(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const setDataModulo = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.MO_CODIGO} value={row.MO_CODIGO}>
        {row.MO_NOMBRE}
      </option>
    ));

    setRowsModulos(rows);
  };

  const editMenu = (row, index) => {
    setMen_nombre(row.MEN_NOMBRE);
    setMen_descripcion(row.MEN_DESCRIPCION);
    setMen_icono(row.MEN_ICONO);
    setmen_modulo(row.MEN_MODULO);
    setMenu(row);
    setIndex(index);
    setshowModal(true);
  };

  const addItem = (row) => {
    return (
      <Button
        onClick={() => {
          openItemsForMenu(row);
        }}
      >
        +
      </Button>
    );
  };

  const openItemsForMenu = async (row) => {
    setIsLoading("visible");
    await axios
      .get(
        Server+"/api/menuopciones/getListMenu/" +
          row.MEN_CODIGO
      )
      .then(function (response) {
        setMenu(row);
        setRowsModal(response.data.data);
        setIsModalVisible(true);
        setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const setDataMenuModal = () => {
    menu.MEN_NOMBRE = men_nombre;
    menu.MEN_DESCRIPCION = men_descripcion;
    menu.MEN_ICONO = men_icono;
    menu.MEN_MODULO = men_modulo;

    seterror((error) => {
      return { ...error, nombre: "", descripcion: "", icono: "" };
    });
    setMen_nombre("");
    setMen_descripcion("");
    setMen_icono("");
    setshowModal(false);
    updateEstadoOp(menu, index);
  };
  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const updateEstadoModal = (itemModal, indexModal) => {
    var newEstado = 0;
    if (itemModal.OP_ESTADO === 1) {
      newEstado = 0;
    } else if (itemModal.OP_ESTADO === 0) {
      newEstado = 1;
    }

    const dataForm = new FormData();
    dataForm.append("mop_menu", menu.MEN_CODIGO);
    dataForm.append("mop_opcion", itemModal.OP_CODIGO);
    dataForm.append("mop_estado", newEstado);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/menuopciones/activeOpcionesMenu",
        dataForm
      )

      .then(function (response) {
        console.log(response);
        if (response.data.success === true) {
          showMessage(response.data.message, "success");
          let newArr = [...getRowsModal];
          newArr[indexModal].OP_ESTADO = newEstado;
          setRowsModal(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const changeEstado = async (key, index) => {
    if (key.MEN_ESTADO === 1) {
      dataMenu[index].MEN_ESTADO = 0;
      updateEstadoOp(dataMenu[index], index);
    } else if (key.MEN_ESTADO === 0) {
      dataMenu[index].MEN_ESTADO = 1;
      updateEstadoOp(dataMenu[index], index);
    }
  };

  const updateEstadoOp = (itemOpcion, index) => {
    const dataForm = new FormData();
    dataForm.append("men_id", itemOpcion.MEN_CODIGO);
    dataForm.append("men_nombre", itemOpcion.MEN_NOMBRE);
    dataForm.append("men_descripcion", itemOpcion.MEN_DESCRIPCION);
    dataForm.append("men_icono", itemOpcion.MEN_ICONO);
    dataForm.append("men_modulo", itemOpcion.MEN_MODULO);
    dataForm.append("men_estado", itemOpcion.MEN_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(Server+"/api/menu/updatemenu", dataForm)

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");
          let newArr = [...dataTable];
          newArr[index] = itemOpcion;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getData = async () => {
    setIsLoading("visible");

    await axios
      .get(Server+"/api/menu/allmenu")
      .then(function (response) {
        setDataMenu(response.data);

        setdataTable(response.data);

        setIsLoading("invisible");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  };

  useEffect(() => {
    getData();

    const getModulos = async () => {
      await axios
        .get(Server+"/api/modulos/getallModulos")
        .then(function (response) {
          setDataModulo(response.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    getModulos();
  }, []);

  const createMenu = async () => {
    seterror((error) => {
      return { ...error, nombre: "", descripcion: "", icono: "" };
    });

    if (men_nombre !== "" && men_descripcion !== "" && men_icono !== "") {
      const dataForm = new FormData();
      dataForm.append("men_nombre", men_nombre);
      dataForm.append("men_descripcion", men_descripcion);
      dataForm.append("men_icono", men_icono);
      dataForm.append("men_modulo", men_modulo);
      dataForm.append("usuario", data.US_CODIGO);

      await axios
        .post(Server+"/api/menu/create", dataForm)

        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");

            setMen_nombre("");
            setMen_descripcion("");
            setMen_icono("");

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      if (men_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (men_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }

      if (men_icono === "") {
        seterror((error) => {
          return { ...error, icono: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    //  onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar Menu
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de Menu"
                              value={men_nombre}
                              onChange={(event) =>
                                setMen_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de Menu"
                              value={men_descripcion}
                              onChange={(event) =>
                                setMen_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Icono:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Icono"
                              value={men_icono}
                              onChange={(event) =>
                                setMen_icono(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Modulo:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              as="select"
                              value={men_modulo}
                              onChange={(e) => setmen_modulo(e.target.value)}
                            >
                              {getRowsModulos}
                            </Form.Control>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDataMenuModal()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setMen_nombre("");
                          setMen_descripcion("");
                          seterror((error) => {
                            return {
                              ...error,
                              nombre: "",
                              descripcion: "",
                              icono: "",
                            };
                          });
                          setMen_icono("");
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>

                <div>
                  <ModalInfo
                    show={isModalVisible}
                    onHide={() => setIsModalVisible(false)}
                    row={1}
                    listItems={getRowsModal}
                    updateEstadoModal={updateEstadoModal}
                  >
                    {" "}
                  </ModalInfo>
                </div>
                <h3 className="text-center">Gestión de "Menú"</h3>
                <Card>
                  <Card.Header as="h5">Creación de "menú" </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de menú"
                            value={men_nombre}
                            onChange={(event) =>
                              setMen_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de menú"
                            value={men_descripcion}
                            onChange={(event) =>
                              setMen_descripcion(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Icono:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Ícono"
                            value={men_icono}
                            onChange={(event) =>
                              setMen_icono(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.icono}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Modulo:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            as="select"
                            onChange={(e) => setmen_modulo(e.target.value)}
                          >
                            <option value="0">N/A</option>
                            {getRowsModulos}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button variant="primary" onClick={() => createMenu()}>
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">Listado general de "Menús"</Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="MEN_CODIGO"
                      data={dataTable}
                      columns={columns}
                    />
                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
