import React, { Fragment, useEffect, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [rowsmModelo, setRowsmModelo] = useState([]);
  const [rowsmVersion, setrowsmVersion] = useState([]);
  const [rowsAnio, setrowsAnio] = useState([]);
  const [showversion, setshowversion] = useState(false);
  const [dataVersion, setdataVersion] = useState([]);
  const [indexVersion, setindexVersion] = useState();
  const [indexAnio, setindexAnio] = useState();
  const [dataAnio, setdataAnio] = useState([]);
  const [showanio, setshowanio] = useState(false);
  const [showvColor, setshowvColor] = useState(false);
  const [isLoading, setisLoading] = useState("invisible");
  const [isLoadingVersion, setisLoadingVersion] = useState("invisible");
  const [isLoadingAnio, setisLoadingAnio] = useState("invisible");
  const [showModalVersion, setshowModalVersion] = useState(false);
  const [showModalVersionUpdate, setshowModalVersionUpdate] = useState(false);
  const [idmodelo, setidmodelo] = useState();
  const [isLoadingCreateVer, setisLoadingCreateVer] = useState("invisible");
  const [showModalAnioCreate, setshowModalAnioCreate] = useState(false);
  const [showModalAnioUpdate, setshowModalAnioUpdate] = useState(false);
  const [showModalColorCreate, setshowModalColorCreate] = useState(false);
  const [showModalColorUpdate, setshowModalColorUpdate] = useState(false);
  const [rowsAllColor, setrowsAllColor] = useState([]);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [version, setversion] = useState({
    ver_codigo: "",
    ver_id: "",
    ver_nombre: "",
    ver_descripcion: "",
    ver_nombre_visible: "",
    ver_estado: 0,
    ver_motor: "",
    ver_consumo: "",
    ver_tranmision: "",
    ver_url_landing: "",
    ver_url_cotizador: "",
    ver_defecto: 0,
  });

  const [errorVersion, seterrorVersion] = useState({
    codigo: "",
    id: "",
    nombre: "",
    descripcion: "",
    nombrevisible: "",
    moto: "",
    consumo: "",
    transmision: "",
    url_lading: "",
    url_cotizador: "",
  });

  const [anio, setanio] = useState({
    vea_anio: "",
    vea_precio: "",
    vea_bono: "",
    vea_precio_final: "",
    vea_estado: 0,
    ver_defecto: 0,
  });

  const [erroanio, seterroanio] = useState({
    anio: "",
    precio: "",
    bono: "",
    precio_final: "",
  });

  const [color, setcolor] = useState({
    vac_estado: 0,
    vac_defecto: 0,
    vac_color: 0,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const [dataTable, setdataTable] = useState([]);

  const columns = [
    {
      dataField: "VAC_COLOR",
      text: "Codigo",
      sort: true,
    },

    {
      dataField: "COL_NOMBRE",
      text: "Nombre",
      sort: true,
    },

    {
      dataField: "COL_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editcolor(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editcolor = (row, index) => {
    setcolor((color) => {
      return {
        ...color,
        vac_estado: row.VAC_ESTADO,
        vac_defecto: row.VAC_DEFECTO,
        vac_color: row.VAC_COLOR,
      };
    });
    setshowModalColorUpdate(true);
  };

  const createVersion = async () => {
    seterrorVersion((errorVersion) => {
      return {
        ...errorVersion,
        codigo: "",
        id: "",
        nombre: "",
        descripcion: "",
        nombrevisible: "",
        moto: "",
        consumo: "",
        transmision: "",
        url_lading: "",
        url_cotizador: "",
      };
    });

    if (
      version.ver_codigo !== "" &&
      version.ver_id !== "" &&
      version.ver_nombre !== "" &&
      version.ver_descripcion !== "" &&
      version.ver_nombre_visible !== "" &&
      version.ver_motor !== "" &&
      version.ver_consumo !== "" &&
      version.ver_tranmision !== "" &&
      version.ver_url_landing !== "" &&
      version.ver_url_cotizador !== ""
    ) {
      setisLoadingCreateVer("visible");

      const dataForm = new FormData();
      dataForm.append("ver_codigo", version.ver_codigo);
      dataForm.append("ver_id", version.ver_id);
      dataForm.append("ver_nombre", version.ver_nombre);
      dataForm.append("ver_descripcion", version.ver_descripcion);
      dataForm.append("ver_nombre_visible", version.ver_nombre_visible);
      dataForm.append("ver_modelo", idmodelo);
      dataForm.append("ver_motor", version.ver_motor);
      dataForm.append("ver_defecto", version.ver_defecto);
      dataForm.append("ver_consumo", version.ver_consumo);
      dataForm.append("ver_tranmision", version.ver_tranmision);
      dataForm.append("ver_url_cotizador", version.ver_url_cotizador);
      dataForm.append("ver_url_landing", version.ver_url_landing);
      dataForm.append("usuario", data.US_CODIGO);

      await axios
        .post(
          Server+"/api/vehiculos/createNewVersion",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalVersion(false);
            showMessage(response.data.message, "success");

            getVersiones(idmodelo);

            setversion((version) => {
              return {
                ...version,
                ver_codigo: "",
                ver_id: "",
                ver_nombre: "",
                ver_descripcion: "",
                ver_nombre_visible: "",
                ver_estado: 0,
                ver_motor: "",
                ver_consumo: "",
                ver_tranmision: "",
                ver_url_landing: "",
                ver_url_cotizador: "",
                ver_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (version.ver_codigo === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, codigo: "Campo requerido" };
        });
      }

      if (version.ver_id === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, id: "Campo requerido" };
        });
      }

      if (version.ver_nombre === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, nombre: "Campo requerido" };
        });
      }

      if (version.ver_descripcion === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, descripcion: "Campo requerido" };
        });
      }

      if (version.ver_nombre_visible === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, nombrevisible: "Campo requerido" };
        });
      }

      if (version.ver_motor === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, moto: "Campo requerido" };
        });
      }

      if (version.ver_consumo === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, consumo: "Campo requerido" };
        });
      }

      if (version.ver_tranmision === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, transmision: "Campo requerido" };
        });
      }

      if (version.ver_url_landing === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, url_lading: "Campo requerido" };
        });
      }

      if (version.ver_url_cotizador === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, url_cotizador: "Campo requerido" };
        });
      }

      showMessage("Error campos incompletos", "error");
    }
  };

  const createAnio = async () => {
    seterroanio((erroanio) => {
      return { ...erroanio, anio: "", precio: "" };
    });

    if (anio.vea_anio !== "" && anio.vea_precio !== "") {
      const dataForm = new FormData();
      dataForm.append("vea_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vea_anio", anio.vea_anio);
      dataForm.append("vea_precio", anio.vea_precio);
      dataForm.append("vea_bono", anio.vea_bono);
      dataForm.append("vea_precio_final", anio.vea_precio_final);
      dataForm.append("vea_defecto", anio.vea_defecto);
      dataForm.append("usuario", data.US_CODIGO);

      setisLoadingCreateVer("visible");
      await axios
        .post(Server+"/api/vehiculos/creaAnio", dataForm)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalAnioCreate(false);
            getAnisoCar(indexVersion);
            showMessage(response.data.message, "success");

            // getVersiones(idmodelo)

            setversion((anio) => {
              return {
                ...anio,
                vea_anio: "",
                vea_precio: "",
                vea_bono: "",
                vea_precio_final: "",
                vea_estado: 0,
                vea_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (anio.vea_anio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, anio: "Campo requerido" };
        });
      }

      if (anio.vea_precio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      if (anio.vea_bono === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      if (anio.vea_precio_final === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const updateVersion = async () => {
    seterrorVersion((errorVersion) => {
      return {
        ...errorVersion,
        codigo: "",
        id: "",
        nombre: "",
        descripcion: "",
        nombrevisible: "",
        moto: "",
        consumo: "",
        transmision: "",
        url_lading: "",
        url_cotizador: "",
      };
    });

    if (
      version.ver_codigo !== "" &&
      version.ver_id !== "" &&
      version.ver_nombre !== "" &&
      version.ver_descripcion !== "" &&
      version.ver_nombre_visible !== "" &&
      version.ver_motor !== "" &&
      version.ver_consumo !== "" &&
      version.ver_tranmision !== "" &&
      version.ver_url_landing !== "" &&
      version.ver_url_cotizador !== ""
    ) {
      setisLoadingCreateVer("visible");

      const dataForm = new FormData();
      dataForm.append("ver_codigo", version.ver_codigo);
      dataForm.append("ver_nombre", version.ver_nombre);
      dataForm.append("ver_descripcion", version.ver_descripcion);
      dataForm.append("ver_nombre_visible", version.ver_nombre_visible);
      dataForm.append("ver_modelo", idmodelo);
      dataForm.append("ver_motor", version.ver_motor);
      dataForm.append("ver_defecto", version.ver_defecto);
      dataForm.append("ver_estado", version.ver_estado);
      dataForm.append("ver_consumo", version.ver_consumo);
      dataForm.append("ver_tranmision", version.ver_tranmision);
      dataForm.append("ver_url_cotizador", version.ver_url_cotizador);
      dataForm.append("ver_url_landing", version.ver_url_landing);
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      await axios
        .post(
          Server+"/api/vehiculos/updateVersion",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");

            setshowModalVersionUpdate(false);

            showMessage(response.data.message, "success");

            getVersiones(idmodelo);

            setversion((version) => {
              return {
                ...version,
                ver_codigo: "",
                ver_id: "",
                ver_nombre: "",
                ver_descripcion: "",
                ver_nombre_visible: "",
                ver_estado: 0,
                ver_motor: "",
                ver_consumo: "",
                ver_tranmision: "",
                ver_url_landing: "",
                ver_url_cotizador: "",
                ver_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      console.log("$$$$$");
      console.log(version.ver_consumo);

      if (version.ver_nombre === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, nombre: "Campo requerido" };
        });
      }

      if (version.ver_descripcion === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, descripcion: "Campo requerido" };
        });
      }

      if (version.ver_nombre_visible === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, nombrevisible: "Campo requerido" };
        });
      }

      if (version.ver_motor === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, moto: "Campo requerido" };
        });
      }

      if (version.ver_consumo === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, consumo: "Campo requerido" };
        });
      }

      if (version.ver_tranmision === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, transmision: "Campo requerido" };
        });
      }

      if (version.ver_url_landing === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, url_lading: "Campo requerido" };
        });
      }

      if (version.ver_url_cotizador === "") {
        seterrorVersion((errorVersion) => {
          return { ...errorVersion, url_cotizador: "Campo requerido" };
        });
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const updateAnio = async () => {
    seterroanio((erroanio) => {
      return { ...erroanio, anio: "", precio: "", bono: "", precio_final: "" };
    });

    if (anio.vea_anio !== "" && anio.vea_precio !== "") {
      const dataForm = new FormData();
      dataForm.append("vea_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vea_anio", anio.vea_anio);
      dataForm.append("vea_precio", anio.vea_precio);
      dataForm.append("vea_bono", anio.vea_bono);
      dataForm.append("vea_precio_final", anio.vea_precio_final);
      dataForm.append("vea_defecto", anio.vea_defecto);
      dataForm.append("vea_estado", String(anio.vea_estado));
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      setisLoadingCreateVer("visible");
      await axios
        .post(
          Server+"/api/vehiculos/updateAnio",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalAnioUpdate(false);
            showMessage(response.data.message, "success");
            getAnisoCar(indexVersion);
            setversion((anio) => {
              return {
                ...anio,
                vea_anio: "",
                vea_precio: "",
                vea_bono: "",
                vea_precio_final: "",
                vea_estado: 0,
                vea_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (anio.vea_precio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo Requerido" };
        });
      } else {
        if (anio.vea_bono === "") {
          seterroanio((erroanio) => {
            return { ...erroanio, bono: "Campo Requerido" };
          });
        } else {
          if (anio.vea_precio_final === "") {
            seterroanio((erroanio) => {
              return { ...erroanio, precio_final: "Campo Requerido" };
            });
          }
        }
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const createColor = async () => {
    if (color.vac_color >= 0) {
      const dataForm = new FormData();
      dataForm.append("vac_defecto", color.vac_defecto);
      dataForm.append("vac_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vac_color", color.vac_color);
      dataForm.append("vac_anio", dataAnio[indexAnio].VEA_ANIO);
      dataForm.append("usuario", data.US_CODIGO);

      setisLoadingCreateVer("visible");
      await axios
        .post(
          Server+"/api/vehiculos/crearColorAuto",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalColorCreate(false);

            getAnisoCarColor(indexAnio);
            showMessage(response.data.message, "success");

            setcolor((color) => {
              return { ...color, vac_estado: 0, vac_defecto: 0, vac_color: 0 };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      showMessage("Seleccione un Color", "error");
    }
  };

  const updateColor = async () => {
    setisLoadingCreateVer("visible");

    const dataForm = new FormData();
    dataForm.append("vac_defecto", color.vac_defecto);
    dataForm.append("vac_version", dataVersion[indexVersion].VER_CODIGO);
    dataForm.append("vac_color", color.vac_color);
    dataForm.append("vac_anio", dataAnio[indexAnio].VEA_ANIO);
    dataForm.append("vac_estado", color.vac_estado);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    await axios
      .post(
        Server+"/api/vehiculos/updateColorAuto",
        dataForm
      )

      .then(function (response) {
        if (response.data.success) {
          setisLoadingCreateVer("invisible");
          setshowModalColorUpdate(false);
          getAnisoCarColor(indexAnio);
          showMessage(response.data.message, "success");

          setcolor((color) => {
            return { ...color, vac_estado: 0, vac_defecto: 0, vac_color: 0 };
          });
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        setisLoadingCreateVer("invisible");
        showMessage("Error en el Intentalo de nuevo", "error");
      });
  };

  useEffect(() => {
    const getModelos = async () => {
      await axios
        .get(Server+"/api/vehiculos/getAllModelos")
        .then(function (response) {
          setDataModelos(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    getModelos();
  }, []);

  const setDataModelos = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.MOD_CODIGO} value={row.MOD_CODIGO}>
        {row.MOD_NOMBRE}
      </option>
    ));

    setRowsmModelo(rows);
  };

  const setDataVersiones = (data) => {
    setshowversion(false);
    setshowanio(false);

    const rows = data.map((row, index) => (
      <option key={row.VER_CODIGO} value={index}>
        {row.VER_NOMBRE}
      </option>
    ));

    setrowsmVersion(rows);
    setdataVersion(data);
    setshowversion(true);
  };

  const setDataAnio = (data) => {
    const rows = data.map((row, index) => (
      <option key={index} value={index}>
        {row.VEA_ANIO}
      </option>
    ));

    setrowsAnio(rows);
    setdataAnio(data);
    setshowanio(true);
  };

  const setDataAllColor = (data) => {
    const rows = data.map((row, index) => (
      <option key={index} value={row.COL_CODIGO}>
        {row.COL_NOMBRE}
      </option>
    ));

    setrowsAllColor(rows);
  };

  const getVersiones = async (codigo) => {
    setshowversion(false);
    setshowanio(false);
    setshowvColor(false);
    setisLoading("visible");
    setidmodelo(codigo);

    await axios
      .get(
        Server+"/api/vehiculos/getVersionForModelo/" +
          codigo
      )
      .then(function (response) {
        setDataVersiones(response.data.data);
        setisLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getAnisoCar = async (index) => {
    setindexVersion(index);

    setshowanio(false);
    setshowvColor(false);

    if (index >= 0) {
      setisLoadingVersion("visible");

      await axios
        .get(
          Server+"/api/vehiculos/getVersionForAnios/" +
            dataVersion[index].VER_CODIGO
        )
        .then(function (response) {
          setDataAnio(response.data.data);
          setisLoadingVersion("invisible");
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const getAllColor = async () => {
    await axios
      .get(Server+"/api/vehiculos/getAllColor")
      .then(function (response) {
        setDataAllColor(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const getAnisoCarColor = async (index) => {
    setindexAnio(index);
    setshowvColor(false);

    if (index >= 0) {
      setisLoadingAnio("visible");

      await axios
        .get(
          Server+"/api/vehiculos/getAllColorModelo/" +
            dataVersion[indexVersion].VER_CODIGO +
            "/" +
            dataAnio[index].VEA_ANIO
        )
        .then(function (response) {
          setdataTable(response.data.data);
          setshowvColor(true);
          getAllColor();
          setisLoadingAnio("invisible");
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const drawSelectAnio = () => {
    if (showanio) {
      return (
        <div style={{ marginTop: 15 }}>
          <Card>
            <Card.Body>
              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <h3 className="text-center">Selecciona un Año</h3>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      setshowModalAnioCreate(true);
                      setanio((anio) => {
                        return {
                          ...anio,
                          vea_anio: "",
                          vea_precio: "",
                          vea_bono: "",
                          vea_precio_final: "",
                          vea_estado: 0,
                          vea_defecto: 0,
                        };
                      });
                    }}
                    variant="primary"
                  >
                    Agregar Nuevo Año
                  </Button>
                </Col>
              </FormGroup>

              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      getAnisoCarColor(e.target.value);
                    }}
                  >
                    <option value="-1">N/A</option>
                    {rowsAnio}
                  </Form.Control>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      if (indexAnio >= 0) {
                        setshowModalAnioUpdate(true);
                        setanio((anio) => {
                          return {
                            ...anio,
                            vea_precio: dataAnio[indexAnio].VEA_PRECIO_PVP,
                            vea_bono: dataAnio[indexAnio].VEA_BONO,
                            vea_precio_final:
                              dataAnio[indexAnio].VEA_PRECIO_FINAL,
                            vea_anio: dataAnio[indexAnio].VEA_ANIO,
                            vea_defecto: dataAnio[indexAnio].VEA_DEFECTO,
                            vea_estado: Number(dataAnio[indexAnio].VEA_ESTADO),
                          };
                        });
                      } else {
                        showMessage(
                          "Seleccione un Año para actualizar",
                          "error"
                        );
                      }
                    }}
                    variant="primary"
                  >
                    Actualizar Precios
                  </Button>
                </Col>
              </FormGroup>

              <div className="text-center">
                <div className={isLoadingAnio} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const drawSelectVersion = () => {
    if (showversion) {
      return (
        <div style={{ marginTop: 25 }}>
          <Card>
            <Card.Body>
              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <h3 className="text-center">Selecciona un Versión</h3>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      setshowModalVersion(true);
                      setversion((version) => {
                        return {
                          ...version,
                          ver_codigo: "",
                          ver_id: "",
                          ver_nombre: "",
                          ver_descripcion: "",
                          ver_nombre_visible: "",
                          ver_estado: 0,
                          ver_motor: "",
                          ver_consumo: "",
                          ver_tranmision: "",
                          ver_url_landing: "",
                          ver_url_cotizador: "",
                          ver_defecto: 0,
                        };
                      });
                    }}
                    variant="primary"
                  >
                    Agregar Nueva Version
                  </Button>
                </Col>
              </FormGroup>

              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      getAnisoCar(e.target.value);
                    }}
                  >
                    <option value="-1">N/A</option>
                    {rowsmVersion}
                  </Form.Control>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      if (indexVersion >= 0) {
                        setshowModalVersionUpdate(true);
                        setversion((version) => {
                          return {
                            ...version,
                            ver_codigo: dataVersion[indexVersion].VER_CODIGO,
                            ver_id: dataVersion[indexVersion].VER_ID,
                            ver_nombre: dataVersion[indexVersion].VER_NOMBRE,
                            ver_descripcion:
                              dataVersion[indexVersion].VER_DESCRIPCION,
                            ver_nombre_visible:
                              dataVersion[indexVersion].VER_NOMBRE_VISIBLE,

                            ver_estado:
                              dataVersion[indexVersion].VER_ACTIVO_SHOWROOM !==
                              null
                                ? dataVersion[indexVersion].VER_ACTIVO_SHOWROOM
                                : 0,
                            ver_motor: dataVersion[indexVersion].VER_MOTOR,
                            ver_defecto:
                              dataVersion[indexVersion].VER_VERSION_DEFECTO,
                            ver_consumo:
                              dataVersion[indexVersion].VER_CONSUMO !== null
                                ? dataVersion[indexVersion].VER_CONSUMO
                                : "",
                            // request->alm_telefono_contacto !== null ? $request->alm_telefono_contacto : ''
                            // ver_consumo: dataVersion[indexVersion].VER_CONSUMO,
                            ver_tranmision:
                              dataVersion[indexVersion].VER_TRANSMISION !== null
                                ? dataVersion[indexVersion].VER_TRANSMISION
                                : "",

                            ver_url_landing:
                              dataVersion[indexVersion].VER_URL_LANDING !== null
                                ? dataVersion[indexVersion].VER_URL_LANDING
                                : "",

                            ver_url_cotizador:
                              dataVersion[indexVersion].VER_URL_COTIZADOR !==
                              null
                                ? dataVersion[indexVersion].VER_URL_COTIZADOR
                                : "",
                          };
                        });
                      } else {
                        showMessage(
                          "Seleccione una versión para actualizar",
                          "error"
                        );
                      }
                    }}
                    variant="primary"
                  >
                    Actualizar esta versión
                  </Button>
                </Col>
              </FormGroup>

              <div className="text-center">
                <div className={isLoadingVersion} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const drawTableColor = () => {
    if (showvColor) {
      return (
        <div style={{ marginTop: 15 }}>
          <Card>
            <Card.Body>
              <h3 className="text-center">Lista de Color Por Version - Año</h3>

              <Button
                onClick={() => {
                  setshowModalColorCreate(true);
                  setcolor((color) => {
                    return {
                      ...color,
                      vac_estado: 0,
                      vac_defecto: 0,
                      vac_color: -1,
                    };
                  });
                }}
                variant="primary"
              >
                Agregar un Nuevo Color{" "}
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="VAC_COLOR"
                  data={dataTable}
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Vehiculo/ Versiones"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />

          <div>
            <Card>
              <Card.Body>
                <h3 className="text-center">Selecciona un Modelo</h3>

                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getVersiones(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {rowsmModelo}
                </Form.Control>

                <div className="text-center">
                  <div className={isLoading} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div>{drawSelectVersion()}</div>
          <div>{drawSelectAnio()}</div>

          <div>{drawTableColor()}</div>

          <div>
            <Modal
              show={showModalVersion}
              // onHide={!showModalVersion}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Version
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Código: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Código"
                        id="ver_codigo"
                        value={version.ver_codigo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_codigo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.codigo}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">ID: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese ID"
                        id="ver_id"
                        value={version.ver_id}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_id: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorVersion.id}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Nombre: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre"
                        id="ver_nombre"
                        value={version.ver_nombre}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.nombre}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Descripción:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Descripción"
                        id="ver_descripcion"
                        value={version.ver_descripcion}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_descripcion: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.descripcion}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Nombre Visible:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre Visible"
                        id="ver_nombre_visible"
                        value={version.ver_nombre_visible}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre_visible: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.nombrevisible}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Motor: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Motor"
                        id="ver_motor"
                        value={version.ver_motor}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_motor: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorVersion.moto}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Ver Consumo:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Consumo"
                        id="ver_consumo"
                        value={version.ver_consumo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_consumo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.consumo}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Ver Transmisión:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Transmisión"
                        id="ver_transmision"
                        value={version.ver_tranmision}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_tranmision: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.transmision}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Url Landing:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Landing"
                        id="url_landing"
                        value={version.ver_url_landing}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_landing: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.url_lading}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Url Cotizador:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Cotizador"
                        id="url_cotizador"
                        value={version.ver_url_cotizador}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_cotizador: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.url_cotizador}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto:"
                        checked={setEstado(version.ver_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (version.ver_defecto === 0) {
                            newStatus = 1;
                          } else if (version.ver_defecto === 1) {
                            newStatus = 0;
                          }
                          setversion((version) => {
                            return { ...version, ver_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createVersion();
                  }}
                >
                  Guardar
                </Button>
                <Button
                  onClick={() => {
                    setshowModalVersion(false);

                    seterrorVersion((errorVersion) => {
                      return {
                        ...errorVersion,
                        codigo: "",
                        id: "",
                        nombre: "",
                        descripcion: "",
                        nombrevisible: "",
                        moto: "",
                        consumo: "",
                        transmision: "",
                        url_lading: "",
                        url_cotizador: "",
                      };
                    });

                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalVersionUpdate}
              // onHide={!showModalVersionUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualiza Version
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Nombre: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre"
                        id="ver_nombre"
                        value={version.ver_nombre}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.nombre}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Descripción:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Descripción"
                        id="ver_descripcion"
                        value={version.ver_descripcion}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_descripcion: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.descripcion}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Nombre Visible:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre Visible"
                        id="ver_nombre_visible"
                        value={version.ver_nombre_visible}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre_visible: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.nombrevisible}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Motor: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Motor"
                        id="ver_motor"
                        value={version.ver_motor}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_motor: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorVersion.moto}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Ver Consumo:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Consumo"
                        id="ver_consumo"
                        value={version.ver_consumo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_consumo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.consumo}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Ver Transmisión:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Transmisión"
                        id="ver_transmision"
                        value={version.ver_tranmision}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_tranmision: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.transmision}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Url Landing:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Landing"
                        id="url_landing"
                        value={version.ver_url_landing}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_landing: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.url_lading}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Url Cotizador:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Cotizador"
                        id="url_cotizador"
                        value={version.ver_url_cotizador}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_cotizador: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorVersion.url_cotizador}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto:"
                        checked={setEstado(version.ver_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (version.ver_defecto === 0) {
                            newStatus = 1;
                          } else if (version.ver_defecto === 1) {
                            newStatus = 0;
                          }
                          setversion((version) => {
                            return { ...version, ver_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Estado"
                        checked={setEstado(version.ver_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (version.ver_estado === 0) {
                            newStatus = 1;
                          } else if (version.ver_estado === 1) {
                            newStatus = 0;
                          }
                          setversion((version) => {
                            return { ...version, ver_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    updateVersion();
                  }}
                >
                  Actualizar
                </Button>
                <Button
                  onClick={() => {
                    setshowModalVersionUpdate(false);

                    seterrorVersion((errorVersion) => {
                      return {
                        ...errorVersion,
                        codigo: "",
                        id: "",
                        nombre: "",
                        descripcion: "",
                        nombrevisible: "",
                        moto: "",
                        consumo: "",
                        transmision: "",
                        url_lading: "",
                        url_cotizador: "",
                      };
                    });
                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalAnioCreate}
              // onHide={!showModalAnioCreate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Crear Año Nuevo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Año: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Año"
                        id="ver_anio_anio"
                        value={anio.vea_anio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_anio: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>{erroanio.anio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Precio PVP:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio PVP"
                        id="ver_anio_precio"
                        value={anio.vea_precio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Bono: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Bono"
                        id="ver_anio_bono"
                        value={anio.vea_bono}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_bono: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Precio Final:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio Final"
                        id="ver_anio_precio_final"
                        value={anio.vea_precio_final}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio_final: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto:"
                        checked={setEstado(anio.vea_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_defecto === 0) {
                            newStatus = 1;
                          } else if (anio.vea_defecto === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createAnio();
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={() => {
                    setshowModalAnioCreate(false);

                    seterroanio((erroanio) => {
                      return { ...erroanio, anio: "", precio: "" };
                    });
                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalAnioUpdate}
              // onHide={!showModalAnioUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualizar Precios
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Precio PVP:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio PVP"
                        id="ver_anio_precio"
                        value={anio.vea_precio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Bono: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Bono"
                        id="ver_anio_bono"
                        value={anio.vea_bono}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_bono: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.bono}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Precio Final:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio Final"
                        id="ver_anio_precio_final"
                        value={anio.vea_precio_final}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio_final: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {erroanio.precio_final}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto"
                        checked={setEstado(anio.vea_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_defecto === 0) {
                            newStatus = 1;
                          } else if (anio.vea_defecto === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Estado"
                        checked={setEstado(anio.vea_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_estado === 0) {
                            newStatus = 1;
                          } else if (anio.vea_estado === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    updateAnio();
                  }}
                >
                  Actualizar
                </Button>
                <Button
                  onClick={() => {
                    setshowModalAnioUpdate(false);

                    seterroanio((erroanio) => {
                      return { ...erroanio, anio: "", precio: "" };
                    });

                    setanio((anio) => {
                      return {
                        ...anio,
                        vea_anio: "",
                        vea_precio: "",
                        vea_bono: "",
                        vea_precio_final: "",
                        vea_estado: 0,
                        vea_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalColorCreate}
              // onHide={!showModalColorCreate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Crear un Nuevo Color
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          const val = e.target.value;
                          setcolor((color) => {
                            return { ...color, vac_color: val };
                          });
                        }}
                      >
                        <option value="-1">N/A</option>
                        {rowsAllColor}
                      </Form.Control>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color por Defecto"
                        checked={setEstado(color.vac_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_defecto === 0) {
                            newStatus = 1;
                          } else if (color.vac_defecto === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createColor();
                  }}
                >
                  Crear Color
                </Button>
                <Button
                  onClick={() => {
                    setshowModalColorCreate(false);
                    setcolor((color) => {
                      return {
                        ...color,
                        vac_estado: 0,
                        vac_defecto: 0,
                        vac_color: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalColorUpdate}
              // onHide={!showModalColorUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualizar Color
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color por Defecto"
                        checked={setEstado(color.vac_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_defecto === 0) {
                            newStatus = 1;
                          } else if (color.vac_defecto === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color Activo"
                        checked={setEstado(color.vac_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_estado === 0) {
                            newStatus = 1;
                          } else if (color.vac_estado === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    updateColor();
                  }}
                >
                  Actualizar Color
                </Button>
                <Button
                  onClick={() => {
                    setshowModalColorUpdate(false);
                    setcolor((color) => {
                      return {
                        ...color,
                        vac_estado: 0,
                        vac_defecto: 0,
                        vac_color: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
