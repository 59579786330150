import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Button,
  Card,
  Spinner,
  Form,
} from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content(props) {
  const [items, setitems] = useState([]);
  const [itemsEmpresa, setitemsEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [showNoticasEmpresas, setshowNoticasEmpresas] = useState(false);
  const [idAlmacen, setidAlmacen] = useState([]);
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const grid = 8;

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items2 = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setitems(items2);
  };

  const onDragEndEmpresa = (result) => {
    if (!result.destination) {
      return;
    }
    const items2 = reorder(
      itemsEmpresa,
      result.source.index,
      result.destination.index
    );

    setitemsEmpresa(items2);
  };

  const getListStyle = (isDraggingOver) => ({
    padding: grid,
    width: props.width,
  });

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? "#002c5f" : "#c4c4c4",
    ...draggableStyle,
  });

  const updateOrden = () => {
    setIsLoading("visible");

    const dataForm = new FormData();
    dataForm.append("list_orden", JSON.stringify(items));
    dataForm.append("empresa", JSON.stringify(0));
    dataForm.append("usuario", JSON.stringify(data.US_CODIGO));
    dataForm.append("_method", "PUT");

    axios
      .post(
        Server+"/api/empresas/setNuevoOrdenNoticiasCarrusel",
        dataForm
      )

      .then(function (response) {
        setIsLoading("invisible");
        if (response.data.success === true) {
          showMessage(response.data.message, "success");
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const updateOrdenEmpresa = () => {
    setIsLoading("visible");

    const dataForm = new FormData();
    dataForm.append("list_orden", JSON.stringify(itemsEmpresa));
    dataForm.append("empresa", idAlmacen);
    dataForm.append("usuario", JSON.stringify(data.US_CODIGO));
    dataForm.append("_method", "PUT");

    axios
      .post(
        Server+"/api/empresas/setNuevoOrdenNoticiasCarrusel",
        dataForm
      )

      .then(function (response) {
        setIsLoading("invisible");
        if (response.data.success === true) {
          showMessage(response.data.message, "success");
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getList = async (idAlmacen) => {
    setshowNoticasEmpresas(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getNoticasOrdenEmpresaCarrusel/" +
            idAlmacen
        )
        .then(function (response) {
          setitemsEmpresa(Array.from(response.data));
          setIsLoading("invisible");
          setshowNoticasEmpresas(true);
          setidAlmacen(idAlmacen);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(
        Server+"/api/empresas/getNoticasOrdenMarcaCarrusel"
      )
      .then(function (response) {
        setIsLoading("invisible");
        setitems(Array.from(response.data));
      })
      .catch(function (error) {
        setIsLoading("invisible");
      })
      .then(function () {});
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const listNoticasEmpresa = () => {
    if (showNoticasEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Orden de Noticias</Card.Header>
            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <DragDropContext onDragEnd={onDragEndEmpresa}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {itemsEmpresa.map((item, index) => (
                          <Draggable
                            key={item.NOTE_CODIGO.toString()}
                            draggableId={item.NOTE_CODIGO.toString()}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                {item.NOT_TITULO}
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <div className="text-center">
                <Button variant="primary" onClick={() => updateOrdenEmpresa()}>
                  Guardar cambios
                </Button>
              </div>

              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setIsLoading("invisible");
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {
          setIsLoading("invisible");
        })
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getData();
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowNoticasEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <Col md="12" lg="12">
          <Card>
            <Card.Header as="h5">Orden Noticias de Marca</Card.Header>

            <Card.Body>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.NOT_CODIGO.toString()}
                          draggableId={item.NOT_CODIGO.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.NOT_TITULO}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Card.Body>

            <div className="text-center">
              <Button variant="primary" onClick={() => updateOrden()}>
                Guardar cambios
              </Button>
            </div>

            <div className="text-center">
              <div className={isLoading} style={{ marginTop: 10 }}>
                <Spinner
                  animation="border"
                  role="status"
                  variant="secondary"
                ></Spinner>
              </div>
            </div>
          </Card>

          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus Noticias
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {listNoticasEmpresa()}
        </Col>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return (
        <Col md="12" lg="12">
          {listNoticasEmpresa()}
        </Col>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home / Carrusel"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              {marcaoEmpresa()}
              {/* <Col md="12" lg="12">
                

              <Card >
              <Card.Header as="h5">Orden Noticias de Marca</Card.Header>
              
              <Card.Body>

              <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Draggable key={(item.NOT_CODIGO).toString()} draggableId={(item.NOT_CODIGO).toString()} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      {item.NOT_TITULO}
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

            </Card.Body>

            <div className="text-center">
                    <Button variant="primary" onClick={() => updateOrden()}>
                        Guardar cambios
                </Button>
                </div>

                <div className="text-center">
                          <div className={isLoading} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>
            </Card>



            <div style={{marginTop:10}}>

            <Card>
            <Card.Header as="h5">Elija una empresa Para Ver sus Noticias</Card.Header>
            <Card.Body>

              <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                      <option value="0">N/A</option>
                      {getRowsEmpresas}

                </Form.Control>

                <div style={{marginTop:5}} className="text-center">
                <div className={isLoading}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            </div>
                </Card.Body>
                </Card>
              </div>

              {listNoticasEmpresa()}

              </Col> */}
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
