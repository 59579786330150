import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Form,
  Card,
  FormGroup,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import * as SecureLS from "secure-ls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import {Server} from "../../constants";

function Content() {
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [isLoadingCrear, setisLoadingCrear] = useState("invisible");
  const [showTable, setshowTable] = useState(false);
  const [showModalUpdate, setshowModalUpdate] = useState(false);
  const [showModal, setshowModal] = useState(false);
  const [getRowsCiudades, setRowsCiudades] = useState([]);
  // const [empresa, setempresa] = useState();

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [almacen, setalmacen] = useState({
    alm_empresa: 0,
    alm_estado: 0,
    alm_codigo: "",
    alm_id: "",
    alm_nombre: "",
    alm_ciudad: "",
    alm_direccion: "",
    alm_telefono: "",
    alm_email: "",
    alm_contacto: "",
    alm_email_contacto: "",
    alm_telefono_contacto: "",
    alm_contacto_taller: "",
    alm_email_contacto_taller: "",
    alm_telefono_contacto_taller: "",
    alm_tipo: "",
    alm_categoria: "",
    alm_horario_lun_vier: "",
    alm_horario_sab: "",
    alm_horario_dom: "",
    alm_horario_feriado: "",
    alm_horario_lun_vier_taller: "",
    alm_horario_sab_taller: "",
    alm_horario_dom_taller: "",
    alm_horario_feriado_taller: "",
  });

  const columns = [
    {
      dataField: "ALM_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "ALM_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "ALM_DIRECCION",
      text: "Dirección",
      sort: true,
    },
    {
      dataField: "ALM_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const setDataCiudad = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.CIU_CODIGO} value={row.CIU_CODIGO}>
        {row.CIU_NOMBRE}
      </option>
    ));
    setRowsCiudades(rows);
  };

  const edit = (row, index) => {
    setalmacen((almacen) => {
      return {
        ...almacen,
        alm_empresa: row.ALM_EMPRESA,
        alm_estado: row.ALM_ESTADO,
        alm_codigo: row.ALM_CODIGO,
        alm_id: row.ALM_ID,
        alm_nombre: row.ALM_NOMBRE,
        alm_ciudad: row.ALM_CIUDAD,
        alm_direccion: row.ALM_DIRECCION,
        alm_telefono: row.ALM_TELEFONO,
        alm_email: row.ALM_EMAIL,
        alm_contacto: row.ALM_CONTACTO,
        alm_email_contacto: row.ALM_EMAIL_CONTACTO,
        alm_telefono_contacto: row.ALM_TELEFONO_CONTACTO,
        alm_contacto_taller: row.ALM_CONTACTO_TALLER,
        alm_email_contacto_taller: row.ALM_EMAIL_CONTACTO_TALLER,
        alm_telefono_contacto_taller: row.ALM_TELEFONO_CONTACTO_TALLER,
        alm_tipo: row.ALM_TIPO,
        alm_categoria: row.ALM_CATEGORIA,
        alm_horario_lun_vier: row.ALM_HORARIO_LUN_VIER,
        alm_horario_sab: row.ALM_HORARIO_SAB,
        alm_horario_dom: row.ALM_HORARIO_DOM,
        alm_horario_feriado: row.ALM_HORARIO_FERIADO,
        alm_horario_lun_vier_taller: row.ALM_HORARIO_LUN_VIER_TALLER,
        alm_horario_sab_taller: row.ALM_HORARIO_SAB_TALLER,
        alm_horario_dom_taller: row.ALM_HORARIO_DOM_TALLER,
        alm_horario_feriado_taller: row.ALM_HORARIO_FERIADO_TALLER,
      };
    });

    setshowModalUpdate(true);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const crearAlmacen = () => {
    if (
      almacen.alm_codigo !== "" &&
      almacen.alm_id !== "" &&
      almacen.alm_nombre !== "" &&
      almacen.alm_direccion !== "" &&
      almacen.alm_telefono !== "" &&
      almacen.alm_email !== "" &&
      almacen.alm_ciudad !== "0" &&
      almacen.alm_tipo !== "0" &&
      almacen.alm_categoria !== "0"
    ) {
      setisLoadingCrear("visible");

      const dataForm = new FormData();
      dataForm.append("alm_empresa", almacen.alm_empresa);
      dataForm.append("alm_estado", almacen.alm_estado);
      dataForm.append("alm_codigo", almacen.alm_codigo);
      dataForm.append("alm_id", almacen.alm_id);
      dataForm.append("alm_nombre", almacen.alm_nombre);
      dataForm.append("alm_ciudad", almacen.alm_ciudad);
      dataForm.append("alm_direccion", almacen.alm_direccion);
      dataForm.append("alm_telefono", almacen.alm_telefono);
      dataForm.append("alm_email", almacen.alm_email);
      dataForm.append("alm_contacto", almacen.alm_contacto);
      dataForm.append("alm_email_contacto", almacen.alm_email_contacto);
      dataForm.append("alm_telefono_contacto", almacen.alm_telefono_contacto);
      dataForm.append("alm_contacto_taller", almacen.alm_contacto_taller);
      dataForm.append(
        "alm_email_contacto_taller",
        almacen.alm_email_contacto_taller
      );
      dataForm.append(
        "alm_telefono_contacto_taller",
        almacen.alm_telefono_contacto_taller
      );
      dataForm.append("alm_tipo", almacen.alm_tipo);
      dataForm.append("alm_categoria", Number(almacen.alm_categoria));
      dataForm.append("alm_horario_lun_vier", almacen.alm_horario_lun_vier);
      dataForm.append("alm_horario_sab", almacen.alm_horario_sab);
      dataForm.append("alm_horario_dom", almacen.alm_horario_dom);
      dataForm.append("alm_horario_feriado", almacen.alm_horario_feriado);
      dataForm.append(
        "alm_horario_lun_vier_taller",
        almacen.alm_horario_lun_vier_taller
      );
      dataForm.append("alm_horario_sab_taller", almacen.alm_horario_sab_taller);
      dataForm.append("alm_horario_dom_taller", almacen.alm_horario_dom_taller);
      dataForm.append(
        "alm_horario_feriado_taller",
        almacen.alm_horario_feriado_taller
      );
      dataForm.append("usuario", data.US_CODIGO);

      axios
        .post(
          Server+"/api/empresas/createAlmacen",
          dataForm
        )

        .then(function (response) {
          setisLoadingCrear("invisible");

          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            setalmacen((almacen) => {
              return {
                ...almacen,
                alm_estado: 0,
                alm_codigo: "",
                alm_id: "",
                alm_nombre: "",
                alm_ciudad: "",
                alm_direccion: "",
                alm_telefono: "",
                alm_email: "",
                alm_contacto: "",
                alm_email_contacto: "",
                alm_telefono_contacto: "",
                alm_contacto_taller: "",
                alm_email_contacto_taller: "",
                alm_telefono_contacto_taller: "",
                alm_tipo: "",
                alm_categoria: "",
                alm_horario_lun_vier: "",
                alm_horario_sab: "",
                alm_horario_dom: "",
                alm_horario_feriado: "",
                alm_horario_lun_vier_taller: "",
                alm_horario_sab_taller: "",
                alm_horario_dom_taller: "",
                alm_horario_feriado_taller: "",
              };
            });
            getList(almacen.alm_empresa);
            setshowModal(false);
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCrear("invisible");
          showMessage("Error Intentelo de Nuevo", "error");
        });
    } else {
      showMessage("Campos Vacios ", "error");
    }
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const updateAlmacen = () => {
    if (
      almacen.alm_codigo !== "" &&
      almacen.alm_id !== "" &&
      almacen.alm_nombre !== "" &&
      almacen.alm_direccion !== "" &&
      almacen.alm_telefono !== "" &&
      almacen.alm_email !== "" &&
      almacen.alm_ciudad !== "0" &&
      almacen.alm_tipo !== "0" &&
      almacen.alm_categoria !== "0"
    ) {
      setisLoadingCrear("visible");

      const dataForm = new FormData();
      dataForm.append("alm_estado", almacen.alm_estado);
      dataForm.append("alm_empresa", almacen.alm_empresa);
      dataForm.append("alm_codigo", almacen.alm_codigo);
      dataForm.append("alm_id", almacen.alm_id);
      dataForm.append("alm_nombre", almacen.alm_nombre);
      dataForm.append("alm_ciudad", almacen.alm_ciudad);
      dataForm.append("alm_direccion", almacen.alm_direccion);
      dataForm.append("alm_telefono", almacen.alm_telefono);
      dataForm.append("alm_email", almacen.alm_email);
      dataForm.append("alm_contacto", almacen.alm_contacto);
      dataForm.append("alm_email_contacto", almacen.alm_email_contacto);
      dataForm.append("alm_telefono_contacto", almacen.alm_telefono_contacto);
      dataForm.append("alm_contacto_taller", almacen.alm_contacto_taller);
      dataForm.append(
        "alm_email_contacto_taller",
        almacen.alm_email_contacto_taller
      );
      dataForm.append(
        "alm_telefono_contacto_taller",
        almacen.alm_telefono_contacto_taller
      );
      dataForm.append("alm_tipo", almacen.alm_tipo);
      dataForm.append("alm_categoria", Number(almacen.alm_categoria));
      dataForm.append("alm_horario_lun_vier", almacen.alm_horario_lun_vier);
      dataForm.append("alm_horario_sab", almacen.alm_horario_sab);
      dataForm.append("alm_horario_dom", almacen.alm_horario_dom);
      dataForm.append("alm_horario_feriado", almacen.alm_horario_feriado);
      dataForm.append(
        "alm_horario_lun_vier_taller",
        almacen.alm_horario_lun_vier_taller
      );
      dataForm.append("alm_horario_sab_taller", almacen.alm_horario_sab_taller);
      dataForm.append("alm_horario_dom_taller", almacen.alm_horario_dom_taller);
      dataForm.append(
        "alm_horario_feriado_taller",
        almacen.alm_horario_feriado_taller
      );
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      axios
        .post(
          Server+"/api/empresas/UpdateAlmacen",
          dataForm
        )

        .then(function (response) {
          setisLoadingCrear("invisible");

          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            setalmacen((almacen) => {
              return {
                ...almacen,
                alm_estado: 0,
                alm_codigo: "",
                alm_id: "",
                alm_nombre: "",
                alm_ciudad: "",
                alm_direccion: "",
                alm_telefono: "",
                alm_email: "",
                alm_contacto: "",
                alm_email_contacto: "",
                alm_telefono_contacto: "",
                alm_contacto_taller: "",
                alm_email_contacto_taller: "",
                alm_telefono_contacto_taller: "",
                alm_tipo: "",
                alm_categoria: "",
                alm_horario_lun_vier: "",
                alm_horario_sab: "",
                alm_horario_dom: "",
                alm_horario_feriado: "",
                alm_horario_lun_vier_taller: "",
                alm_horario_sab_taller: "",
                alm_horario_dom_taller: "",
                alm_horario_feriado_taller: "",
              };
            });
            getList(almacen.alm_empresa);
            setshowModalUpdate(false);
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCrear("invisible");
          showMessage("Error Intentelo de Nuevo", "error");
        });
    } else {
      showMessage("Campos Vacios ", "error");
    }
  };

  useEffect(() => {
    const getCiudad = async () => {
      await axios
        .get(Server+"/api/empresas/getCiudadesHyndai")
        .then(function (response) {
          setDataCiudad(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    const getDataEmpresas = async () => {
      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    getCiudad();

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowTable(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const getList = async (idAlmacen) => {
    setshowTable(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");
      // setempresa(idAlmacen);

      await axios
        .get(
            Server+"/api/empresas/getListAlmacenesForEmpresa/" +
            idAlmacen
        )

        .then(function (response) {
          setIsLoading("invisible");

          setshowTable(true);
          setdataTable(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const drawList = () => {
    if (showTable) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Listado general de "Almacenes"</Card.Header>
            <Card.Body>
              {/* <Button
                onClick={() => {
                  setalmacen((almacen) => {
                    return {
                      ...almacen,
                      alm_empresa: empresa,
                      alm_estado: 0,
                      alm_codigo: "",
                      alm_id: "",
                      alm_nombre: "",
                      alm_ciudad: "0",
                      alm_direccion: "",
                      alm_telefono: "",
                      alm_email: "",
                      alm_contacto: "",
                      alm_email_contacto: "",
                      alm_telefono_contacto: "",
                      alm_contacto_taller: "",
                      alm_email_contacto_taller: "",
                      alm_telefono_contacto_taller: "",
                      alm_tipo: "0",
                      alm_categoria: "0",
                      alm_horario_lun_vier: "",
                      alm_horario_sab: "",
                      alm_horario_dom: "",
                      alm_horario_feriado: "",
                      alm_horario_lun_vier_taller: "",
                      alm_horario_sab_taller: "",
                      alm_horario_dom_taller: "",
                      alm_horario_feriado_taller: "",
                    };
                  });

                  setshowModal(true);
                }}
                variant="primary"
                style={{ marginBottom: 10 }}
              >
                Crear Almacen
              </Button> */}

              <BootstrapTable
                keyField="ALM_CODIGO"
                data={dataTable}
                columns={columns}
              />
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const drawTypeAlmacen = (tipo) => {
    if (tipo === "1") {
      return (
        <>
          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">Contacto: </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Contacto"
                id="alm_contacto"
                value={almacen.alm_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_contacto: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Email de Contacto:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un email de Contacto"
                id="alm_email_contacto"
                value={almacen.alm_email_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_email_contacto: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Teléfono de Contacto:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Teléfono de Contacto"
                id="alm_telefono_contacto"
                value={almacen.alm_telefono_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_telefono_contacto: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horarios de Lunes a Viernes:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_lunes_viernes"
                value={almacen.alm_horario_lun_vier}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_lun_vier: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horarios de Sábado:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horario"
                id="alm_horario_sabado"
                value={almacen.alm_horario_sab}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_sab: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horario de Domingo:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_domingo"
                value={almacen.alm_horario_dom}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_dom: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horario de Feriados:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horarios"
                id="alm_horario_feriado"
                value={almacen.alm_horario_feriado}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_feriado: val };
                  });
                }}
              />
            </Col>
          </FormGroup>
        </>
      );
    } else if (tipo === "2") {
      return (
        <>
          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Contacto de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Contacto de Taller"
                id="alm_contacto_taller"
                value={almacen.alm_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_contacto_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Email de Contacto de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un email de Contacto de Taller"
                id="alm_email_contacto_taller"
                value={almacen.alm_email_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_email_contacto_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Teléfono de Contacto Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Teléfono de Contacto de Taller"
                id="alm_telefono_contacto_taller"
                value={almacen.alm_telefono_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_telefono_contacto_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horarios de Lunes a Viernes de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_lunes_viernes_taller"
                value={almacen.alm_horario_lun_vier_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_lun_vier_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horarios de Sábado de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horario"
                id="alm_horario_sabado_taller"
                value={almacen.alm_horario_sab_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_sab_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horario de Domingo de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_domingo_taller"
                value={almacen.alm_horario_dom_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_dom_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horario de Feriados de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horarios"
                id="alm_horario_feriado_taller"
                value={almacen.alm_horario_feriado_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_feriado_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>
        </>
      );
    } else if (tipo === "3") {
      return (
        <>
          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">Contacto: </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Contacto"
                id="alm_contacto"
                value={almacen.alm_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_contacto: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Email de Contacto:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un email de Contacto"
                id="alm_email_contacto"
                value={almacen.alm_email_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_email_contacto: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Teléfono de Contacto:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Teléfono de Contacto"
                id="alm_telefono_contacto"
                value={almacen.alm_telefono_contacto}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_telefono_contacto: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horarios de Lunes a Viernes:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_lunes_viernes"
                value={almacen.alm_horario_lun_vier}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_lun_vier: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horarios de Sábado:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horario"
                id="alm_horario_sabado"
                value={almacen.alm_horario_sab}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_sab: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horario de Domingo:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_domingo"
                value={almacen.alm_horario_dom}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_dom: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horario de Feriados:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horarios"
                id="alm_horario_feriado"
                value={almacen.alm_horario_feriado}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_feriado: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Contacto de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Contacto de Taller"
                id="alm_contacto_taller"
                value={almacen.alm_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_contacto_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Email de Contacto de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un email de Contacto de Taller"
                id="alm_email_contacto_taller"
                value={almacen.alm_email_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_email_contacto_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Teléfono de Contacto Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Teléfono de Contacto de Taller"
                id="alm_telefono_contacto_taller"
                value={almacen.alm_telefono_contacto_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_telefono_contacto_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horarios de Lunes a Viernes de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_lunes_viernes_taller"
                value={almacen.alm_horario_lun_vier_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_lun_vier_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horarios de Sábado de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horario"
                id="alm_horario_sabado_taller"
                value={almacen.alm_horario_sab_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_sab_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup as={Row}>
            <Col>
              <Form.Label className="title-create">
                Horario de Domingo de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={50}
                placeholder="Ingrese un Horario"
                id="alm_horario_domingo_taller"
                value={almacen.alm_horario_dom_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_dom_taller: val };
                  });
                }}
              />
            </Col>

            <Col>
              <Form.Label className="title-create">
                Horario de Feriados de Taller:{" "}
              </Form.Label>
              <Form.Control
                maxLength={40}
                placeholder="Ingrese un Horarios"
                id="alm_horario_feriado_taller"
                value={almacen.alm_horario_feriado_taller}
                onChange={(e) => {
                  const val = e.target.value;
                  setalmacen((almacen) => {
                    return { ...almacen, alm_horario_feriado_taller: val };
                  });
                }}
              />
            </Col>
          </FormGroup>
        </>
      );
    }
  };

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <Col md="12" lg="12">
          <div>
            <Card>
              <Card.Body>
                <h3 className="text-center">Elija una empresa</h3>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {drawList()}
        </Col>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{drawList()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Empresa/ Almacenes"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Row>
            {marcaoEmpresa()}
            {/* <Col md="12" lg="12">
              <div>

              <Card>
              <Card.Body>
              <h3 className="text-center">Elija una empresa</h3>
                <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                        <option value="0">N/A</option>
                        {getRowsEmpresas}

                  </Form.Control>

                  <div style={{marginTop:5}} className="text-center">
                  <div className={isLoading}>
                      <Spinner animation="border" role="status">
                      </Spinner>
                  </div>
              </div>
                  </Card.Body>
                  </Card>
                </div>





              {drawList()}





              </Col> */}
          </Row>

          <div>
            <Modal
              show={showModal}
              // onHide={!showModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Crear Almacen
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <div className={isLoadingCrear}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Form>
                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Código:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={6}
                          placeholder="Ingrese Código"
                          id="alm_codigo"
                          value={almacen.alm_codigo}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_codigo: val };
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <Form.Label className="title-create">ID: </Form.Label>
                        <Form.Control
                          maxLength={10}
                          placeholder="Ingrese un ID"
                          id="alm_id"
                          value={almacen.alm_id}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_id: val };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Nombre Comercial:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Nombre "
                          id="alm_nombre_comercial"
                          value={almacen.alm_nombre}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_nombre: val };
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Form.Label className="title-create">
                          Dirección:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Dirección"
                          id="alm_direccion"
                          value={almacen.alm_direccion}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_direccion: val };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Teléfono:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Teléfono"
                          id="al_telefono"
                          value={almacen.alm_telefono}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_telefono: val };
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Form.Label className="title-create">
                          Email:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Email"
                          id="alm_email"
                          value={almacen.alm_email}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_email: val };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Ciudad:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_ciudad}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_ciudad: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>
                          {getRowsCiudades}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label className="title-create">
                          Tipo de Empresas:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_tipo}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_tipo: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>

                          <option value="1">Livianos</option>
                          <option value="2">Pesados</option>
                          <option value="3">Pesados y Livianos</option>
                        </Form.Control>
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Categoria de Almacen:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_categoria}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_categoria: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>

                          <option value="1">Ventas</option>
                          <option value="2">Taller</option>
                          <option value="3">Ventas y Taller</option>
                        </Form.Control>
                      </Col>
                    </FormGroup>

                    {drawTypeAlmacen(almacen.alm_categoria)}
                  </Form>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => crearAlmacen()}>Crear</Button>
                <Button
                  onClick={() => {
                    setshowModal(false);
                    setalmacen((almacen) => {
                      return {
                        ...almacen,
                        alm_codigo: "",
                        alm_estado: 0,
                        alm_id: "",
                        alm_nombre: "",
                        alm_ciudad: "",
                        alm_direccion: "",
                        alm_telefono: "",
                        alm_email: "",
                        alm_contacto: "",
                        alm_email_contacto: "",
                        alm_telefono_contacto: "",
                        alm_contacto_taller: "",
                        alm_email_contacto_taller: "",
                        alm_telefono_contacto_taller: "",
                        alm_tipo: "",
                        alm_categoria: "",
                        alm_horario_lun_vier: "",
                        alm_horario_sab: "",
                        alm_horario_dom: "",
                        alm_horario_feriado: "",
                        alm_horario_lun_vier_taller: "",
                        alm_horario_sab_taller: "",
                        alm_horario_dom_taller: "",
                        alm_horario_feriado_taller: "",
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalUpdate}
              // onHide={!showModalUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualizar Almacen
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="text-center">
                  <div className={isLoadingCrear}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>

                <div style={{ marginTop: 10 }}>
                  <Form>
                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Nombre Comercial:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Nombre "
                          id="alm_nombre_comercial"
                          value={almacen.alm_nombre}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_nombre: val };
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Form.Label className="title-create">
                          Dirección:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Dirección"
                          id="alm_direccion"
                          value={almacen.alm_direccion}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_direccion: val };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Teléfono:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Teléfono"
                          id="al_telefono"
                          value={almacen.alm_telefono}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_telefono: val };
                            });
                          }}
                        />
                      </Col>

                      <Col>
                        <Form.Label className="title-create">
                          Email:{" "}
                        </Form.Label>
                        <Form.Control
                          maxLength={50}
                          placeholder="Ingrese un Email"
                          id="alm_email"
                          value={almacen.alm_email}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_email: val };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Check
                          className="title-create"
                          type="checkbox"
                          label="Estado"
                          checked={setEstado(almacen.alm_estado)}
                          onChange={() => {
                            var newStatus = 0;
                            if (almacen.alm_estado === 0) {
                              newStatus = 1;
                            } else if (almacen.alm_estado === 1) {
                              newStatus = 0;
                            }
                            setalmacen((almacen) => {
                              return { ...almacen, alm_estado: newStatus };
                            });
                          }}
                        />
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Ciudad:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_ciudad}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_ciudad: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>
                          {getRowsCiudades}
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Label className="title-create">
                          Tipo de Empresas:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_tipo}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_tipo: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>

                          <option value="1">Livianos</option>
                          <option value="2">Pesados</option>
                          <option value="3">Pesados y Livianos</option>
                        </Form.Control>
                      </Col>
                    </FormGroup>

                    <FormGroup as={Row}>
                      <Col>
                        <Form.Label className="title-create">
                          Categoria de Almacen:{" "}
                        </Form.Label>

                        <Form.Control
                          as="select"
                          value={almacen.alm_categoria}
                          onChange={(e) => {
                            const val = e.target.value;
                            setalmacen((almacen) => {
                              return { ...almacen, alm_categoria: val };
                            });
                          }}
                        >
                          <option value="0">N/A</option>

                          <option value="1">Ventas</option>
                          <option value="2">Taller</option>
                          <option value="3">Ventas y Taller</option>
                        </Form.Control>
                      </Col>
                    </FormGroup>

                    {drawTypeAlmacen(almacen.alm_categoria)}
                  </Form>
                </div>
              </Modal.Body>

              <Modal.Footer>
                <Button onClick={() => updateAlmacen()}>Actualizar</Button>
                <Button
                  onClick={() => {
                    setshowModalUpdate(false);
                    setalmacen((almacen) => {
                      return {
                        ...almacen,
                        alm_codigo: "",
                        alm_id: "",
                        alm_nombre: "",
                        alm_estado: 0,
                        alm_ciudad: "",
                        alm_direccion: "",
                        alm_telefono: "",
                        alm_email: "",
                        alm_contacto: "",
                        alm_email_contacto: "",
                        alm_telefono_contacto: "",
                        alm_contacto_taller: "",
                        alm_email_contacto_taller: "",
                        alm_telefono_contacto_taller: "",
                        alm_tipo: "",
                        alm_categoria: "",
                        alm_horario_lun_vier: "",
                        alm_horario_sab: "",
                        alm_horario_dom: "",
                        alm_horario_feriado: "",
                        alm_horario_lun_vier_taller: "",
                        alm_horario_sab_taller: "",
                        alm_horario_dom_taller: "",
                        alm_horario_feriado_taller: "",
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
