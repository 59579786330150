import React, { Fragment, useState } from "react";
import Hamburger from "react-hamburgers";
import MobileMenu from "./MobileMenu";

function Logo({
  enableClosedSidebar,
  SetEnableclosedsidebar,
  enableMobileMenu,
  setEnablemobilemenu,
  enableMobileMenuSmall,
  setEnablemobilemenusmall,
}) {
  const [activeHam, setActiveHam] = useState(false);

  const activeHamburger = () => {
    if (activeHam === true) {
      setActiveHam(false);
    } else {
      setActiveHam(true);
    }
  };

  const toggleEnableClosedSidebar = () => {
    SetEnableclosedsidebar(!enableClosedSidebar);
  };

  return (
    <Fragment>
      <div className="app-header__logo">
        <div className="logo-src" />
        <div className="header__pane ml-auto">
          <div onClick={toggleEnableClosedSidebar}>
            <Hamburger
              active={enableClosedSidebar}
              type="elastic"
              onClick={activeHamburger}
            />
          </div>
        </div>
      </div>
      <MobileMenu
        enableMobileMenu={enableMobileMenu}
        setEnablemobilemenu={setEnablemobilemenu}
        enableMobileMenuSmall={enableMobileMenuSmall}
        setEnablemobilemenusmall={setEnablemobilemenusmall}
      />
    </Fragment>
  );
}

export default Logo;
