import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Button,
  Form,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as xlsx from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentsDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Server } from "../../constants";
import "./CargaBonos.css";
require("react-quill/dist/quill.snow.css");

function Content() {
  const [isLoading, setIsLoading] = useState("invisible");
  const [idAlmacen, setidAlmacen] = useState();

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const readUploadFile = (e) => {
    setidAlmacen(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        insertBono(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const cleanfile = (e) => {
    const fileInput = document.getElementById('upload');
    fileInput.value = "";
    readUploadFile(e);
  };

  const insertBono = async (datos) => {
    let lengthDatos = Object.keys(datos).length;
    let rows = [];
    let cont = 1;
    await datos.forEach(async (element) => {
      let data2 = new FormData();
      data2.append("bo_vin", element["VIN"]);
      data2.append("bo_promocion", element["BOLETIN"]);
      data2.append("bo_accesorio", element["FACTURA_ACCESORIOS"]);
      data2.append("bo_valor_accesorios", element["VALOR_ACCESORIOS"]);
      data2.append("bo_observaciones", element["OBSERVACIONES"]);
      data2.append("bo_nota_credito", element["NOTA_CREDITO"]);
      data2.append("bo_valor_nc", element["VALOR_NOTA_CREDITO"]);
      data2.append("bo_valor_rebate", element["VALOR_REBATE"]);
      data2.append("bo_empresa", data.US_EMPRESA_DEFAULT.EMP_CODIGO);
      data2.append("usuario", data.US_CODIGO);
      await axios
        .post(Server + "/api/bonos/createBono", data2)
        .then(function (response) {
          if (response.data.success) {
            setIsLoading("visible");
            rows.push({
              VIN: element["VIN"],
              BOLETIN: element["BOLETIN"],
              FACTURA_ACCESORIOS: element["FACTURA_ACCESORIOS"],
              VALOR_ACCESORIOS: element["VALOR_ACCESORIOS"],
              NOTA_CREDITO: element["NOTA_CREDITO"],
              VALOR_NOTA_CREDITO: element["VALOR_NOTA_CREDITO"],
              VALOR_REBATE: element["VALOR_REBATE"],
              OBSERVACIONES: element["OBSERVACIONES"],
              MENSAJE: response.data.message,
            });
            if (lengthDatos === cont) {
              generateExcel(rows);
            }
            cont++;
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setIsLoading("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    });
  };

  const generateExcel = async (rows) => {
    /* generate worksheet and workbook */
    const worksheet = xlsx.utils.json_to_sheet(rows);
    const workbook = xlsx.utils.book_new();
    worksheet["A1"] = {
      font: {
        color: { rgb: "FF0187FA" },
        bold: true,
      },
      alignment: {
        horizontal: "center",
      },
    };

    xlsx.utils.book_append_sheet(workbook, worksheet, "Bonos");

    /* fix headers */
    xlsx.utils.sheet_add_aoa(
      worksheet,
      [["VIN", "BOLETIN"]],
      { origin: "A1" }
    );

    let nombreArchivo = "ReporteBono_" + Date.now().toLocaleString() + ".xlsx";
    xlsx.writeFile(workbook, nombreArchivo, { compression: true });
  };



  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus bonos
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setidAlmacen(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                </Form.Control>
                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginTop: 10 }}>
          </div>
          <Card>
            <Card.Header as="h5">Carga de Bonos <FontAwesomeIcon className="icono" icon={faCommentsDollar} title="Factura vehiculo" /></Card.Header>
            <Card.Body>
              <Form.Group controlId="formFile" >
                <Form.Label>Seleccione archivo para cargar bonos</Form.Label>
                <Form.Control
                  type="file"
                  name="upload"
                  id="upload"
                />
                <Button className="button-carga" variant="primary" onClick={readUploadFile} >
                  Cargar
                </Button>
                <Button className="button-carga" variant="primary" onClick={(e) => cleanfile(e)} >
                  Limpiar
                </Button>
              </Form.Group>
              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      //setidAlmacen(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
      return (
        <div>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">Carga de Bonos <FontAwesomeIcon className="icono" icon={faCommentsDollar} title="Factura vehiculo" /></Card.Header>
              <Card.Body>
                <Form.Group controlId="formFile" >
                  <Form.Label>Seleccione archivo para cargar bonos</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={(e) => readUploadFile(e)}
                  />
                  <Button className="button-carga" variant="primary" onClick={(e) => cleanfile(e)} >
                    Limpiar
                  </Button>
                </Form.Group>
                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>


        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Bonos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {marcaoEmpresa()}


        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
