import React, { Fragment, useState } from "react";
import Hamburger from "react-hamburgers";
import cx from "classnames";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

function MobileMenu({ enableMobileMenu, setEnablemobilemenu, enableMobileMenuSmall, setEnablemobilemenusmall }) {
  const [activeHam, setActiveHam] = useState(false);
  const [activeSecondaryMenuMobile, setActivesecondarymenumobile] = useState(false);

  const activeHambur = () => {
    if (activeHam === true) {
      setActiveHam(false);
    } else {
      setActiveHam(true);
    }
  };

  const toggleMobileSidebar = () => {
    setEnablemobilemenu(!enableMobileMenu);
  };

  const toggleMobileSmall = () => {
    setEnablemobilemenusmall(!enableMobileMenuSmall);
  };

  const changeActiveSecondary = () => {
    setActivesecondarymenumobile(!activeSecondaryMenuMobile)
  };

  return (
    <Fragment>
      <div className="app-header__mobile-menu">
        <div onClick={toggleMobileSidebar}>
          <Hamburger active={activeHam} type="elastic" onClick={activeHambur} />
        </div>
      </div>
      <div className="app-header__menu">
        <span onClick={toggleMobileSmall}>
          <Button
            size="sm"
            className={cx("btn-icon btn-icon-only", {
              active: activeSecondaryMenuMobile,
            })}
            color="primary"
            onClick={changeActiveSecondary}
          >
            <div className="btn-icon-wrapper">
              <FontAwesomeIcon icon={faEllipsisV} />
            </div>
          </Button>
        </span>
      </div>
    </Fragment>
  );
}

export default MobileMenu;
