import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import Logo from "./Logo";
import cx from "classnames";
import UserBox from "./UserBox";

function Header({
  enableClosedSidebar,
  SetEnableclosedsidebar,
  enableMobileMenu,
  setEnablemobilemenu,
  enableMobileMenuSmall,
  setEnablemobilemenusmall,
  closedSmallerSidebar,
  setClosedsmallersidebar,
}) {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-header", "bg-strong-bliss header-text-light", {
          "header-shadow": false,
        })}
        transitionName="HeaderAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Logo
          enableClosedSidebar={enableClosedSidebar}
          SetEnableclosedsidebar={SetEnableclosedsidebar}
          enableMobileMenu={enableMobileMenu}
          setEnablemobilemenu={setEnablemobilemenu}
          enableMobileMenuSmall={enableMobileMenuSmall}
          setEnablemobilemenusmall={setEnablemobilemenusmall}
          closedSmallerSidebar={closedSmallerSidebar}
          setClosedsmallersidebar={setClosedsmallersidebar}
        />

        <div
          className={cx("app-header__content", {
            "header-mobile-open": enableMobileMenuSmall,
          })}
        >
          <div className="app-header-left"></div>
          <div className="app-header-right">
            <UserBox />
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Header;
