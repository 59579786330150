import React, { Fragment, useState, useEffect, useCallback } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  Form,
  Modal,
  FormGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory from "react-bootstrap-table2-filter";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faFile,
  faCheckCircle,
  faBan,
  faWindowClose,
  faCommentsDollar,
} from "@fortawesome/free-solid-svg-icons";
import { Server } from "../../constants";
import { useMemo } from "react";
import "./bonos.css";

require("react-quill/dist/quill.snow.css");

function Content() {
  const [showModalUpdate, setshowModalUpdate] = useState(false);
  const [fileFactura, setFileFactura] = useState("");
  const [fileAccesorios, setFileAccesorios] = useState("");
  const [fileNota, setFileNota] = useState("");
  const [fileCedula, setFileCedula] = useState("");
  const [fileFlota, setFileFlota] = useState("");
  const [fileMatricula, setFileMatricula] = useState("");
  const [dataTableBonoEmpresa, setdataTableBonoEmpresa] = useState([]);
  const [isLoadingCreate, setisLoadingCreate] = useState("invisible");
  const [isLoading, setIsLoading] = useState(false);
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [idAlmacen, setidAlmacen] = useState([]);
  const [showofertaEmpresas, setshowofertaEmpresas] = useState(false);
  const [buttonUpdate, setButtonUpdate] = useState(true);
  const [countOpen, setCountOpen] = useState(0);
  const [countPendiente, setCountPendiente] = useState(0);
  const [countValidado, setCountValidado] = useState(0);
  const [countPreaprobado, setCountPreaprobado] = useState(0);
  const [countAprobado, setCountAprobado] = useState(0);
  const [countAplicado, setCountAplicado] = useState(0);
  const [countRechazado, setCountRechazado] = useState(0);
  const [countTotal, setCountTotal] = useState(0);
  const [showDocuments, setshowDocuments] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [bono, setBono] = useState({
    bo_vin: "",
    bo_cliente: "",
    bo_promocion: "",
    bo_fac_venta: "",
    bo_fe_fac_venta: "",
    bo_fac_wholesale: "",
    bo_fe_fac_wholesale: "",
  });

  const [filtroBono, setfiltroBono] = useState({
    boletin: "",
    vin: "",
    modelo: "",
    estado: "",
  });

  const [bonoDocumento, setBonoDocumento] = useState({
    urlFactura: "",
    urlAccesorios: "",
    urlNota: "",
    urlCedula: "",
    urlFlota: "",
    urlMatricula: "",
  });

  const columnsEmpresa = [
    {
      dataField: "bo_id",
      text: "Codigo",
      headerStyle: { width: 65 },
      sort: true,
      dataVisible: false,
    },
    {
      dataField: "boletin",
      text: "Boletin",
      sort: true,
      headerStyle: { width: 65 },
    },
    {
      dataField: "nombre_propietario",
      text: "Cliente",
      sort: true,
      headerStyle: { width: 135 },
    },
    {
      dataField: "bo_vin",
      text: "VIN",
      sort: true,
      headerStyle: { width: 150 },
    },
    {
      dataField: "ve_modelo",
      text: "Modelo",
      sort: true,
      headerStyle: { width: 135 },
    },
    {
      dataField: "valor",
      text: "Valor Rebate",
      sort: true,
      headerStyle: { width: 120 },
      formatter: (cell, row, index) => formatoMoneda(row, index),
    },
    {
      dataField: "retail",
      text: "FC Venta",
      sort: true,
      headerStyle: { width: 150 },
    },
    {
      dataField: "wholesale",
      text: "FC WholeSale",
      sort: true,
      headerStyle: { width: 135 },
    },
    {
      dataField: "bo_fecha_create",
      text: "FC creación",
      sort: true,
      headerStyle: { width: 135 },
    },
    {
      dataField: "bo_estado",
      text: "Estado",
      sort: true,
      headerStyle: { width: 135 },
    },
    {
      text: "Vista previa Documento",
      sort: true,
      headerStyle: { width: 130 },
      formatter: (cell, row, index) => viewDocumentos(row, index),
    },
    {
      dataField: "OFE_EDITAR",
      text: "Editar",
      headerStyle: { width: 60 },
      formatter: (cell, row, index) => editRowBono(row, index),
    },
    {
      dataField: "bo_validacion",
      text: "Valida",
      headerStyle: { width: 60 },
      formatter: (cell, row, index) => validateRowEmpresa(row, index),
      hidden: data.US_PERMISOS[0].PER_CODIGO === 1 ? false : true,
    },
    {
      dataField: "",
      text: "Rechazar",
      headerStyle: { width: 80 },
      formatter: (cell, row, index) => rechazaRowEmpresa(row, index),
      hidden: data.US_PERMISOS[0].PER_CODIGO === 1 ? false : true,
    },
  ];

  const formatoMoneda = (row) => {
    var valor = row.valor;
    return (
      <span>$ {parseFloat(valor.toFixed(2)).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    )
  }

  const viewDocumentos = (row) => {
    return (
      <div className="icon-file">
        <img width="25" height="25" src="https://img.icons8.com/ios/50/file-2.png" alt="file-2" onClick={() => viewDocumentosRow(row)} />
      </div>
    );
  };

  const viewDocumentosRow = (row) => {
    setBonoDocumento((bonoDocumento) => {
      return {
        ...bonoDocumento,
        urlAccesorios: row.bo_url_accesorios,
        urlFactura: row.bo_url_factura,
        urlCedula: row.bo_url_cedula,
        urlFlota: row.bo_url_flota,
        urlNota: row.bo_url_nota_credito,
        urlMatricula: row.bo_url_matricula,
      };
    });
    setshowDocuments(true);
  }

  const validateRowEmpresa = (row) => {
    if (row.bo_estado === "VALIDADO") {
      return (
        <Button color="primary" variant="contained">
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: "green" }} />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            updateState(row, "VALIDADO");
          }}
          color="primary"
          variant="contained"
        >
          <FontAwesomeIcon icon={faCheckCircle} />
        </Button>
      );
    }
  };

  const rechazaRowEmpresa = (row) => {
    if (row.bo_estado === "RECHAZADO") {
      return (
        <Button color="primary" variant="contained">
          <FontAwesomeIcon icon={faWindowClose} style={{ color: "red" }} />
        </Button>
      );
    } else {
      return (
        <Button
          onClick={() => {
            updateState(row, "RECHAZADO");
          }}
          color="primary"
          variant="contained"
        >
          <FontAwesomeIcon icon={faWindowClose} />
        </Button>
      );
    }
  };

  const updateState = async (row, estado) => {
    setIsLoading(true);
    setdataTableBonoEmpresa("");
    setisLoadingCreate("visible");
    const data2 = new FormData();
    data2.append("bo_estado", estado);
    data2.append("bo_id", row.bo_id);
    await axios
      .post(Server + "/api/bonos/editBonoStatus", data2)
      .then(function (response) {
        if (response.data.success) {
          setisLoadingCreate("invisible");
          showMessage(response.data.message, "success");
          setTimeout(function () {
            getList(idAlmacen);
          }, 2000);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        setisLoadingCreate("invisible");
        showMessage("Error en el Intentalo de nuevo", "error");
      });
  };

  const editRowBono = (row) => {
    //validar que no sea mayor a 90 dias
    var fechaRetail = new Date(row.fecha_retail);
    var fechaActual = Date.now();
    var diferenciaMilisegundos = fechaActual - fechaRetail.getTime();
    var diferenciaDias = diferenciaMilisegundos / (1000 * 60 * 60 * 24);
    if (diferenciaDias > 360) {
      return (
        <Button variant="contained" disabled>
          <FontAwesomeIcon
            icon={faBan}
            style={{ color: "#e10505" }}
            title="Factura mayor a 360 días"
          />
        </Button>
      );
    }
    if (
      (row.bo_estado === "ABIERTO" || row.bo_estado === "PENDIENTE") &&
      diferenciaDias <= 360
    ) {
      return (
        <Button
          onClick={() => {
            editBono(row);
          }}
          color="primary"
          variant="contained"
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      );
    }
  };

  const editBono = (row) => {
    setBono((bono) => {
      return {
        ...bono,
        bo_empresa: idAlmacen,
        bo_id: row.bo_id,
        bo_promocion: row.bo_promocion,
        bo_cliente: row.bo_cliente,
        bo_vin: row.bo_vin,
        bo_modelo: row.bo_modelo,
        bo_estado: row.bo_estado,
      };
    });
    setshowModalUpdate(true);
  };

  useMemo(() => {
    if (
      bonoDocumento.urlAccesorios &&
      bonoDocumento.urlCedula &&
      bonoDocumento.urlFactura &&
      bonoDocumento.urlMatricula &&
      bonoDocumento.urlNota
    ) {
      setButtonUpdate(false);
    }
  }, [bonoDocumento]);

  const selectFileFactura = (event) => {
    setFileFactura(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlFactura: val };
    });
  };

  const selectFileAccesorios = (event) => {
    setFileAccesorios(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlAccesorios: val };
    });
  };

  const selectFileNota = (event) => {
    setFileNota(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlNota: val };
    });
  };

  const selectFileCedula = (event) => {
    setFileCedula(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlCedula: val };
    });
  };

  const selectFileFlota = (event) => {
    setFileFlota(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlFlota: val };
    });
  };

  const selectFileMatricula = (event) => {
    setFileMatricula(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setBonoDocumento((bonoDocumento) => {
      return { ...bonoDocumento, urlMatricula: val };
    });
  };

  const editarBono = async () => {
    const data2 = new FormData();
    data2.append("fileFactura", fileFactura);
    data2.append("fileAccesorios", fileAccesorios);
    data2.append("fileNota", fileNota);
    data2.append("fileCedula", fileCedula);
    data2.append("fileFlota", fileFlota);
    data2.append("fileMatricula", fileMatricula);
    data2.append("bo_id", bono.bo_id);
    data2.append("usuario", data.US_CODIGO);
    setisLoadingCreate("visible");
    await axios
      .post(Server + "/api/bonos/editBono", data2)
      .then(function (response) {
        if (response.data.success) {
          setisLoadingCreate("invisible");
          setshowModalUpdate(false);
          showMessage(response.data.message, "success");
          getList(bono.bo_empresa);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        setisLoadingCreate("invisible");
        showMessage("Error en el Intentalo de nuevo", "error");
      });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const getList = useCallback (async(idAlmacen) => {
    setdataTableBonoEmpresa(false);
    setshowofertaEmpresas(false);
    if (idAlmacen !== "0") {
      countStates(idAlmacen);
      setIsLoading("visible");
      await axios
        .get(Server + "/api/bonos/getBonosMarcaForEmpresa/" + idAlmacen, {
          params: {
            boletin: filtroBono.boletin,
            vin: filtroBono.vin,
            modelo: filtroBono.modelo,
            estado: filtroBono.estado,
          },
        })
        .then(function (response) {
          setidAlmacen(idAlmacen);
          setshowofertaEmpresas(true);
          setdataTableBonoEmpresa(response.data.data);
          setIsLoading(false);
        })
        .catch(function (error) { })
        .then(function () { });
    }
  },[filtroBono]);

  const cleanData = () => {
    setfiltroBono((filtroBono) => {
      return {
        boletin: "",
        vin: "",
        modelo: "",
        estado: "",
      };
    });
  };

  const listofertaEmpresa = () => {
    if (showofertaEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">
              bonos{" "}
              <FontAwesomeIcon
                className="icono"
                icon={faCommentsDollar}
                title="Factura vehiculo"
              />
            </Card.Header>
            <Card.Body>
              <div className="totales-estados">
                <table>
                  <tr>
                    <td>
                      <Button className="totales-estados_abierto" variant="normal">
                        <div className="totales-estados_abierto-iz">
                          ABIERTO
                        </div>
                        <div className="totales-estados_abierto-de">
                          {countOpen}
                        </div>
                      </Button>
                    </td>
                    <td>
                      <Button className="totales-estados_pendiente" variant="normal" >
                        <div className="totales-estados_pendiente-iz">
                          PENDIENTE
                        </div>
                        <div className="totales-estados_pendiente-de">
                          {countPendiente}
                        </div>
                      </Button>
                    </td>
                    <td>
                      <Button className="totales-estados_validado" variant="normal" >
                        <div className="totales-estados_validado-iz">
                          VALIDADO
                        </div>
                        <div className="totales-estados_validado-de">
                          {countValidado}
                        </div>
                      </Button>
                    </td>
                    <td>
                      <Button className="totales-estados_preAprobado" variant="normal"  >
                        <div className="totales-estados_preAprobado-iz">
                          PREAPROBADO
                        </div>
                        <div className="totales-estados_preAprobado-de">
                          {countPreaprobado}
                        </div>
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Button className="totales-estados_aprobado" variant="normal"  >
                        <div className="totales-estados_aprobado-iz">
                          APROBADO
                        </div>
                        <div className="totales-estados_aprobado-de">
                          {countAprobado}
                        </div>
                      </Button>
                    </td>
                    <td>
                      <Button className="totales-estados_aplicado" variant="normal" >
                        <div className="totales-estados_aplicado-iz">
                          APLICADO
                        </div>
                        <div className="totales-estados_aplicado-de">
                          {countAplicado}
                        </div>
                      </Button>
                    </td>
                    <td>
                      <Button className="totales-estados_rechazado" variant="normal"  >
                        <div className="totales-estados_rechazado-iz">
                          RECHAZADO
                        </div>
                        <div className="totales-estados_rechazado-de">
                          {countRechazado}
                        </div>
                      </Button>
                    </td>
                  </tr>
                </table>
              </div>
              <div>
                <table className="total-bonos">
                  <tr>
                    <td className="total-bonos-td">Total</td>
                    <td className="total-bonos-td">{countTotal}</td>
                  </tr>
                </table>
              </div>
              <div
                className="table-bootstrap"
               /* </Card.Body> style={{ width: "80%", overflowX: "scroll" }} */
              >
              </div>
                {isLoading ?
                  <div style={{ marginTop: 5 }} className="text-center">
                    <div className="visible" >
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  </div> :
                  <div style={{ marginTop: 10, width: "100%", overflowX: "scroll"}}>
                    <BootstrapTable
                    style={{ width: "80%", overflowX: "auto" }}
                      keyField="bo_id"
                      data={dataTableBonoEmpresa}
                      columns={columnsEmpresa}
                      pagination={paginationFactory()}
                      filter={filterFactory()}
                    />
                  </div>
                }
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  useEffect(() => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      axios
        .get(Server + "/api/empresas/getListEmpresasActivas")
        .then(function (response) {
          var data = response.data.data;
          const rows = data.map((row, index) => (
            <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
              {row.EMP_NOMBRE_COMERCIAL}
            </option>
          ));
          setRowsEmpresas(rows);
        })
        .catch(function (error) { })
        .then(function () { });
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowofertaEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data,getList]);

  const countStates = async (idAlmacen) => {
    setdataTableBonoEmpresa(false);
    setshowofertaEmpresas(false);
      setIsLoading("visible");
      await axios
        .get(Server + "/api/bonos/getBonosAbierto/"+idAlmacen)
        .then(function (response) {
          setCountOpen(response.data.data[0].abiertos);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosPendiente/"+idAlmacen)
        .then(function (response) {
          setCountPendiente(response.data.data[0].pendientes);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosValidado/"+idAlmacen)
        .then(function (response) {
          setCountValidado(response.data.data[0].validados);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosPreaprobado/"+idAlmacen)
        .then(function (response) {
          setCountPreaprobado(response.data.data[0].preaprobados);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosAprobado/"+idAlmacen)
        .then(function (response) {
          setCountAprobado(response.data.data[0].aprobados);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosAplicado/"+idAlmacen)
        .then(function (response) {
          setCountAplicado(response.data.data[0].aplicados);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosRechazado/"+idAlmacen)
        .then(function (response) {
          setCountRechazado(response.data.data[0].rechazados);
        })
        .catch(function (error) { })
        .then(function () { });

        await axios
        .get(Server + "/api/bonos/getBonosTotal/"+idAlmacen)
        .then(function (response) {
          setCountTotal(response.data.data[0].total);
        })
        .catch(function (error) { })
        .then(function () { });
  };

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus bonos
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setidAlmacen(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>
              </Card.Body>
            </Card>
          </div>
        <div className="filter-box">
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">Filtros</Card.Header>
              <Card.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Boletín:{" "}
                      </Form.Label>
                      <Form.Control
                        className="form-holder"
                        maxLength={7}
                        placeholder="Ingrese Boletín"
                        id="boletin"
                        value={filtroBono.boletin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, boletin: val };
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="title-create">VIN: </Form.Label>
                      <Form.Control
                        className="form-holder"
                        maxLength={17}
                        placeholder="Ingrese VIN"
                        id="vin"
                        value={filtroBono.vin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, vin: val };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Modelo: </Form.Label>
                      <Form.Control
                        className="form-holder"
                        maxLength={100}
                        placeholder="Ingrese Modelo"
                        id="modelo"
                        value={filtroBono.modelo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, modelo: val };
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="title-create">Estado: </Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Ingrese estado"
                        id="estado"
                        value={filtroBono.estado}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, estado: val };
                          });
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="ABIERTO">Abierto</option>
                        <option value="PENDIENTE">Pendiente</option>
                        <option value="VALIDADO">Validado</option>
                        <option value="PREAPROBADO">Preaprobado</option>
                        <option value="APROBADO">Aprobado</option>
                        <option value="APLICADO">Aplicado</option>
                        <option value="RECHAZADO">Rechazado</option>
                      </Form.Control>
                    </Col>
                  </FormGroup>
                </Form>
              </Card.Body>
              <div className="button-aling">
                <Button
                  className="button-uno"
                  variant="primary"
                  onClick={() => getList(idAlmacen)}
                >
                  Buscar bonos
                </Button>
                <Button variant="primary" onClick={() => cleanData()}>
                  Limpiar
                </Button>
              </div>
            </Card>
          </div>
        </div>
          
          {listofertaEmpresa()}
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return (
        <div className="filter-box">
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">Filtros</Card.Header>
              <Card.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Boletín:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={7}
                        placeholder="Ingrese Boletín"
                        id="boletin"
                        value={filtroBono.boletin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, boletin: val };
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="title-create">VIN: </Form.Label>
                      <Form.Control
                        maxLength={17}
                        placeholder="Ingrese VIN"
                        id="vin"
                        value={filtroBono.vin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, vin: val };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Modelo: </Form.Label>
                      <Form.Control
                        maxLength={100}
                        placeholder="Ingrese Modelo"
                        id="modelo"
                        value={filtroBono.modelo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, modelo: val };
                          });
                        }}
                      />
                    </Col>
                    <Col>
                      <Form.Label className="title-create">Estado: </Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Ingrese estado"
                        id="estado"
                        value={filtroBono.estado}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, estado: val };
                          });
                        }}
                      >
                        <option value="">Todos</option>
                        <option value="ABIERTO">Abierto</option>
                        <option value="PENDIENTE">Pendiente</option>
                        <option value="VALIDADO">Validado</option>
                        <option value="PREAPROBADO">Preaprobado</option>
                        <option value="APROBADO">Aprobado</option>
                        <option value="APLICADO">Aplicado</option>
                        <option value="RECHAZADO">Rechazado</option>
                      </Form.Control>
                    </Col>
                  </FormGroup>
                </Form>
              </Card.Body>
              <div className="button-aling">
                <Button
                  className="button-uno"
                  variant="primary"
                  onClick={() => getList(idAlmacen)}
                >
                  Buscar bonos
                </Button>
                <Button variant="primary" onClick={() => cleanData()}>
                  Limpiar
                </Button>
              </div>
            </Card>
          </div>
          <div>{listofertaEmpresa()}</div>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Bonos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {marcaoEmpresa()}
          <Row>
            <div>
              <Modal
                show={showModalUpdate}
                // onHide={!showModalUpdate}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar bono
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Factura vehículo:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileFactura}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Factura accesorios:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileAccesorios}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Nota de crédito o contrafactura:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileNota}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Cédula de identidad:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileCedula}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Certificado de pertenecer a flota:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileFlota}
                      />
                    </Col>
                  </FormGroup>
                  <Form.Group as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Matricula:{" "}
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="application/pdf, image/jpeg"
                        onChange={selectFileMatricula}
                      />
                    </Col>
                  </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                  <Button disabled={buttonUpdate} onClick={() => editarBono()}>
                    Actualizar bono
                  </Button>
                  <Button
                    onClick={() => {
                      setshowModalUpdate(false);
                      setBono((bono) => {
                        return {
                          ...bono,
                          of_tipo: 0,
                          of_url_foto: "",
                          of_url: "",
                          ofe_codigo: 0,
                          of_descripcion: "",
                        };
                      });
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>

                <div className="text-center">
                  <div className={isLoadingCreate} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Modal>
            </div>
          </Row>
          <div>
            <Modal show={showDocuments} size="sm" centered>
              <Modal.Header>
                <Modal.Title id="view-documents.modal">
                  Vista de los Documentos
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FormGroup as={Row}>
                  <Col>
                    <div>
                      <Form.Label className="showDocumentes-label">
                        FACTURA DEL VEHICULO{" "}
                      </Form.Label>
                      <div className="modal-documents-icono col-md-2">
                        <a
                          href={bonoDocumento.urlFactura}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faFile}
                            title="Factura vehiculo"
                          />
                        </a>
                      </div>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup as={Row}>
                  <Col>
                    <Form.Label className="showDocumentes-label ">
                      {" "}
                      FACTURA ACCERSORIOS{" "}
                    </Form.Label>
                    <div className="modal-documents-icono col-md-2">
                      <a
                        href={bonoDocumento.urlAccesorios}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Factura accesorios"
                        />
                      </a>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup as={Row}>
                  <Col>
                    <Form.Label className="showDocumentes-label">
                      {" "}
                      NOTA DE CREDITO{" "}
                    </Form.Label>
                    <div className="modal-documents-icono col-md-2">
                      <a
                        href={bonoDocumento.urlNota}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Nota de crédito"
                        />
                      </a>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup as={Row}>
                  <Col>
                    <Form.Label className="showDocumentes-label">
                      {" "}
                      CEDULA DE IDENTIDAD{" "}
                    </Form.Label>
                    <div className="modal-documents-icono col-md-2">
                      <a
                        href={bonoDocumento.urlCedula}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Cédula de identidad"
                        />
                      </a>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup as={Row}>
                  <Col>
                    <Form.Label className="showDocumentes-label">
                      {" "}
                      CERTIFICADO FLOTA{" "}
                    </Form.Label>
                    <div className="modal-documents-icono col-md-2">
                      <a
                        href={bonoDocumento.urlFlota}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Certificado flota"
                        />
                      </a>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
                <FormGroup as={Row}>
                  <Col>
                    <Form.Label className="showDocumentes-label">
                      {" "}
                      MATRÍCULA VEHICULO{" "}
                    </Form.Label>
                    <div className="modal-documents-icono col-md-2">
                      <a
                        href={bonoDocumento.urlMatricula}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          title="Matrícula vehículo"
                        />
                      </a>
                    </div>
                  </Col>
                </FormGroup>
                <hr />
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    setshowDocuments(false);
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
