import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTable, setdataTable] = useState([]);
  const [getRowsCiudades, setRowsCiudades] = useState([]);
  // const [isLoadingEmpresaCreada, setisLoadingEmpresaCreada] = useState(
  //   "invisible"
  // );
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  const [first, setfirst] = useState(true);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [empresa, setempresa] = useState({
    emp_codigo: "",
    emp_estado: 0,
    emp_id: "",
    emp_razon_social: "",
    emp_nombre_comercial: "",
    emp_ciudad: "",
    emp_direccion: "",
    emp_telefono: "",
    emp_email: "",
    emp_representante: "",
    emp_ruc: "",
    emp_pagina_web: "",
    emp_tipo_empresa: "",
    emp_360_autos: "",
    emp_360_suv: "",
    emp_citas: "",
  });

  const [error, seterror] = useState({
    codigo: "",
    id: "",
    razon: "",
    nombreco: "",
    direccion: "",
    telefono: "",
    email: "",
    representante: "",
    pagina: "",
    ruc: "",
    autos360: "",
    suv360: "",
    citas: "",
  });

  const columns = [
    {
      dataField: "EMP_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "EMP_RAZON_SOCIAL",
      text: "Razon Social",
      sort: true,
    },
    {
      dataField: "EMP_DIRECCION",
      text: "Dirección",
      sort: true,
    },
    {
      dataField: "EMP_TELEFONO",
      text: "Teléfono",
      sort: true,
    },
    {
      dataField: "EMP_EMAIL_EMPRESA",
      text: "Email",
      sort: true,
    },

    {
      dataField: "EMP_RUC",
      text: "RUC",
      sort: true,
    },
    {
      dataField: "EMP_PAGINA_WEB",
      text: "Página Web",
      sort: true,
    },

    {
      dataField: "EMP_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setempresa((empresa) => {
      return {
        ...empresa,
        emp_codigo: row.EMP_CODIGO,
        emp_id: row.EMP_ID,
        emp_estado: row.EMP_ESTADO,
        emp_razon_social: row.EMP_RAZON_SOCIAL,
        emp_nombre_comercial: row.EMP_NOMBRE_COMERCIAL,
        emp_ciudad: row.EMP_CIUDAD,
        emp_direccion: row.EMP_DIRECCION,
        emp_telefono: row.EMP_TELEFONO,
        emp_email: row.EMP_EMAIL_EMPRESA,
        emp_representante: row.EMP_REPRESENTANTE,
        emp_ruc: row.EMP_RUC,
        emp_pagina_web: row.EMP_PAGINA_WEB,
        emp_tipo_empresa: row.EMP_TIPO_EMPRESA,
        emp_360_autos: row.EMP_360_AUTOS !== null ? row.EMP_360_AUTOS : "",
        emp_360_suv: row.EMP_360_SUV !== null ? row.EMP_360_SUV : "",
        emp_citas: row.EMP_CITAS !== null ? row.EMP_CITAS : "",
      };
    });

    setshowModal(true);
  };

  const setDataCiudad = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.CIU_CODIGO} value={row.CIU_CODIGO}>
        {row.CIU_NOMBRE}
      </option>
    ));
    setRowsCiudades(rows);
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const getEmpresas = async () => {
    setisLoadingTable("visible");
    await axios
      .get(Server+"/api/empresas/getListEmpresas")
      .then(function (response) {
        setisLoadingTable("invisible");

        setdataTable(response.data.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  // const createEmpresa = async () => {
  //   seterror((error) => {
  //     return {
  //       ...error,
  //       codigo: "",
  //       id: "",
  //       razon: "",
  //       nombreco: "",
  //       direccion: "",
  //       telefono: "",
  //       email: "",
  //       representante: "",
  //       pagina: "",
  //       ruc: "",
  //     };
  //   });

  //   if (empresa.emp_ciudad === 0 || empresa.emp_tipo_empresa === 0) {
  //     showMessage("Selecciona una Ciudad o Tipo de Empresa", "error");
  //   } else {
  //     if (
  //       empresa.emp_codigo !== "" &&
  //       empresa.emp_id !== "" &&
  //       empresa.emp_razon_social !== "" &&
  //       empresa.emp_nombre_comercial !== "" &&
  //       empresa.emp_direccion !== "" &&
  //       empresa.emp_telefono !== "" &&
  //       empresa.emp_email !== "" &&
  //       empresa.emp_representante !== "" &&
  //       empresa.emp_ruc !== "" &&
  //       empresa.emp_pagina_web !== ""
  //     ) {
  //       setisLoadingEmpresaCreada("visible");

  //       const dataForm = new FormData();
  //       dataForm.append("emp_codigo", empresa.emp_codigo);
  //       dataForm.append("emp_id", empresa.emp_id);
  //       dataForm.append("emp_razon_social", empresa.emp_razon_social);
  //       dataForm.append("emp_nombre_comercial", empresa.emp_nombre_comercial);
  //       dataForm.append("emp_ciudad", empresa.emp_ciudad);
  //       dataForm.append("emp_direccion", empresa.emp_direccion);
  //       dataForm.append("emp_telefono", empresa.emp_telefono);
  //       dataForm.append("emp_representante", empresa.emp_representante);
  //       dataForm.append("emp_ruc", empresa.emp_ruc);
  //       dataForm.append("emp_pagina_web", empresa.emp_pagina_web);
  //       dataForm.append("emp_email", empresa.emp_email);
  //       dataForm.append("emp_tipo_empresa", empresa.emp_tipo_empresa);
  //       dataForm.append("usuario", data.US_CODIGO);

  //       await axios
  //         .post(Server+"/api/empresas/createEmpresa", dataForm)

  //         .then(function (response) {
  //           setisLoadingEmpresaCreada("invisible");

  //           if (response.data.success) {
  //             showMessage(response.data.message, "success");

  //             setempresa((empresa) => {
  //               return {
  //                 ...empresa,
  //                 emp_codigo: "",
  //                 emp_id: "",
  //                 emp_razon_social: "",
  //                 emp_estado: 0,
  //                 emp_nombre_comercial: "",
  //                 emp_ciudad: "0",
  //                 emp_direccion: "",
  //                 emp_telefono: "",
  //                 emp_email: "",
  //                 emp_representante: "",
  //                 emp_ruc: "",
  //                 emp_pagina_web: "",
  //                 emp_tipo_empresa: "0",
  //               };
  //             });

  //             //getData()
  //           } else {
  //             showMessage(response.data.message, "error");
  //           }
  //         })
  //         .catch(function (error) {
  //           showMessage("Campos incompletos", "error");
  //         });
  //     } else {
  //       if (empresa.emp_codigo === "") {
  //         seterror((error) => {
  //           return { ...error, codigo: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_id === "") {
  //         seterror((error) => {
  //           return { ...error, id: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_razon_social === "") {
  //         seterror((error) => {
  //           return { ...error, razon: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_nombre_comercial === "") {
  //         seterror((error) => {
  //           return { ...error, nombreco: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_direccion === "") {
  //         seterror((error) => {
  //           return { ...error, direccion: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_telefono === "") {
  //         seterror((error) => {
  //           return { ...error, telefono: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_email === "") {
  //         seterror((error) => {
  //           return { ...error, email: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_representante === "") {
  //         seterror((error) => {
  //           return { ...error, representante: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_pagina_web === "") {
  //         seterror((error) => {
  //           return { ...error, pagina: "Campo requerido" };
  //         });
  //       }

  //       if (empresa.emp_ruc === "") {
  //         seterror((error) => {
  //           return { ...error, ruc: "Campo requerido" };
  //         });
  //       }

  //       showMessage("Campos incompletos", "error");
  //     }
  //   }
  // };

  const updateEmpresa = async () => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        razon: "",
        nombreco: "",
        direccion: "",
        telefono: "",
        email: "",
        representante: "",
        pagina: "",
        ruc: "",
        autos360: "",
        suv360: "",
        citas: "",
      };
    });

    if (empresa.emp_ciudad === 0 || empresa.emp_tipo_empresa === 0) {
      showMessage("Selecciona una Ciudad o Tipo de Empresa", "error");
    } else {
      if (
        empresa.emp_codigo !== "" &&
        empresa.emp_id !== "" &&
        empresa.emp_razon_social !== "" &&
        empresa.emp_nombre_comercial !== "" &&
        empresa.emp_direccion !== "" &&
        empresa.emp_telefono !== "" &&
        empresa.emp_email !== "" &&
        empresa.emp_representante !== "" &&
        empresa.emp_ruc !== "" &&
        empresa.emp_pagina_web !== "" &&
        empresa.emp_360_autos !== "" &&
        empresa.emp_360_suv !== "" &&
        empresa.emp_citas !== ""
      ) {
        setisLoadingActualizar("visible");

        const dataForm = new FormData();
        dataForm.append("emp_codigo", empresa.emp_codigo);
        dataForm.append("emp_estado", empresa.emp_estado);
        dataForm.append("emp_id", empresa.emp_id);
        dataForm.append("emp_razon_social", empresa.emp_razon_social);
        dataForm.append("emp_nombre_comercial", empresa.emp_nombre_comercial);
        dataForm.append("emp_ciudad", empresa.emp_ciudad);
        dataForm.append("emp_direccion", empresa.emp_direccion);
        dataForm.append("emp_telefono", empresa.emp_telefono);
        dataForm.append("emp_representante", empresa.emp_representante);
        dataForm.append("emp_ruc", empresa.emp_ruc);
        dataForm.append("emp_pagina_web", empresa.emp_pagina_web);
        dataForm.append("emp_tipo_empresa", empresa.emp_tipo_empresa);
        dataForm.append("emp_email", empresa.emp_email);
        dataForm.append("emp_360_autos", empresa.emp_360_autos);
        dataForm.append("emp_360_suv", empresa.emp_360_suv);
        dataForm.append("emp_citas", empresa.emp_citas);

        dataForm.append("usuario", data.US_CODIGO);
        dataForm.append("_method", "PUT");

        await axios
          .post(
            Server+"/api/empresas/updateEmpresa",
            dataForm
          )

          .then(function (response) {
            setisLoadingActualizar("invisible");

            if (response.data.success) {
              showMessage(response.data.message, "success");

              setempresa((empresa) => {
                return {
                  ...empresa,
                  emp_codigo: "",
                  emp_id: "",
                  emp_razon_social: "",
                  emp_estado: 0,
                  emp_nombre_comercial: "",
                  emp_ciudad: "0",
                  emp_direccion: "",
                  emp_telefono: "",
                  emp_email: "",
                  emp_representante: "",
                  emp_ruc: "",
                  emp_pagina_web: "",
                  emp_tipo_empresa: "0",
                  emp_360_autos: "",
                  emp_360_suv: "",
                  emp_citas: "",
                };
              });

              setshowModal(false);

              getEmpresas();
              // getData()
            } else {
              showMessage(response.data.message, "error");
            }
          })
          .catch(function (error) {
            showMessage("Campos incompletos", "error");
          });
      } else {
        if (empresa.emp_codigo === "") {
          seterror((error) => {
            return { ...error, codigo: "Campo requerido" };
          });
        }

        if (empresa.emp_id === "") {
          seterror((error) => {
            return { ...error, id: "Campo requerido" };
          });
        }

        if (empresa.emp_razon_social === "") {
          seterror((error) => {
            return { ...error, razon: "Campo requerido" };
          });
        }

        if (empresa.emp_nombre_comercial === "") {
          seterror((error) => {
            return { ...error, nombreco: "Campo requerido" };
          });
        }

        if (empresa.emp_direccion === "") {
          seterror((error) => {
            return { ...error, direccion: "Campo requerido" };
          });
        }

        if (empresa.emp_telefono === "") {
          seterror((error) => {
            return { ...error, telefono: "Campo requerido" };
          });
        }

        if (empresa.emp_email === "") {
          seterror((error) => {
            return { ...error, email: "Campo requerido" };
          });
        }

        if (empresa.emp_representante === "") {
          seterror((error) => {
            return { ...error, representante: "Campo requerido" };
          });
        }

        if (empresa.emp_pagina_web === "") {
          seterror((error) => {
            return { ...error, pagina: "Campo requerido" };
          });
        }

        if (empresa.emp_ruc === "") {
          seterror((error) => {
            return { ...error, ruc: "Campo requerido" };
          });
        }

        if (empresa.emp_360_autos === "") {
          seterror((error) => {
            return { ...error, autos360: "Campo requerido" };
          });
        }

        if (empresa.emp_360_suv === "") {
          seterror((error) => {
            return { ...error, suv360: "Campo requerido" };
          });
        }

        if (empresa.emp_citas === "") {
          seterror((error) => {
            return { ...error, citas: "Campo requerido" };
          });
        }

        showMessage("Campos incompletos", "error");
      }
    }
  };

  useEffect(() => {
    const getCiudad = async () => {
      await axios
        .get(Server+"/api/empresas/getCiudadesHyndai")
        .then(function (response) {
          setDataCiudad(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (first) {
      getEmpresas();
      getCiudad();

      setfirst(false);
    }
  }, [first]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Empresa/ Empresa"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              {/* <div>
                <Card>
                  <div className="text-center">
                    <div
                      className={isLoadingEmpresaCreada}
                      style={{ marginTop: 10 }}
                    >
                      <Spinner
                        animation="border"
                        role="status"
                        variant="secondary"
                      ></Spinner>
                    </div>
                  </div>

                  <Card.Header as="h5">Creación de Nueva Empresa </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Código:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={6}
                            placeholder="Ingrese Código"
                            id="emp_codigo"
                            value={empresa.emp_codigo}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_codigo: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.codigo}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">ID: </Form.Label>
                          <Form.Control
                            maxLength={10}
                            placeholder="Ingrese un ID"
                            id="emp_id"
                            value={empresa.emp_id}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_id: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.id}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Razón Social:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Razón Social"
                            id="emp_razon_soacial"
                            value={empresa.emp_razon_social}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_razon_social: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.razon}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre Comercial:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Nombre Comercial"
                            id="emp_nombre_comercial"
                            value={empresa.emp_nombre_comercial}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return {
                                  ...empresa,
                                  emp_nombre_comercial: val,
                                };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombreco}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Dirección"
                            id="emp_direccion"
                            value={empresa.emp_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Teléfono:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Teléfono"
                            id="emp_telefono"
                            value={empresa.emp_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_telefono: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.telefono}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Email:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Email"
                            id="emp_email"
                            value={empresa.emp_email}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_email: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.email}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Representante:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={50}
                            placeholder="Ingrese un Representate"
                            id="emp_representante"
                            value={empresa.emp_representante}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_representante: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.representante}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Ingrese una Página Web:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={40}
                            placeholder="Ingrese Página Web"
                            id="emp_pagina_web"
                            value={empresa.emp_pagina_web}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_pagina_web: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.pagina}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            RUC:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese un RUC"
                            id="emp_ruc"
                            value={empresa.emp_ruc}
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_ruc: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.ruc}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Ciudad:{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_ciudad: val };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsCiudades}
                          </Form.Control>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Tipo de Empresas:{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = e.target.value;
                              setempresa((empresa) => {
                                return { ...empresa, emp_tipo_empresa: val };
                              });
                            }}
                          >
                            <option value="0">N/A</option>

                            <option value="1">Livianos</option>
                            <option value="2">Pesados</option>
                            <option value="3">Pesados y Livianos</option>
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          onClick={() => {
                            createEmpresa();
                          }}
                          variant="primary"
                        >
                          Crear Empresa
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>
              </div> */}
              <div>
                <Card>
                  <Card.Header as="h5">
                    Listado general de "Empresas"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="EMP_CODIGO"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Empresa para Actualizar
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Código : {empresa.emp_codigo}
                            </Form.Label>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              ID: {empresa.emp_id}
                            </Form.Label>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Razón Social:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Razón Social"
                              id="emp_razon_soacial"
                              value={empresa.emp_razon_social}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_razon_social: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.razon}</span>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Nombre Comercial:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Nombre Comercial"
                              id="emp_nombre_comercial"
                              value={empresa.emp_nombre_comercial}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return {
                                    ...empresa,
                                    emp_nombre_comercial: val,
                                  };
                                });
                              }}
                            />

                            <span style={{ color: "red" }}>
                              {error.nombreco}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Dirección:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={100}
                              placeholder="Dirección"
                              id="emp_direccion"
                              value={empresa.emp_direccion}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_direccion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.direccion}
                            </span>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Teléfono:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Teléfono"
                              id="emp_telefono"
                              value={empresa.emp_telefono}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_telefono: val };
                                });
                              }}
                            />

                            <span style={{ color: "red" }}>
                              {error.telefono}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Email:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Email"
                              id="emp_email"
                              value={empresa.emp_email}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_email: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.email}</span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Representante:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Ingrese un Representate"
                              id="emp_representante"
                              value={empresa.emp_representante}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_representante: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.representante}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Ingrese una Página Web:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={40}
                              placeholder="Ingrese Página Web"
                              id="emp_pagina_web"
                              value={empresa.emp_pagina_web}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_pagina_web: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.pagina}</span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              RUC:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={13}
                              placeholder="Ingrese un RUC"
                              id="emp_ruc"
                              value={empresa.emp_ruc}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_ruc: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.ruc}</span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              360 Autos:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              placeholder="Ingrese un link 360 Autos"
                              id="emp_360_autos"
                              value={empresa.emp_360_autos}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_360_autos: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.autos360}
                            </span>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              360 Suv:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              placeholder="Ingrese un link 360 Suv"
                              id="emp_representante"
                              value={empresa.emp_360_suv}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_360_suv: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.suv360}</span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Url Citas:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={200}
                              placeholder="Ingrese url de citas"
                              id="emp_citas"
                              value={empresa.emp_citas}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_citas: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.citas}</span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Check
                              className="title-create"
                              type="checkbox"
                              label="Estado"
                              checked={setEstado(empresa.emp_estado)}
                              onChange={() => {
                                var newStatus = 0;
                                if (empresa.emp_estado === 0) {
                                  newStatus = 1;
                                } else if (empresa.emp_estado === 1) {
                                  newStatus = 0;
                                }
                                setempresa((empresa) => {
                                  return { ...empresa, emp_estado: newStatus };
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Ciudad:{" "}
                            </Form.Label>

                            <Form.Control
                              as="select"
                              value={empresa.emp_ciudad}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_ciudad: val };
                                });
                              }}
                            >
                              <option value="0">N/A</option>
                              {getRowsCiudades}
                            </Form.Control>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Tipo de Empresas:{" "}
                            </Form.Label>

                            <Form.Control
                              as="select"
                              value={empresa.emp_tipo_empresa}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_tipo_empresa: val };
                                });
                              }}
                            >
                              <option value="0">N/A</option>

                              <option value="1">Livianos</option>
                              <option value="2">Pesados</option>
                              <option value="3">Pesados y Livianos</option>
                            </Form.Control>
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateEmpresa();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        seterror((error) => {
                          return {
                            ...error,
                            codigo: "",
                            id: "",
                            razon: "",
                            nombreco: "",
                            direccion: "",
                            telefono: "",
                            email: "",
                            representante: "",
                            pagina: "",
                            ruc: "",
                            autos360: "",
                            suv360: "",
                            citas: "",
                          };
                        });
                        setempresa((empresa) => {
                          return {
                            ...empresa,
                            emp_codigo: "",
                            emp_id: "",
                            emp_estado: 0,
                            emp_razon_social: "",
                            emp_nombre_comercial: "",
                            emp_ciudad: "0",
                            emp_direccion: "",
                            emp_telefono: "",
                            emp_email: "",
                            emp_representante: "",
                            emp_ruc: "",
                            emp_pagina_web: "",
                            emp_tipo_empresa: "0",
                            emp_360_autos: "",
                            emp_360_suv: "",
                            emp_citas: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
