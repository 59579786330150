import React from "react";
import cx from "classnames";

function PageTitle({ heading, icon }) {

  return (
    <div className="app-page-title app-theme-white ">
      <div className="page-title-wrapper">
        <div className="page-title-heading .app-title-heading-flex">
          <div
            className={cx("page-title-icon", {
              "d-none": false,
            })}
          >
            <i className={icon} />
          </div>
          <div>{heading}</div>
        </div>
      </div>
    </div>
  );
}

export default PageTitle;
