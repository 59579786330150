import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./global.css";
import Index from "./pages/Index";
import ControlPanel from "./pages/ControlPanel";
import RutaLogged from "./components/login/RutaLogged";
import ListadoEmpresa from "./pages/ListadoEmpresa";
import Version from "./components/gestionarversion/GestionarVesion";
import GestionTalento from "./components/gestiontalento/GestionTalento";
import Ventas from "./components/ventas/Ventas";
import Opciones from "./components/opciones/Opciones";
import Modulos from "./components/modulos/Modulos";
import Menu from "./components/menu/Menu";
import Cargo from "./components/cargo/Cargo";
import Roles from "./components/roles/Roles";
import Permisos from "./components/permisos/Permisos";
import Usuarios from "./components/usuarios/Usuarios";
import Orden from "./components/orden/Orden";
import Foto from "./components/foto/Foto";
import Producto from "./components/producto/Producto";
import Ofertames from "./components/ofertames/OfertaMes";
import Bonos from "./components/bonos/Bonos";
import CargaVentas from "./components/cargaventas/CargaVentas";
import AprobarBonos from "./components/aprobarbonos/AprobarBonos";
import PagoBonos from "./components/pagobonos/PagoBonos";
import Privilegios from "./components/privilegios/Privilegios";
import ReporteDespachos from "./components/reportedespachos/ReporteDespachos";
import GestionarModelo from "./components/gestionar/Gestionar";
import GestionarConcesionarios from "./components/ventas/Ventas";
import ActivarDesVehiculo from "./components/activardesveh/ActivarDesVehiculo";
import GestionarEmpresas from "./components/gestionarempresas/GestionarEmpresas";
import GestionarAlmacenes from "./components/gestionaralmacenes/GestionarAlmacenes";
import GestionarCiudades from "./components/gestionarciudades/Gestionarciudades";
import Noticiasprincipales from "./components/noticiasprincipales/NoticiasPrincipales";
import ActivarDesNoticias from "./components/activardesNoticia/ActivarDesNoticia";
import Asignar from "./components/asignar/Asignar";
import NoticiasActualidad from "./components/noticiaActualidad/NoticiaActualidad";
import OrdenNoticia from "./components/ordennoticia/OrdenNoticia";
import Carrusel from "./components/carrusel/Carrusel";
import AsignarOferta from "./components/asignarofertames/AsignarofertaMes";
import OrdenOferta from "./components/ordenarofertames/OrdenarOfertaMes";
import ActivarDesOfertas from "./components/activardesOfertas/ActivardesOfertas";
import RedesSociales from "./components/redesSociales/RedesSociales";
import Facturacion from "./components/facturacion/Facturacion";
import CargaBonos from "./components/cargabonos/CargaBonos";

import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
require("react-bootstrap-table-next/dist/react-bootstrap-table2.min.css");

function App() {
  return (
    <Router>
      <div>
        <Switch>
          <Route component={Index} exact path="/" />
          <Route component={ListadoEmpresa} exact path="/listadoempresa" />
          <RutaLogged component={ControlPanel} path="/dashboard" />
          <RutaLogged component={Version} path="/version" />
          <RutaLogged component={GestionTalento} path="/jefesasesores" />
          <RutaLogged component={Ventas} path="/ventas" />
          <RutaLogged component={Opciones} path="/opciones" />
          <RutaLogged component={Modulos} path="/modulos" />
          <RutaLogged component={Usuarios} path="/usuarios" />
          <RutaLogged component={CargaBonos} path="/cargabonos"/>
          <RutaLogged component={Menu} path="/menu" />
          <RutaLogged component={Cargo} path="/cargo" />
          <RutaLogged component={Roles} path="/roles" />
          <RutaLogged component={Version} path="/version" />
          <RutaLogged component={Orden} path="/orden" />
          <RutaLogged
            component={ActivarDesVehiculo}
            path="/activardesvehiculo"
          />
          <RutaLogged component={Orden} path="/orden" />
          <RutaLogged component={Foto} path="/foto" />
          <RutaLogged component={GestionarModelo} path="/gestionar" />
          <RutaLogged
            component={GestionarConcesionarios}
            path="/gestionarconcesionarios"
          />
          <RutaLogged component={GestionarEmpresas} path="/gestionarempresas" />
          <RutaLogged
            component={GestionarAlmacenes}
            path="/gestionaralmacenes"
          />
          <RutaLogged component={Usuarios} path="/usuarios" />
          <RutaLogged component={Producto} path="/producto" />
          <RutaLogged component={Ofertames} path="/ofertames" />
          <RutaLogged component={Bonos} path="/bonos" />
          <RutaLogged component={AprobarBonos} path="/aprobarbonos" />
          <RutaLogged component={PagoBonos} path="/pagobonos" />
          <RutaLogged component={CargaVentas} path="/cargaventas" />
          <RutaLogged component={Privilegios} path="/privilegios" />
          <RutaLogged component={ReporteDespachos} path="/reportedespachos" />
          <RutaLogged
            component={Noticiasprincipales}
            path="/noticiasprincipales"
          />
          <RutaLogged component={ActivarDesNoticias} path="/actdesnoticas" />
          <RutaLogged component={ActivarDesOfertas} path="/actdesofertas" />
          <RutaLogged component={Asignar} path="/asignar" />
          <RutaLogged
            component={NoticiasActualidad}
            path="/noticiasdeactulidad"
          />
          <RutaLogged component={OrdenNoticia} path="/ordennoticias" />
          <RutaLogged component={Carrusel} path="/carruselorden" />
          <RutaLogged component={AsignarOferta} path="/asignaroferta" />
          <RutaLogged component={OrdenOferta} path="/ordernaroferta" />
          <RutaLogged component={Permisos} path="/permisos" />
          <RutaLogged component={GestionarCiudades} path="/gestionarciudad" />
          <RutaLogged component={RedesSociales} path="/redessociales" />
          <RutaLogged component={Facturacion} path="/facturacion" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
