import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Modal,
  Card,
  Spinner,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [op_nombre, setOp_nombre] = useState("");
  const [op_url, setOp_url] = useState("");
  const [firstTime, setfirstTime] = useState(true);
  const [dataOpciones, setDataOpciones] = useState([]);
  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [opciones, setopciones] = useState();
  const [index, setIndex] = useState();
  const [showModal, setshowModal] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const columns = [
    {
      dataField: "OP_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "OP_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "OP_URL",
      text: "Descripción",
    },
    {
      dataField: "OP_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "PER_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const [error, seterror] = useState({
    nombre: "",
    ruta: "",
  });

  const editpermisos = (row, index) => {
    setOp_nombre(row.OP_NOMBRE);
    setOp_url(row.OP_URL);
    setopciones(row);
    setIndex(index);
    setshowModal(true);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editpermisos(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const setDataOpcionesModal = () => {
    opciones.OP_NOMBRE = op_nombre;
    opciones.OP_URL = op_url;
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    setOp_nombre("");
    setOp_url("");
    setshowModal(false);
    updateEstadoOp(opciones, index);
  };

  const changeEstado = async (key, index) => {
    if (key.OP_ESTADO === 1) {
      dataOpciones.data[index].OP_ESTADO = 0;
      updateEstadoOp(dataOpciones.data[index], index);
    } else if (key.OP_ESTADO === 0) {
      dataOpciones.data[index].OP_ESTADO = 1;
      updateEstadoOp(dataOpciones.data[index], index);
    }
  };

  const updateEstadoOp = (itemOpcion, index) => {
    const dataForm = new FormData();
    dataForm.append("op_id", itemOpcion.OP_CODIGO);
    dataForm.append("op_nombre", itemOpcion.OP_NOMBRE);
    dataForm.append("op_url", itemOpcion.OP_URL);
    dataForm.append("op_estado", itemOpcion.OP_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/opciones/updateOpciones",
        dataForm
      )

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = itemOpcion;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(Server+"/api/opciones/allOpciones")
      .then(function (response) {
        setDataOpciones(response);

        setdataTable(response.data);
        setfirstTime(false);

        setIsLoading("invisible");
      })
      .catch(function (error) {
        setfirstTime(false);
      })
      .then(function () {
        // always executed
        setfirstTime(false);
      });
  };
  useEffect(() => {
    // Make a request for a user with a given ID
    if (firstTime) {
      getData();
    }
  });

  const createOpciones = async () => {
    seterror((error) => {
      return { ...error, nombre: "", ruta: "" };
    });

    if (op_nombre !== "" && op_url !== "") {
      const dataForm = new FormData();
      dataForm.append("op_nombre", op_nombre);
      dataForm.append("op_url", op_url);
      dataForm.append("usuario", data.US_CODIGO);
      await axios
        .post(Server+"/api/opciones/create", dataForm)

        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            setOp_url("");
            setOp_nombre("");
            getData();
            // updateItems()
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (op_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (op_url === "") {
        seterror((error) => {
          return { ...error, ruta: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Editar Permisos
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de Permisos"
                            value={op_nombre}
                            onChange={(event) =>
                              setOp_nombre(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción de Permisos"
                            value={op_url}
                            onChange={(event) => setOp_url(event.target.value)}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setDataOpcionesModal()}>
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);
                        setOp_nombre("");
                        setOp_url("");
                        seterror((error) => {
                          return { ...error, nombre: "", ruta: "" };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                <h3 className="text-center">Gestión de "OPCIONES"</h3>
                <Card>
                  <Card.Header as="h5">
                    Creación de "opcion" para menús
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de opción"
                            value={op_nombre}
                            onChange={(event) =>
                              setOp_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Ruta:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Ruta de opción"
                            value={op_url}
                            onChange={(event) => setOp_url(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createOpciones()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Opciones"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="OP_CODIGO"
                      data={dataTable}
                      columns={columns}
                    />

                    {/* <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>URL</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRows}
                            </tbody>
                        </Table> */}
                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
