import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import { Row, Col, Container} from 'react-bootstrap';

function Content() {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <p>Producto</p>
                
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
