import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as moment from "moment";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import {Server} from "../../constants";

require("react-quill/dist/quill.snow.css");

function Content() {
  const [showModal, setshowModal] = useState(false);
  const [showModalUpdate, setshowModalUpdate] = useState(false);

  const [image2, setimage2] = useState("");
  const [dataTableofertaMarca, setdataTableofertaMarca] = useState([]);

  const [dataTableofertaEmpresa, setdataTableofertaEmpresa] = useState([]);
  const [isLoadingCreate, setisLoadingCreate] = useState("invisible");
  const [isLoadingTableMarca, setisLoadingTableMarca] = useState("invisible");
  const [isLoading, setIsLoading] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [idAlmacen, setidAlmacen] = useState([]);

  const [showofertaEmpresas, setshowofertaEmpresas] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [oferta, setoferta] = useState({
    of_codigo: 0,
    of_url_foto: "",
    of_url: "",
    of_descripcion: "",
    of_tipo: 0,
    of_empresa: 0,
    ofe_codigo: 0,
  });

  const [errorOferta, seterrorOferta] = useState({
    imagen: "",
    descripcion: "",
    url: "",
  });

  const columns = [
    {
      dataField: "OF_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "OF_DESCRIPCION",
      text: "Descripción",
      sort: true,
    },

    {
      dataField: "OF_EDITAR",
      text: "Editar",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "OFE_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "OF_DESCRIPCION",
      text: "Descripción",
      sort: true,
    },

    {
      dataField: "OFE_EDITAR",
      text: "Editar",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => editRowEmpresa(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setimage2("");
    setoferta((oferta) => {
      return {
        ...oferta,
        of_tipo: 0,
        of_empresa: 0,
        of_url_foto: row.OF_URL_FOTO,
        of_url: row.OF_URL,
        of_descripcion: row.OF_DESCRIPCION,
        ofe_codigo: 0,
        of_codigo: row.OF_CODIGO,
      };
    });

    setshowModalUpdate(true);
  };

  const editRowEmpresa = (row, index) => {
    return (
      <Button
        onClick={() => {
          editEmpresa(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editEmpresa = (row, index) => {
    setimage2("");
    setoferta((oferta) => {
      return {
        ...oferta,
        of_empresa: idAlmacen,
        ofe_codigo: row.OFE_CODIGO,
        of_url_foto: row.OF_URL_FOTO,
        of_url: row.OF_URL,
        of_descripcion: row.OF_DESCRIPCION,
        of_codigo: row.OF_CODIGO,
      };
    });

    setshowModalUpdate(true);
  };

  const modalFoto = (foto) => {
    if (foto !== "") {
      return (
        <img
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            height: "40%",
          }}
          src={foto}
          alt=""
        />
      );
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const selectFile = (event) => {
    setimage2(event.target.files[0]);
    //setimage(URL.createObjectURL(event.target.files[0]))
    const val = URL.createObjectURL(event.target.files[0]);
    setoferta((oferta) => {
      return { ...oferta, of_url_foto: val };
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const createoferta = async () => {
    seterrorOferta((errorOferta) => {
      return { ...errorOferta, imagen: "", descripcion: "", url: "" };
    });

    if (
      image2 !== null &&
      oferta.not_titulo !== "" &&
      oferta.of_descripcion !== "" &&
      oferta.of_url_foto !== ""
    ) {
      const data2 = new FormData();
      data2.append("file", image2);
      data2.append("of_url", oferta.of_url);
      data2.append("of_descripcion", oferta.of_descripcion);
      data2.append("of_tipo", oferta.of_tipo);
      data2.append("of_empresa", oferta.of_empresa);
      data2.append("ofe_codigo", oferta.ofe_codigo);
      data2.append("usuario", data.US_CODIGO);
      setisLoadingCreate("visible");

      await axios
        .post(Server+"/api/empresas/CreateOferta", data2)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreate("invisible");
            setshowModal(false);
            showMessage(response.data.message, "success");

            if (oferta.of_tipo === 0) {
              getOfertasMarca();
            } else if (oferta.of_tipo === 1) {
              getList(oferta.of_empresa);
            }
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreate("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (image2 === null || image2 === "") {
        seterrorOferta((errorOferta) => {
          return { ...errorOferta, imagen: "Imagen Requerida" };
        });
      }

      if (oferta.of_descripcion === "") {
        seterrorOferta((errorOferta) => {
          return { ...errorOferta, descripcion: "Campo Requerido" };
        });
      }

      if (oferta.of_url_foto === "") {
        seterrorOferta((errorOferta) => {
          return { ...errorOferta, url: "Campo Requerido" };
        });
      }

      showMessage("Campos Vacios Por favor Completarlos", "error");
    }
  };

  const updateoferta = async () => {
    var newImagen = 0;

    if (image2 !== "") {
      newImagen = 1;
    }

    seterrorOferta((errorOferta) => {
      return { ...errorOferta, imagen: "", descripcion: "", url: "" };
    });

    console.log(oferta);

    if (oferta.of_descripcion !== "" && oferta.of_url_foto !== "") {
      const data2 = new FormData();
      data2.append("file", image2);
      data2.append("of_url", oferta.of_url);
      data2.append("of_descripcion", oferta.of_descripcion);
      data2.append("of_url_foto", oferta.of_url_foto);
      data2.append("of_tipo", oferta.of_tipo);
      data2.append("of_new_imagen", newImagen);
      data2.append("of_empresa", oferta.of_empresa);
      data2.append("of_codigo", oferta.of_codigo);
      data2.append("ofe_codigo", oferta.ofe_codigo);
      data2.append("usuario", data.US_CODIGO);

      setisLoadingCreate("visible");

      await axios
        .post(Server+"/api/empresas/updateOferta", data2)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreate("invisible");
            setshowModalUpdate(false);
            showMessage(response.data.message, "success");

            if (oferta.of_empresa === 0) {
              getOfertasMarca();
            } else {
              getList(oferta.of_empresa);
            }
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreate("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (oferta.of_descripcion === "") {
        seterrorOferta((errorOferta) => {
          return { ...errorOferta, descripcion: "Campo Requerido" };
        });
      }

      if (oferta.of_url_foto === "") {
        seterrorOferta((errorOferta) => {
          return { ...errorOferta, url: "Campo Requerido" };
        });
      }

      showMessage("Campos Vacios Por favor Completarlos", "error");
    }
  };

  const getOfertasMarca = async () => {
    setisLoadingTableMarca("visble");
    await axios
      .get(Server+"/api/empresas/getOfertasMarca")

      .then(function (response) {
        setisLoadingTableMarca("invisible");
        setdataTableofertaMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getList = async (idAlmacen) => {
    // setshowTable(false)

    setshowofertaEmpresas(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getOfertasMarcaForEmpresa/" +
            idAlmacen
        )

        .then(function (response) {
          setidAlmacen(idAlmacen);
          setIsLoading("invisible");
          setshowofertaEmpresas(true);
          setdataTableofertaEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const listofertaEmpresa = () => {
    if (showofertaEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">ofertas</Card.Header>
            <Card.Body>
              <Button
                variant="primary"
                onClick={() => {
                  setshowModal(true);
                  setoferta((oferta) => {
                    return {
                      ...oferta,
                      of_tipo: 1,
                      of_empresa: idAlmacen,
                      of_url_foto: "",
                      not_fecha: moment(new Date()).format("yyyy-MM-DD"),

                      of_url: "",

                      ofe_codigo: 0,
                      of_descripcion: "",
                    };
                  });
                }}
              >
                Crear Oferta
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="OFE_CODIGO"
                  data={dataTableofertaEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                />
              </div>

              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getOfertasMarca();
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowofertaEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Ofertas de Marca</Card.Header>

            <Card.Body>
              <Button
                variant="primary"
                onClick={() => {
                  setshowModal(true);
                  setoferta((oferta) => {
                    return {
                      ...oferta,
                      of_tipo: 0,
                      of_url_foto: "",
                      of_url: "",
                      ofe_codigo: 0,
                      of_descripcion: "",
                    };
                  });
                }}
              >
                Crear Oferta
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="OF_CODIGO"
                  data={dataTableofertaMarca}
                  columns={columns}
                  pagination={paginationFactory()}
                />

                <div className="text-center">
                  <div
                    className={isLoadingTableMarca}
                    style={{ marginTop: 10 }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus ofertas
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {listofertaEmpresa()}
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listofertaEmpresa()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />

          {marcaoEmpresa()}

          {/* <div style={{marginTop:10}}>
        <Card >
              <Card.Header as="h5">Ofertas de Marca</Card.Header>
              
              <Card.Body>
              <Button variant="primary" onClick={() => {setshowModal(true)
                                                  setoferta(oferta => {
                                                    return { ...oferta,  of_tipo: 0,
                                                                          of_url_foto: "",
                                                                          of_url: "",
                                                                          ofe_codigo:0,
                                                                          of_descripcion: "" }}); 
                                                
                                                }} >Crear Oferta</Button>



                <div style={{marginTop:10}}>                                  
              <BootstrapTable
                  keyField="OF_CODIGO"
                  data={dataTableofertaMarca}
                  columns={columns}
                  pagination={paginationFactory()}
              />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>

        
              </div>

              </Card.Body>
          </Card>

          <div style={{marginTop:10}}>

            <Card>
            <Card.Header as="h5">Elija una empresa Para Ver sus ofertas</Card.Header>
            <Card.Body>

              <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                      <option value="0">N/A</option>
                      {getRowsEmpresas}

                </Form.Control>

                <div style={{marginTop:5}} className="text-center">
                <div className={isLoading}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            </div>
                </Card.Body>
                </Card>
              </div>


            {listofertaEmpresa()}

      </div> */}

          <Row>
            <div>
              <Modal
                show={showModal}
                // onHide={!showModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Crear una Oferta
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormGroup>
                    <Form.Label className="title-create">
                      Imagen de Oferta:{" "}
                    </Form.Label>

                    <FormGroup>{modalFoto(oferta.of_url_foto)}</FormGroup>

                    <FormGroup>
                      <label className="btn btn-default">
                        <input
                          type="file"
                          onChange={selectFile}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </label>
                    </FormGroup>

                    <span style={{ color: "red" }}>{errorOferta.imagen}</span>

                    <span style={{ color: "gray", fontSize: 12 }}>
                      El tamaño de la imagen debe ser 800px * 600px y formato
                      .jpg
                    </span>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Descripción:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese una Descripción"
                        id="not_titulo"
                        value={oferta.of_descripcion}
                        onChange={(e) => {
                          const val = e.target.value;
                          setoferta((oferta) => {
                            return { ...oferta, of_descripcion: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorOferta.descripcion}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">URL: </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese una URL"
                        id="not_titulo"
                        value={oferta.of_url}
                        onChange={(e) => {
                          const val = e.target.value;
                          setoferta((oferta) => {
                            return { ...oferta, of_url: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorOferta.url}</span>
                    </Col>
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => createoferta()}>Crear Oferta</Button>
                  <Button
                    onClick={() => {
                      setshowModal(false);
                      setoferta((oferta) => {
                        return {
                          ...oferta,
                          of_tipo: 0,
                          of_url_foto: "",
                          of_url: "",
                          ofe_codigo: 0,
                          of_descripcion: "",
                        };
                      });
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>

                <div className="text-center">
                  <div className={isLoadingCreate} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Modal>
            </div>

            <div>
              <Modal
                show={showModalUpdate}
                // onHide={!showModalUpdate}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar oferta
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormGroup>
                    <Form.Label className="title-create">
                      Imagen de Oferta:{" "}
                    </Form.Label>

                    <FormGroup>{modalFoto(oferta.of_url_foto)}</FormGroup>

                    <FormGroup>
                      <label className="btn btn-default">
                        <input
                          type="file"
                          onChange={selectFile}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </label>
                    </FormGroup>

                    <span style={{ color: "red" }}>{errorOferta.imagen}</span>
                    <span style={{ color: "gray", fontSize: 12 }}>
                      El tamaño de la imagen debe ser 800 * 600
                    </span>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Descripción:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese una Descripción"
                        id="not_titulo"
                        value={oferta.of_descripcion}
                        onChange={(e) => {
                          const val = e.target.value;
                          setoferta((oferta) => {
                            return { ...oferta, of_descripcion: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorOferta.descripcion}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">URL: </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese una URL"
                        id="not_titulo"
                        value={oferta.of_url}
                        onChange={(e) => {
                          const val = e.target.value;
                          setoferta((oferta) => {
                            return { ...oferta, of_url: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorOferta.url}</span>
                    </Col>
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => updateoferta()}>
                    Actualizar oferta
                  </Button>
                  <Button
                    onClick={() => {
                      setshowModalUpdate(false);
                      setoferta((oferta) => {
                        return {
                          ...oferta,
                          of_tipo: 0,
                          of_url_foto: "",
                          of_url: "",
                          ofe_codigo: 0,
                          of_descripcion: "",
                        };
                      });
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>

                <div className="text-center">
                  <div className={isLoadingCreate} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Modal>
            </div>
          </Row>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
