import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Form,
  Modal,
  Button,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTablenoticasMarca, setdataTablenoticasMarca] = useState([]);
  const [isLoadingTableMarca, setisLoadingTableMarca] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [isLoadingU, setisLoadingU] = useState("invisible");
  const [idEmpresa, setidEmpresa] = useState();
  const [idNoticia, setidNoticia] = useState();
  const [showNoticasEmpresas, setshowNoticasEmpresas] = useState(false);
  const [dataTablenoticasEmpresa, setdataTablenoticasEmpresa] = useState([]);
  const [showModal, setshowModal] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  // const {data} = infoUsuario;
  const [data] = useState(infoUsuario.data);

  const columns = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOT_ESTADO",
      text: "Activar/Desactivar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "NOTE_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOTE_ESTADO",
      text: "Activar/Desactivar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactiveEmpresa(row, index),
      sort: true,
    },
  ];

  const getList = async (idAlmacen) => {
    setshowNoticasEmpresas(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getNoticiasallMarcaForEmpresa/" +
            idAlmacen
        )

        .then(function (response) {
          // setidAlmacen(idAlmacen)
          setIsLoading("invisible");
          setshowNoticasEmpresas(true);
          setdataTablenoticasEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };
  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.NOT_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const activeDesactiveEmpresa = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.NOTE_ESTADO)}
        onChange={() => changeEstadoEmpresa(row, index)}
      />
    );
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const changeEstado = async (row, index) => {
    var newEs = 0;
    if (row.NOT_ESTADO === 1) {
      newEs = 0;
    } else if (row.NOT_ESTADO === 0) {
      newEs = 1;
    }
    updateNoticia(0, row.NOT_CODIGO, newEs, row.NOT_ACTUALIDAD);
  };

  const changeEstadoEmpresa = async (row, index) => {
    var newEs = 0;
    if (row.NOTE_ESTADO === 1) {
      newEs = 0;
    } else if (row.NOTE_ESTADO === 0) {
      newEs = 1;
    }
    updateNoticia(
      row.NOTE_EMPRESA,
      row.NOTE_CODIGO,
      newEs,
      row.NOTE_ACTUALIDAD
    );
  };

  const updateNoticia = async (
    idEmpresa,
    idNoticia,
    status,
    noticiaActualidad
  ) => {
    if (noticiaActualidad === 0) {
      setIsLoading("visible");

      const dataForm = new FormData();
      dataForm.append("not_empresa", idEmpresa);
      dataForm.append("not_noticia", idNoticia);
      dataForm.append("not_estado", status);
      dataForm.append("not_actualidad", 0);
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      await axios
        .post(
          Server+"/api/empresas/activardesNoticia",
          dataForm
        )
        .then(function (response) {
          setIsLoading("invisible");

          if (response.data.success === true) {
            showMessage(response.data.message, "success");

            if (idEmpresa === 0) {
              getNoticiasMarca();
            } else {
              getList(idEmpresa);
            }
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {});
    } else {
      if (status === 0) {
        setidEmpresa(idEmpresa);
        setidNoticia(idNoticia);

        setshowModal(true);
      } else {
        const dataForm = new FormData();
        dataForm.append("not_empresa", idEmpresa);
        dataForm.append("not_noticia", idNoticia);
        dataForm.append("not_estado", status);
        dataForm.append("not_actualidad", 0);
        dataForm.append("_method", "PUT");

        setIsLoading("visible");
        await axios
          .post(
            Server+"/api/empresas/activardesNoticia",
            dataForm
          )

          .then(function (response) {
            setIsLoading("invisible");

            if (response.data.success === true) {
              showMessage(response.data.message, "success");

              if (idEmpresa === 0) {
                getNoticiasMarca();
              } else {
                getList(idEmpresa);
              }
            } else {
              showMessage(response.data.message, "error");
            }
          })
          .catch(function (error) {});
      }
    }
  };

  const updateNoticiaModal = async (idEmpresa, idNoticia, status) => {
    setisLoadingU("visible");

    const dataForm = new FormData();
    dataForm.append("not_empresa", idEmpresa);
    dataForm.append("not_noticia", idNoticia);
    dataForm.append("not_estado", status);
    dataForm.append("not_actualidad", 1);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    await axios
      .post(
        Server+"/api/empresas/activardesNoticia",
        dataForm
      )
      .then(function (response) {
        setisLoadingU("invisible");
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          setshowModal(false);

          if (idEmpresa === 0) {
            getNoticiasMarca();
          } else {
            getList(idEmpresa);
          }
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const getNoticiasMarca = async () => {
    setisLoadingTableMarca("visble");
    await axios
      .get(Server+"/api/empresas/getAllNoticiasMarca")

      .then(function (response) {
        setisLoadingTableMarca("invisible");
        setdataTablenoticasMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const listNoticasEmpresa = () => {
    if (showNoticasEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias</Card.Header>
            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                />
              </div>

              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias de Marca</Card.Header>

            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
                />

                <div className="text-center">
                  <div
                    className={isLoadingTableMarca}
                    style={{ marginTop: 10 }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus Noticias
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {listNoticasEmpresa()}
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listNoticasEmpresa()}</div>;
    }
  };
  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getNoticiasMarca();
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowNoticasEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                {marcaoEmpresa()}
                {/* <div style={{marginTop:10}}>
        <Card >
              <Card.Header as="h5">Noticias de Marca</Card.Header>
              
              <Card.Body>
            
                <div style={{marginTop:10}}>                                  
              <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
              />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>

        
              </div>

              </Card.Body>
          </Card>

          <div style={{marginTop:10}}>

            <Card>
            <Card.Header as="h5">Elija una empresa Para Ver sus Noticias</Card.Header>
            <Card.Body>

              <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                      <option value="0">N/A</option>
                      {getRowsEmpresas}

                </Form.Control>

                <div style={{marginTop:5}} className="text-center">
                <div className={isLoading}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            </div>
                </Card.Body>
                </Card>
              </div>

              {listNoticasEmpresa()}


           

      </div> */}

                <div>
                  <Modal
                    show={showModal}
                    // onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Noticia de Actualidad Predeterminada desea Actualizarla?
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                      <Button
                        onClick={() =>
                          updateNoticiaModal(idEmpresa, idNoticia, 0)
                        }
                      >
                        Actualizar Noticia
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>

                    <div className="text-center">
                      <div className={isLoadingU} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
