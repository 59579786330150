import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTablenoticasMarca, setdataTablenoticasMarca] = useState([]);
  const [dataTablenoticasMarcaEmpresa, setdataTablenoticasMarcaEmpresa] =
    useState([]);
  const [dataTableOfertaEmpresa, setdataTableOfertaEmpresa] = useState([]);

  const [showModal, setshowModal] = useState(false);
  const [isLoadingEstado, setisLoadingEstado] = useState("invisible");
  const [tipo, settipo] = useState(1);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const columns = [
    {
      dataField: "OF_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "OF_DESCRIPCION",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "OF_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsAempresas = [
    {
      dataField: "OF_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "OF_DESCRIPCION",
      text: "Descripción",
      sort: true,
    },

    {
      dataField: "OF_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactiveforEmpresa(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "OFE_NOMBRE_EMPRESA",
      text: "Empresa",
      sort: true,
    },

    {
      dataField: "OFE_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactiveforEmpresa = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.OF_ESTADO)}
        onChange={() => changeEstadoEmpresa(row, index)}
      />
    );
  };

  const changeEstadoEmpresa = async (row, index) => {
    if (row.OF_ESTADO === 1) {
      row.OF_ESTADO = 0;
    } else if (row.OF_ESTADO === 0) {
      row.OF_ESTADO = 1;
    }

    updateEstadoEmpresa(row, index);
  };

  const updateEstadoEmpresa = (item, index) => {
    const dataForm = new FormData();
    dataForm.append("ofe_oferta", item.OF_CODIGO);
    dataForm.append("ofe_empresa", data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    dataForm.append("ofe_estado", item.OF_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/empresas/asignarOferta",
        dataForm
      )

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTablenoticasMarcaEmpresa];
          newArr[index] = item;
          setdataTablenoticasMarcaEmpresa(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.OFE_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const changeEstado = async (row, index) => {
    if (row.OFE_ESTADO === 1) {
      row.OFE_ESTADO = 0;
    } else if (row.OFE_ESTADO === 0) {
      row.OFE_ESTADO = 1;
    }

    updateEstado(row, index);
  };

  const updateEstado = (item, index) => {
    setisLoadingEstado("visible");

    const dataForm = new FormData();
    dataForm.append("ofe_oferta", item.OFE_OFERTA);
    dataForm.append("ofe_empresa", item.OFE_EMPRESA);
    dataForm.append("ofe_estado", item.OFE_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/empresas/asignarOferta",
        dataForm
      )

      .then(function (response) {
        setisLoadingEstado("invisible");

        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTableOfertaEmpresa];
          newArr[index] = item;
          setdataTableOfertaEmpresa(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const edit = (row, index) => {
    // setOfertaempresa(Ofertaempresa => {
    //   return { ...Ofertaempresa,
    //                         note_Oferta: row.NOT_CODIGO,
    //                         note_empresa:'0',
    //                        }});

    getAsignaOfertaEm(row.OF_CODIGO);
  };

  const getAsignaOfertaEm = async (idOferta) => {
    await axios
      .get(
        Server+"/api/empresas/getListActiveOferta/" +
          idOferta
      )

      .then(function (response) {
        setdataTableOfertaEmpresa(response.data.data);
        setshowModal(true);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getOfertasMarca = async () => {
    await axios
      .get(Server+"/api/empresas/getAllOfertaMarca")

      .then(function (response) {
        setdataTablenoticasMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  useEffect(() => {
    const getNoticiasMarcaEmpresa = async (idEmpresa) => {
      await axios
        .get(
          Server+"/api/empresas/getAllOfertaMarcaForEmpresa/" +
            idEmpresa
        )

        .then(function (response) {
          setdataTablenoticasMarcaEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      // getOfertasMarca()
      // getDataEmpresas()

      settipo(1);
      getOfertasMarca();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      // setshowofertaEmpresas(true)
      // getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO)
      settipo(2);
      getNoticiasMarcaEmpresa(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const typeAsignar = (typo) => {
    if (typo === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <BootstrapTable
            keyField="OF_CODIGO"
            data={dataTablenoticasMarca}
            columns={columns}
            pagination={paginationFactory()}
          />
        </div>
      );
    } else if (typo === 2) {
      return (
        <div style={{ marginTop: 10 }}>
          <BootstrapTable
            keyField="OF_CODIGO"
            data={dataTablenoticasMarcaEmpresa}
            columns={columnsAempresas}
            pagination={paginationFactory()}
          />
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home / Asignar"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <div style={{ marginTop: 10 }}>
                  <Card>
                    <Card.Header as="h5">Ofertas de Marca</Card.Header>

                    <Card.Body>
                      {/* <div style={{marginTop:10}}>                                  
              <BootstrapTable
                  keyField="OF_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
              />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>



        
              </div> */}

                      {typeAsignar(tipo)}
                    </Card.Body>
                  </Card>
                </div>

                <div>
                  <Modal
                    show={showModal}
                    // onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Seleccione una empresa para asignar Oferta?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="text-center">
                        <div className={isLoadingEstado}>
                          <Spinner
                            animation="border"
                            role="status"
                            variant="secondary"
                          ></Spinner>
                        </div>
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <BootstrapTable
                          keyField="OFE_EMPRESA"
                          data={dataTableOfertaEmpresa}
                          columns={columnsEmpresa}
                          pagination={paginationFactory()}
                        />
                      </div>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
