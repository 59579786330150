import React, {Fragment, useState, useEffect} from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
    Row,
    Col,
    Form,
    Card,
    FormGroup,
    Button,
    Spinner,
    Modal,
} from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
    const [getRowsEmpresas, setRowsEmpresas] = useState([]);
    const [isLoadingModal, setisLoadingModal] = useState("invisible");
    const [dataTable, setdataTable] = useState([]);
    const [isLoading, setIsLoading] = useState("invisible");

    const [showTable, setshowTable] = useState(false);

    const [showModal, setshowModal] = useState(false);

    const ls = new SecureLS({
        encodingType: "des",
        isCompression: false,
        encryptionSecret: "admin-hyundai",
    });
    const {infoUsuario} = ls.get("ask-hy");
    const [data] = useState(infoUsuario.data);

    const [concesionarios, setconcesionarios] = useState({
        alm_mapas_url: "",
        alm_latitud_mapa: "",
        alm_longitud_mapa: "",
        alm_zoom: "",
        alm_imagen_mapa_venta: "",
        alm_imagen_mapa_taller: "",
        alm_mapas: 0,
    });

    const [rowConsesionario, setrowConsesionario] = useState();

    const columns = [
        {
            dataField: "ALM_CODIGO",
            text: "Codigo",
            sort: true,
        },
        {
            dataField: "ALM_NOMBRE",
            text: "Nombre",
            sort: true,
        },
        {
            dataField: "ALM_MAPAS_URL",
            text: "Url",
            sort: true,
        },
        {
            dataField: "ALM_LATITUD_MAPA",
            text: "Latitud",
            sort: true,
        },
        {
            dataField: "ALM_LONGITUD_MAPA",
            text: "Longitud",
            sort: true,
        },

        {
            dataField: "ALM_ZOOM",
            text: "Zoom",
            sort: true,
        },
        {
            dataField: "ALM_IMAGEN_MAPA_VENTA",
            text: "Imagen Venta",
            sort: true,
        },
        {
            dataField: "ALM_IMAGEN_MAPA_TALLER",
            text: "Imagen Taller",
            sort: true,
        },
        {
            dataField: "ALM_EDITAR",
            text: "Editar",
            formatter: (cell, row, index) => editRow(row, index),
            sort: true,
        },
    ];

    const editRow = (row, index) => {
        return (
            <Button
                onClick={() => {
                    edit(row, index);
                }}
                color="primary"
                variant="contained"
            >
                <FontAwesomeIcon icon={faEdit}/>
            </Button>
        );
    };

    const edit = (row, index) => {
        setconcesionarios((concesionarios) => {
            return {
                ...concesionarios,
                alm_mapas_url: row.ALM_MAPAS_URL,
                alm_latitud_mapa: row.ALM_LATITUD_MAPA,
                alm_longitud_mapa: row.ALM_LONGITUD_MAPA,
                alm_zoom: row.ALM_ZOOM,
                alm_imagen_mapa_venta: row.ALM_IMAGEN_MAPA_VENTA,
                alm_imagen_mapa_taller: row.ALM_IMAGEN_MAPA_TALLER,
                alm_mapas: row.ALM_MAPAS,
            };
        });
        setrowConsesionario(row);
        setshowModal(true);
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
    });

    const setEstado = (estado) => {
        if (estado === 1) {
            return true;
        } else if (estado === 0) {
            return false;
        }
    };

    const showMessage = (msn, type) => {
        Toast.fire({
            title: msn,
            // text: msn,
            icon: type,
            confirmButtonText: "Entendido",
        });
    };

    const updateConsesionarios = () => {
        setisLoadingModal("visible");

        const dataForm = new FormData();

        dataForm.append("alm_codigo", rowConsesionario.ALM_CODIGO);
        dataForm.append("alm_empresa", rowConsesionario.ALM_EMPRESA);
        dataForm.append("alm_mapas_url", concesionarios.alm_mapas_url);
        dataForm.append("alm_latitud_mapa", concesionarios.alm_latitud_mapa);
        dataForm.append("alm_longitud_mapa", concesionarios.alm_longitud_mapa);
        dataForm.append("alm_zoom", concesionarios.alm_zoom);
        dataForm.append("alm_mapas", concesionarios.alm_mapas);
        dataForm.append(
            "alm_imagen_mapa_venta",
            concesionarios.alm_imagen_mapa_venta
        );
        dataForm.append(
            "alm_imagen_mapa_taller",
            concesionarios.alm_imagen_mapa_taller
        );
        dataForm.append("usuario", data.US_CODIGO);
        dataForm.append("_method", "PUT");

        axios
            .post(
                Server + "/api/empresas/UpdateConsesiones",
                dataForm
            )

            .then(function (response) {
                setisLoadingModal("invisible");

                if (response.data.success === true) {
                    showMessage(response.data.message, "success");

                    setconcesionarios((concesionarios) => {
                        return {
                            ...concesionarios,
                            alm_mapas_url: "",
                            alm_latitud_mapa: "",
                            alm_longitud_mapa: "",
                            alm_zoom: "",
                            alm_imagen_mapa_venta: "",
                            alm_imagen_mapa_taller: "",
                            alm_mapas: 0,
                        };
                    });

                    getList(rowConsesionario.ALM_EMPRESA);
                } else {
                    showMessage(response.data.message, "error");
                }

                setshowModal(false);
            })
            .catch(function (error) {
                setisLoadingModal("invisible");
                showMessage("error en el servidor o revisa los datos", "error");
            });
    };

    useEffect(() => {
        const getDataEmpresas = async () => {
            await axios
                .get(
                    Server + "/api/empresas/getListEmpresasActivas"
                )

                .then(function (response) {
                    setEmpresaRows(response.data.data);
                })
                .catch(function (error) {
                })
        };

        if (data.US_PERMISOS[0].PER_CODIGO === 1) {
            getDataEmpresas();
        } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
            setshowTable(true);
            getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
        }
    }, [data]);

    const setEmpresaRows = (data) => {
        const rows = data.map((row, index) => (
            <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
                {row.EMP_NOMBRE_COMERCIAL}
            </option>
        ));
        setRowsEmpresas(rows);
    };

    const getList = async (idAlmacen) => {
        setshowTable(false);
        setIsLoading("visible");

        await axios
            .get(
                Server + "/api/empresas/getListAlmacenesForEmpresa/" +
                idAlmacen
            )

            .then(function (response) {
                setIsLoading("invisible");

                setshowTable(true);
                setdataTable(response.data.data);
            })
            .catch(function (error) {
            })
    };

    const drawList = () => {
        if (showTable) {
            return (
                <div style={{marginTop: 10}}>
                    <Card>
                        <Card.Header as="h5">
                            Listado general de "Almacenes en Mapas"
                        </Card.Header>
                        <Card.Body>
                            <BootstrapTable
                                keyField="ALM_CODIGO"
                                data={dataTable}
                                columns={columns}
                            />
                        </Card.Body>
                    </Card>
                </div>
            );
        }
    };

    const marcaoEmpresa = () => {
        if (data.US_PERMISOS[0].PER_CODIGO === 1) {
            return (
                <Col md="12" lg="12">
                    <div>
                        <Card>
                            <Card.Body>
                                <h3 className="text-center">Elija una empresa</h3>
                                <Form.Control
                                    as="select"
                                    onChange={(e) => {
                                        getList(e.target.value);
                                    }}
                                >
                                    <option value="0">N/A</option>
                                    {getRowsEmpresas}
                                </Form.Control>

                                <div style={{marginTop: 5}} className="text-center">
                                    <div className={isLoading}>
                                        <Spinner animation="border" role="status"></Spinner>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>

                    {drawList()}
                </Col>
            );
        } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
            return <div>{drawList()}</div>;
        }
    };

    return (
        <Fragment>
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}
            >
                <div>
                    <PageTitle
                        heading="Empresa/ Concesionarios"
                        icon="pe-7s-user icon-gradient bg-mean-fruit"
                    />
                    <Row>
                        {marcaoEmpresa()}
                        {/* <Col md="12" lg="12">
              <div>

              <Card>
              <Card.Body>
              <h3 className="text-center">Elija una empresa</h3>
                <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                        <option value="0">N/A</option>
                        {getRowsEmpresas}

                  </Form.Control>

                  <div style={{marginTop:5}} className="text-center">
                  <div className={isLoading}>
                      <Spinner animation="border" role="status">
                      </Spinner>
                  </div>
              </div>
                  </Card.Body>
                  </Card>
                </div>





              {drawList()}

              </Col> */}
                    </Row>

                    <div>
                        <Modal
                            show={showModal}
                            // onHide={!showModal}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header>
                                <Modal.Title id="contained-modal-title-vcenter">
                                    Editar Concesionarios
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <FormGroup as={Row}>
                                    <Col>
                                        <Form.Label className="title-create">
                                            Url de Mapa:{" "}
                                        </Form.Label>
                                        <Form.Control
                                            maxLength={100}
                                            placeholder="Ingrese una Url"
                                            id="alm_url"
                                            value={concesionarios.alm_mapas_url}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {...concesionarios, alm_mapas_url: val};
                                                });
                                            }}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Col>
                                        <Form.Label className="title-create">Latitud: </Form.Label>
                                        <Form.Control
                                            maxLength={1000}
                                            placeholder="Ingrese Latitud"
                                            id="alm_latitud"
                                            value={concesionarios.alm_latitud_mapa}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {...concesionarios, alm_latitud_mapa: val};
                                                });
                                            }}
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Label className="title-create">Longitud: </Form.Label>
                                        <Form.Control
                                            maxLength={1000}
                                            placeholder="Ingrese Longitud"
                                            id="alm_longitud"
                                            value={concesionarios.alm_longitud_mapa}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {...concesionarios, alm_longitud_mapa: val};
                                                });
                                            }}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Col>
                                        <Form.Label className="title-create">
                                            Zoom Mapa:{" "}
                                        </Form.Label>
                                        <Form.Control
                                            maxLength={13}
                                            placeholder="Ingrese un Zoom para Mapa"
                                            id="alm_zoom"
                                            value={concesionarios.alm_zoom}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {...concesionarios, alm_zoom: val};
                                                });
                                            }}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Col>
                                        <Form.Label className="title-create">
                                            Imagen Venta:{" "}
                                        </Form.Label>
                                        <Form.Control
                                            maxLength={250}
                                            placeholder="Ingrese una Imagen Venta"
                                            id="alm_imagen_venta"
                                            value={concesionarios.alm_imagen_mapa_venta}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {
                                                        ...concesionarios,
                                                        alm_imagen_mapa_venta: val,
                                                    };
                                                });
                                            }}
                                        />
                                    </Col>

                                    <Col>
                                        <Form.Label className="title-create">
                                            Imagen Taller:{" "}
                                        </Form.Label>
                                        <Form.Control
                                            maxLength={250}
                                            placeholder="Ingrese una Imagen Taller"
                                            id="alm_img_taller"
                                            value={concesionarios.alm_imagen_mapa_taller}
                                            onChange={(e) => {
                                                const val = e.target.value;
                                                setconcesionarios((concesionarios) => {
                                                    return {
                                                        ...concesionarios,
                                                        alm_imagen_mapa_taller: val,
                                                    };
                                                });
                                            }}
                                        />
                                    </Col>
                                </FormGroup>

                                <FormGroup as={Row}>
                                    <Col>
                                        <Form.Check
                                            className="title-create"
                                            type="checkbox"
                                            label="Estado"
                                            checked={setEstado(concesionarios.alm_mapas)}
                                            onChange={() => {
                                                var newStatus = 0;
                                                if (concesionarios.alm_mapas === 0) {
                                                    newStatus = 1;
                                                } else if (concesionarios.alm_mapas === 1) {
                                                    newStatus = 0;
                                                }
                                                setconcesionarios((concesionarios) => {
                                                    return {...concesionarios, alm_mapas: newStatus};
                                                });
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => updateConsesionarios()}>
                                    Actualizar
                                </Button>
                                <Button
                                    onClick={() => {
                                        setshowModal(false);
                                        setconcesionarios((concesionarios) => {
                                            return {
                                                ...concesionarios,
                                                alm_mapas_url: "",
                                                alm_latitud_mapa: "",
                                                alm_longitud_mapa: "",
                                                alm_zoom: "",
                                                alm_imagen_mapa_venta: "",
                                                alm_imagen_mapa_taller: "",
                                                alm_mapas: 0,
                                            };
                                        });
                                    }}
                                >
                                    Cerrar
                                </Button>
                            </Modal.Footer>

                            <div style={{marginTop: 5}} className="text-center">
                                <div className={isLoadingModal}>
                                    <Spinner animation="border" role="status"></Spinner>
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </ReactCSSTransitionGroup>
        </Fragment>
    );
}

export default Content;
