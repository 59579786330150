import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Modal,
  Card,
  Spinner,
  Container,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import filterFactory from "react-bootstrap-table2-filter";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const { SearchBar } = Search;
  const [firstTime, setfirstTime] = useState(true);
  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  const [ciudad, setciudad] = useState({
    cui_codigo: 0,
    ciu_latitud: 0,
    ciu_longitud: 0,
    ciu_latitud_point: 0,
    ciu_longitud_point: 0,
    ciu_zoom: 0,
    ciu_estado: 0,
  });

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const columns = [
    {
      dataField: "CIU_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "CIU_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "CIU_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "PER_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editpermisos = (row, index) => {
    setciudad((ciudad) => {
      return {
        ...ciudad,
        cui_codigo: row.CIU_CODIGO,
        ciu_latitud: row.CIU_LATITUD,
        ciu_longitud: row.CIU_LONGITUD,
        ciu_latitud_point: row.CIU_LATITUD_POINT,
        ciu_longitud_point: row.CIU_LONGITUD_POINT,
        ciu_zoom: row.CIU_ZOOM,
        ciu_estado: row.CIU_ESTADO_HYUNDAI,
      };
    });
    setshowModal(true);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editpermisos(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.CIU_ESTADO_HYUNDAI)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const setDataOpcionesModal = () => {
    setshowModal(false);
    updateEstadoOp();
  };

  const changeEstado = (row, index) => {
    var newEs = 0;

    if (row.CIU_ESTADO_HYUNDAI === 1) {
      newEs = 0;
    } else if (row.CIU_ESTADO_HYUNDAI === 0) {
      newEs = 1;
    }

    updateEstadoCiudad(row, newEs);
  };

  const updateEstadoOp = () => {
    console.log(ciudad);
    const dataForm = new FormData();
    dataForm.append("ciu_codigo", ciudad.cui_codigo);
    dataForm.append("ciu_latitud", ciudad.ciu_latitud);
    dataForm.append("ciu_longitud", ciudad.ciu_longitud);
    dataForm.append("ciu_latitud_point", ciudad.ciu_latitud_point);
    dataForm.append("ciu_longitud_point", ciudad.ciu_longitud_point);
    dataForm.append("ciu_zoom", ciudad.ciu_zoom);
    dataForm.append("ciu_estado", ciudad.ciu_estado);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(Server+"/api/empresas/UpdateCiudad", dataForm)

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          getData();
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const updateEstadoCiudad = (row, estado) => {
    console.log(row);
    const dataForm = new FormData();
    dataForm.append("ciu_codigo", row.CIU_CODIGO);
    dataForm.append("ciu_estado", estado);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(
        Server+"/api/empresas/UpdateCiudadEstado",
        dataForm
      )

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          getData();
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(Server+"/api/empresas/getLisCiudades")
      .then(function (response) {
        // setDataOpciones(response);
        setdataTable(response.data.data);
        setfirstTime(false);

        setIsLoading("invisible");
      })
      .catch(function (error) {
        setfirstTime(false);
      })
      .then(function () {
        // always executed
        setfirstTime(false);
      });
  };
  useEffect(() => {
    // Make a request for a user with a given ID
    if (firstTime) {
      getData();
    }
  });

  // const createOpciones = async () => {
  //   seterror((error) => {
  //     return { ...error, nombre: "", ruta: "" };
  //   });

  //   if (op_nombre !== "" && op_url !== "") {
  //     const dataForm = new FormData();
  //     dataForm.append("op_nombre", op_nombre);
  //     dataForm.append("op_url", op_url);
  //     dataForm.append("usuario", data.US_CODIGO);
  //     await axios
  //       .post(Server+"/api/opciones/create", dataForm)

  //       .then(function (response) {
  //         if (response.data.success === true) {
  //           showMessage(response.data.message, "success");
  //           setOp_url("");
  //           setOp_nombre("");
  //           getData();
  //           // updateItems()
  //         } else {
  //           showMessage(response.data.message, "error");
  //         }
  //       })
  //       .catch(function (error) {});
  //   } else {
  //     if (op_nombre === "") {
  //       seterror((error) => {
  //         return { ...error, nombre: "Campo requerido" };
  //       });
  //     }

  //     if (op_url === "") {
  //       seterror((error) => {
  //         return { ...error, ruta: "Campo requerido" };
  //       });
  //     }
  //     showMessage("Campos incompletos", "error");
  //   }
  // };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Editar Ciudad
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Latitud Mapa:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Ingrese Latitud Mapa"
                            id="ciu_latitud"
                            value={ciudad.ciu_latitud}
                            onChange={(e) => {
                              const val = e.target.value;
                              setciudad((ciudad) => {
                                return { ...ciudad, ciu_latitud: val };
                              });
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Longitud Mapa:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Ingrese Longitud Mapa"
                            id="ciu_longitud"
                            value={ciudad.ciu_longitud}
                            onChange={(e) => {
                              const val = e.target.value;
                              setciudad((ciudad) => {
                                return { ...ciudad, ciu_longitud: val };
                              });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Latitud Punto Fijo:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Latitud Punto Fijo"
                            id="ciu_latitud_point"
                            value={ciudad.ciu_latitud_point}
                            onChange={(e) => {
                              const val = e.target.value;
                              setciudad((ciudad) => {
                                return { ...ciudad, ciu_latitud_point: val };
                              });
                            }}
                          />
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Longitud Punto Fijo:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Ingrese Longitud Punto Fijo"
                            id="ciu_longitud_point"
                            value={ciudad.ciu_longitud_point}
                            onChange={(e) => {
                              const val = e.target.value;
                              setciudad((ciudad) => {
                                return { ...ciudad, ciu_longitud_point: val };
                              });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Zoom Mapa:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={200}
                            placeholder="Zoom Mapa"
                            id="ciu_zoom"
                            value={ciudad.ciu_zoom}
                            onChange={(e) => {
                              const val = e.target.value;
                              setciudad((ciudad) => {
                                return { ...ciudad, ciu_zoom: val };
                              });
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => setDataOpcionesModal()}>
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);
                        // setOp_nombre("");
                        // setOp_url("");
                        setciudad((ciudad) => {
                          return {
                            ...ciudad,
                            cui_codigo: 0,
                            ciu_latitud: 0,
                            ciu_longitud: 0,
                            ciu_latitud_point: 0,
                            ciu_longitud_point: 0,
                            ciu_zoom: 0,
                            ciu_estado: 0,
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div>
                {/* <h3 className="text-center">Gestión de "OPCIONES"</h3>
                <Card>
                  <Card.Header as="h5">
                    Creación de "opcion" para menús
                  </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre de opción"
                            value={op_nombre}
                            onChange={(event) =>
                              setOp_nombre(event.target.value)
                            }
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Ruta:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Ruta de opción"
                            value={op_url}
                            onChange={(event) => setOp_url(event.target.value)}
                          />
                          <span style={{ color: "red" }}>{error.ruta}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createOpciones()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br> */}

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Ciudades"
                  </Card.Header>
                  <Card.Body>
                    <ToolkitProvider
                      keyField="CIU_CODIGO"
                      data={dataTable}
                      columns={columns}
                      search
                    >
                      {(props) => (
                        <div>
                          <Card>
                            <Card.Body>
                              <Container>
                                <Row md="12" lg="12">
                                  <Col className="align-middle" md="auto">
                                    Burcar:
                                  </Col>
                                  <Col md="auto">
                                    <SearchBar
                                      className="align-middle"
                                      placeholder="Buscar..."
                                      {...props.searchProps}
                                    />
                                  </Col>
                                </Row>
                              </Container>
                            </Card.Body>
                          </Card>
                          <Card className="mt-1">
                            <Card.Body>
                              <BootstrapTable
                                class="align-middle"
                                filter={filterFactory()}
                                pagination={paginationFactory()}
                                {...props.baseProps}
                              />
                            </Card.Body>
                          </Card>
                        </div>
                      )}
                    </ToolkitProvider>
                    {/* <BootstrapTable
                      keyField="CIU_CODIGO"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    /> */}

                    {/* <Table responsive>
                            <thead>
                                <tr>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>URL</th>
                                    <th>Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                {getRows}
                            </tbody>
                        </Table> */}
                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
