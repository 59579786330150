import React, { Fragment, useEffect, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Form,
  FormGroup,
  Button,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import BootstrapTable from "react-bootstrap-table-next";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import { Server } from "../../constants";

function Content() {
  const [rowsEmpresas, setRowsEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState();
  const [rowsAlmacenes, setRowsAlmacenes] = useState([]);
  const [almacen, setAlmacen] = useState();
  const [rowsmVersion, setrowsmVersion] = useState([]);
  const [rowsAnio, setrowsAnio] = useState([]);
  const [showversion, setshowversion] = useState(false);
  const [dataVersion, setdataVersion] = useState([]);
  const [indexVersion, setindexVersion] = useState();
  const [indexAnio, setindexAnio] = useState();
  const [dataAnio, setdataAnio] = useState([]);
  const [showanio, setshowanio] = useState(false);
  const [showvColor, setshowvColor] = useState(false);
  const [isLoading, setisLoading] = useState("invisible");
  const [isLoadingAgencias, setisLoadingAgencias] = useState("invisible");
  const [isLoadingVersion, setisLoadingVersion] = useState("invisible");
  const [isLoadingAnio, setisLoadingAnio] = useState("invisible");
  const [showModalVersion, setshowModalVersion] = useState(false);
  const [showModalVersionUpdate, setshowModalVersionUpdate] = useState(false);
  //const [idmodelo, setidmodelo] = useState();
  const [isLoadingCreateVer, setisLoadingCreateVer] = useState("invisible");
  const [showModalAnioCreate, setshowModalAnioCreate] = useState(false);
  const [showModalAnioUpdate, setshowModalAnioUpdate] = useState(false);
  const [showModalColorCreate, setshowModalColorCreate] = useState(false);
  const [showModalColorUpdate, setshowModalColorUpdate] = useState(false);
  const [rowsAllColor, setrowsAllColor] = useState([]);
  const [version, setversion] = useState([]);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [jefe, setJefe] = useState({
    je_cedula: "",
    je_id: "",
    je_nombre: "",
    je_apellido: "",
    je_correo: "",
    je_contra: "",
    je_usuario: "",
    je_estado: ""
  });

  const [errorJefe, setErrorJefe] = useState({
    je_cedula: "",
    je_id: "",
    je_nombre: "",
    je_apellido: "",
    je_correo: "",
    je_contra: "",
    je_usuario: "",
    je_estado: ""
  });

  const [anio, setanio] = useState({
    vea_anio: "",
    vea_precio: "",
    vea_bono: "",
    vea_precio_final: "",
    vea_estado: 0,
    ver_defecto: 0,
  });

  const [erroanio, seterroanio] = useState({
    anio: "",
    precio: "",
    bono: "",
    precio_final: "",
  });

  const [color, setcolor] = useState({
    vac_estado: 0,
    vac_defecto: 0,
    vac_color: 0,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const [dataTable, setdataTable] = useState([]);

  const columns = [
    {
      dataField: "VAC_COLOR",
      text: "Codigo",
      sort: true,
    },

    {
      dataField: "COL_NOMBRE",
      text: "Nombre",
      sort: true,
    },

    {
      dataField: "COL_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editcolor(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editcolor = (row, index) => {
    setcolor((color) => {
      return {
        ...color,
        vac_estado: row.VAC_ESTADO,
        vac_defecto: row.VAC_DEFECTO,
        vac_color: row.VAC_COLOR,
      };
    });
    setshowModalColorUpdate(true);
  };

  const createJefe = async () => {
    console.log(jefe);
    setErrorJefe((errorJefe) => {
      return {
        ...errorJefe,
        je_cedula: "",
        je_id: "",
        je_nombre: "",
        je_apellido: "",
        je_correo: "",
        je_contra: "",
        je_usuario: "",
        je_estado: ""
      };
    });

    if (
      jefe.je_cedula !== "" &&
      jefe.je_nombre !== "" &&
      jefe.je_apellido !== "" &&
      jefe.je_correo !== "" &&
      jefe.je_contra !== "" &&
      jefe.je_usuario !== "" &&
      jefe.je_estado !== ""
    ) {
      setisLoadingCreateVer("visible");

      const dataForm = new FormData();
      dataForm.append("je_identidad", jefe.je_cedula);
      dataForm.append("je_nombre", jefe.je_nombre);
      dataForm.append("je_apellido", jefe.je_apellido);
      dataForm.append("je_correo", jefe.je_correo);
      dataForm.append("je_contra", jefe.je_contra);
      dataForm.append("je_usuario", jefe.je_usuario);
      dataForm.append("je_estado", jefe.je_estado);
      dataForm.append("je_empresa", empresa);
      dataForm.append("je_almacen", almacen);
      dataForm.append("usuario", data.US_CODIGO);
      await axios
        .post(
          Server + "/api/talentohumano/createJefe",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalVersion(false);
            showMessage(response.data.message, "success");
            //getJefes(idmodelo);
            setversion((version) => {
              return {
                ...version,
                ver_codigo: "",
                ver_id: "",
                ver_nombre: "",
                ver_descripcion: "",
                ver_nombre_visible: "",
                ver_estado: 0,
                ver_motor: "",
                ver_consumo: "",
                ver_tranmision: "",
                ver_url_landing: "",
                ver_url_cotizador: "",
                ver_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (jefe.je_cedula === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_cedula: "Campo requerido" };
        });
      }
      if (jefe.je_nombre === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_nombre: "Campo requerido" };
        });
      }

      if (version.je_apellido === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_apellido: "Campo requerido" };
        });
      }
      if (version.je_correo === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_correo: "Campo requerido" };
        });
      }
      if (version.je_contra === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_contra: "Campo requerido" };
        });
      }
      if (version.je_usuario === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_usuario: "Campo requerido" };
        });
      }
      if (version.je_estado === "") {
        setErrorJefe((errorJefe) => {
          return { ...errorJefe, je_estado: "Campo requerido" };
        });
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const createAnio = async () => {
    seterroanio((erroanio) => {
      return { ...erroanio, anio: "", precio: "" };
    });

    if (anio.vea_anio !== "" && anio.vea_precio !== "") {
      const dataForm = new FormData();
      dataForm.append("vea_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vea_anio", anio.vea_anio);
      dataForm.append("vea_precio", anio.vea_precio);
      dataForm.append("vea_bono", anio.vea_bono);
      dataForm.append("vea_precio_final", anio.vea_precio_final);
      dataForm.append("vea_defecto", anio.vea_defecto);
      dataForm.append("usuario", data.US_CODIGO);

      setisLoadingCreateVer("visible");
      await axios
        .post(Server + "/api/vehiculos/creaAnio", dataForm)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalAnioCreate(false);
            getAnisoCar(indexVersion);
            showMessage(response.data.message, "success");

            // getVersiones(idmodelo)

            setversion((anio) => {
              return {
                ...anio,
                vea_anio: "",
                vea_precio: "",
                vea_bono: "",
                vea_precio_final: "",
                vea_estado: 0,
                vea_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (anio.vea_anio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, anio: "Campo requerido" };
        });
      }

      if (anio.vea_precio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      if (anio.vea_bono === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      if (anio.vea_precio_final === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo requerido" };
        });
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const updateAnio = async () => {
    seterroanio((erroanio) => {
      return { ...erroanio, anio: "", precio: "", bono: "", precio_final: "" };
    });

    if (anio.vea_anio !== "" && anio.vea_precio !== "") {
      const dataForm = new FormData();
      dataForm.append("vea_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vea_anio", anio.vea_anio);
      dataForm.append("vea_precio", anio.vea_precio);
      dataForm.append("vea_bono", anio.vea_bono);
      dataForm.append("vea_precio_final", anio.vea_precio_final);
      dataForm.append("vea_defecto", anio.vea_defecto);
      dataForm.append("vea_estado", String(anio.vea_estado));
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      setisLoadingCreateVer("visible");
      await axios
        .post(
          Server + "/api/vehiculos/updateAnio",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalAnioUpdate(false);
            showMessage(response.data.message, "success");
            getAnisoCar(indexVersion);
            setversion((anio) => {
              return {
                ...anio,
                vea_anio: "",
                vea_precio: "",
                vea_bono: "",
                vea_precio_final: "",
                vea_estado: 0,
                vea_defecto: 0,
              };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (anio.vea_precio === "") {
        seterroanio((erroanio) => {
          return { ...erroanio, precio: "Campo Requerido" };
        });
      } else {
        if (anio.vea_bono === "") {
          seterroanio((erroanio) => {
            return { ...erroanio, bono: "Campo Requerido" };
          });
        } else {
          if (anio.vea_precio_final === "") {
            seterroanio((erroanio) => {
              return { ...erroanio, precio_final: "Campo Requerido" };
            });
          }
        }
      }
      showMessage("Error campos incompletos", "error");
    }
  };

  const createColor = async () => {
    if (color.vac_color >= 0) {
      const dataForm = new FormData();
      dataForm.append("vac_defecto", color.vac_defecto);
      dataForm.append("vac_version", dataVersion[indexVersion].VER_CODIGO);
      dataForm.append("vac_color", color.vac_color);
      dataForm.append("vac_anio", dataAnio[indexAnio].VEA_ANIO);
      dataForm.append("usuario", data.US_CODIGO);

      setisLoadingCreateVer("visible");
      await axios
        .post(
          Server + "/api/vehiculos/crearColorAuto",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreateVer("invisible");
            setshowModalColorCreate(false);

            getAnisoCarColor(indexAnio);
            showMessage(response.data.message, "success");

            setcolor((color) => {
              return { ...color, vac_estado: 0, vac_defecto: 0, vac_color: 0 };
            });
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreateVer("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      showMessage("Seleccione un Color", "error");
    }
  };

  const updateColor = async () => {
    setisLoadingCreateVer("visible");

    const dataForm = new FormData();
    dataForm.append("vac_defecto", color.vac_defecto);
    dataForm.append("vac_version", dataVersion[indexVersion].VER_CODIGO);
    dataForm.append("vac_color", color.vac_color);
    dataForm.append("vac_anio", dataAnio[indexAnio].VEA_ANIO);
    dataForm.append("vac_estado", color.vac_estado);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    await axios
      .post(
        Server + "/api/vehiculos/updateColorAuto",
        dataForm
      )

      .then(function (response) {
        if (response.data.success) {
          setisLoadingCreateVer("invisible");
          setshowModalColorUpdate(false);
          getAnisoCarColor(indexAnio);
          showMessage(response.data.message, "success");

          setcolor((color) => {
            return { ...color, vac_estado: 0, vac_defecto: 0, vac_color: 0 };
          });
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        setisLoadingCreateVer("invisible");
        showMessage("Error en el Intentalo de nuevo", "error");
      });
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server + "/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) { })
        .then(function () { });
    };
    getDataEmpresas();
  }, []);

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const setDataVersiones = (data) => {
    setshowversion(false);
    setshowanio(false);
    const rows = data.map((row, index) => (
      <option key={row.je_id} value={index}>
        {row.je_nombre} {row.je_apellido}
      </option>
    ));

    setrowsmVersion(rows);
    setdataVersion(data);
    setshowversion(true);
  };

  const setDataAnio = (data) => {
    const rows = data.map((row, index) => (
      <option key={index} value={index}>
        {row.VEA_ANIO}
      </option>
    ));

    setrowsAnio(rows);
    setdataAnio(data);
    setshowanio(true);
  };

  const setDataAllColor = (data) => {
    const rows = data.map((row, index) => (
      <option key={index} value={row.COL_CODIGO}>
        {row.COL_NOMBRE}
      </option>
    ));

    setrowsAllColor(rows);
  };

  const getJefes = async (agencia) => {
    setshowversion(false);
    setshowanio(false);
    setshowvColor(false);
    setisLoading("visible");
    setAlmacen(agencia);

    await axios
      .get(
        Server + "/api/talentohumano/getJefes/" +
        empresa +"/"+agencia
      )
      .then(function (response) {
        setDataVersiones(response.data.data);
        setisLoading("invisible");
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const getAnisoCar = async (index) => {
    setindexVersion(index);

    setshowanio(false);
    setshowvColor(false);

    if (index >= 0) {
      setisLoadingVersion("visible");

      await axios
        .get(
          Server + "/api/vehiculos/getVersionForAnios/" +
          dataVersion[index].VER_CODIGO
        )
        .then(function (response) {
          setDataAnio(response.data.data);
          setisLoadingVersion("invisible");
        })
        .catch(function (error) { })
        .then(function () { });
    }
  };

  const getAllColor = async () => {
    await axios
      .get(Server + "/api/vehiculos/getAllColor")
      .then(function (response) {
        setDataAllColor(response.data.data);
      })
      .catch(function (error) { })
      .then(function () { });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const getAlmacenes = async (idEmpresa) => {
    setEmpresa(idEmpresa);
    setisLoadingAgencias("visible");
    if (idEmpresa !== "0") {
      await axios
        .get(
          Server + "/api/empresas/getListAlmacenesActivos/" +
          idEmpresa
        )
        .then(function (response) {
          setAlmacenRows(response.data.data);
          setisLoadingAgencias("invisible");
        })
        .catch(function (error) { })
        .then(function () { });
    }
  };

  const setAlmacenRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.ALM_CODIGO} value={row.ALM_CODIGO}>
        {row.ALM_NOMBRE}
      </option>
    ));
    setRowsAlmacenes(rows);
  };

  const getAnisoCarColor = async (index) => {
    setindexAnio(index);
    setshowvColor(false);

    if (index >= 0) {
      setisLoadingAnio("visible");

      await axios
        .get(
          Server + "/api/vehiculos/getAllColorModelo/" +
          dataVersion[indexVersion].VER_CODIGO +
          "/" +
          dataAnio[index].VEA_ANIO
        )
        .then(function (response) {
          setdataTable(response.data.data);
          setshowvColor(true);
          getAllColor();
          setisLoadingAnio("invisible");
        })
        .catch(function (error) { })
        .then(function () { });
    }
  };

  const drawSelectAnio = () => {
    if (showanio) {
      return (
        <div style={{ marginTop: 15 }}>
          <Card>
            <Card.Body>
              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <h3 className="text-center">Selecciona un Año</h3>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      setshowModalAnioCreate(true);
                      setanio((anio) => {
                        return {
                          ...anio,
                          vea_anio: "",
                          vea_precio: "",
                          vea_bono: "",
                          vea_precio_final: "",
                          vea_estado: 0,
                          vea_defecto: 0,
                        };
                      });
                    }}
                    variant="primary"
                  >
                    Agregar Nuevo Año
                  </Button>
                </Col>
              </FormGroup>

              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      getAnisoCarColor(e.target.value);
                    }}
                  >
                    <option value="-1">N/A</option>
                    {rowsAnio}
                  </Form.Control>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      if (indexAnio >= 0) {
                        setshowModalAnioUpdate(true);
                        setanio((anio) => {
                          return {
                            ...anio,
                            vea_precio: dataAnio[indexAnio].VEA_PRECIO_PVP,
                            vea_bono: dataAnio[indexAnio].VEA_BONO,
                            vea_precio_final:
                              dataAnio[indexAnio].VEA_PRECIO_FINAL,
                            vea_anio: dataAnio[indexAnio].VEA_ANIO,
                            vea_defecto: dataAnio[indexAnio].VEA_DEFECTO,
                            vea_estado: Number(dataAnio[indexAnio].VEA_ESTADO),
                          };
                        });
                      } else {
                        showMessage(
                          "Seleccione un Año para actualizar",
                          "error"
                        );
                      }
                    }}
                    variant="primary"
                  >
                    Actualizar Precios
                  </Button>
                </Col>
              </FormGroup>

              <div className="text-center">
                <div className={isLoadingAnio} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const drawSelectVersion = () => {
    if (showversion) {
      return (
        <div style={{ marginTop: 25 }}>
          <Card>
            <Card.Body>
              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <h3 className="text-center">Selecciona un jefe de agencia</h3>
                </Col>
                <Col align="center">
                  <Button
                    onClick={() => {
                      setshowModalVersion(true);
                      setJefe((jefe) => {
                        return {
                          ...jefe,
                          je_cedula: "",
                          je_id: "",
                          je_nombre: "",
                          je_apellido: "",
                          je_correo: "",
                          je_contra: "",
                          je_usuario: "",
                          je_estado: "",
                        };
                      });
                    }}
                    variant="primary"
                  >
                    Agregar Jefe de Agencia
                  </Button>
                </Col>
              </FormGroup>
              <FormGroup style={{ marginTop: 15 }} as={Row}>
                <Col align="center">
                  <Form.Control
                    as="select"
                    onChange={(e) => {
                      getAnisoCar(e.target.value);
                    }}
                  >
                    <option value="-1">N/A</option>
                    {rowsmVersion}
                  </Form.Control>
                </Col>

                <Col align="center">
                  <Button
                    onClick={() => {
                      if (indexVersion >= 0) {
                        setshowModalVersionUpdate(true);
                        setversion((version) => {
                          return {
                            ...version,
                            ver_codigo: dataVersion[indexVersion].VER_CODIGO,
                            ver_id: dataVersion[indexVersion].VER_ID,
                            ver_nombre: dataVersion[indexVersion].VER_NOMBRE,
                            ver_descripcion:
                              dataVersion[indexVersion].VER_DESCRIPCION,
                            ver_nombre_visible:
                              dataVersion[indexVersion].VER_NOMBRE_VISIBLE,

                            ver_estado:
                              dataVersion[indexVersion].VER_ACTIVO_SHOWROOM !==
                                null
                                ? dataVersion[indexVersion].VER_ACTIVO_SHOWROOM
                                : 0,
                            ver_motor: dataVersion[indexVersion].VER_MOTOR,
                            ver_defecto:
                              dataVersion[indexVersion].VER_VERSION_DEFECTO,
                            ver_consumo:
                              dataVersion[indexVersion].VER_CONSUMO !== null
                                ? dataVersion[indexVersion].VER_CONSUMO
                                : "",
                            // request->alm_telefono_contacto !== null ? $request->alm_telefono_contacto : ''
                            // ver_consumo: dataVersion[indexVersion].VER_CONSUMO,
                            ver_tranmision:
                              dataVersion[indexVersion].VER_TRANSMISION !== null
                                ? dataVersion[indexVersion].VER_TRANSMISION
                                : "",

                            ver_url_landing:
                              dataVersion[indexVersion].VER_URL_LANDING !== null
                                ? dataVersion[indexVersion].VER_URL_LANDING
                                : "",

                            ver_url_cotizador:
                              dataVersion[indexVersion].VER_URL_COTIZADOR !==
                                null
                                ? dataVersion[indexVersion].VER_URL_COTIZADOR
                                : "",
                          };
                        });
                      } else {
                        showMessage(
                          "Seleccione una versión para actualizar",
                          "error"
                        );
                      }
                    }}
                    variant="primary"
                  >
                    Actualizar Jefe de Agencia
                  </Button>
                </Col>
              </FormGroup>

              <div className="text-center">
                <div className={isLoadingVersion} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const drawTableColor = () => {
    if (showvColor) {
      return (
        <div style={{ marginTop: 15 }}>
          <Card>
            <Card.Body>
              <h3 className="text-center">Lista de Color Por Version - Año</h3>

              <Button
                onClick={() => {
                  setshowModalColorCreate(true);
                  setcolor((color) => {
                    return {
                      ...color,
                      vac_estado: 0,
                      vac_defecto: 0,
                      vac_color: -1,
                    };
                  });
                }}
                variant="primary"
              >
                Agregar un Nuevo Color{" "}
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="VAC_COLOR"
                  data={dataTable}
                  columns={columns}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Jefes/Asesores"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <div>
            <Card>
              <Card.Body>
                <h3 className="text-center">Selecciona una Empresa</h3>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getAlmacenes(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {rowsEmpresas}
                </Form.Control>
                <div className="text-center">
                  <div className={isLoadingAgencias} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div>
            <Card>
              <Card.Body>
                <h3 className="text-center">Selecciona una Agencia</h3>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getJefes(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {rowsAlmacenes}
                </Form.Control>
                <div className="text-center">
                  <div className={isLoading} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          <div>{drawSelectVersion()}</div>
          <div>{drawSelectAnio()}</div>

          <div>{drawTableColor()}</div>

          <div>
            <Modal
              show={showModalVersion}
              // onHide={!showModalVersion}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Nuevo Jefe de Agencia
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Cédula: </Form.Label>
                      <Form.Control
                        maxLength={13}
                        placeholder="Ingrese Cédula"
                        id="je_cedula"
                        //value={jefe.je_cedula}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_cedula: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errorJefe.je_cedula}
                      </span>
                    </Col>
                    <Col>
                      <Form.Label className="title-create">Correo: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese correo"
                        id="je_correo"
                        //value={jefe.je_correo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_correo: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>{errorJefe.je_correo}</span>
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Nombre: </Form.Label>
                      <Form.Control
                        maxLength={45}
                        placeholder="Ingrese Nombre"
                        id="je_nombre"
                        //value={jefe.je_nombre}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_nombre: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errorJefe.je_nombre}
                      </span>
                    </Col>
                    <Col>
                      <Form.Label className="title-create">
                        Apellido:
                      </Form.Label>
                      <Form.Control
                        maxLength={45}
                        placeholder="Ingrese Apellido"
                        id="je_apellido"
                        //value={jefe.je_apellido}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_apellido: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errorJefe.je_apellido}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Usuario: </Form.Label>
                      <Form.Control
                        maxLength={12}
                        placeholder="Ingrese Usuario"
                        id="je_usuario"
                        //value={jefe.je_usuario}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_usuario: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errorJefe.je_usuario}
                      </span>
                    </Col>
                    <Col>
                      <Form.Label className="title-create">
                        Contraseña:
                      </Form.Label>
                      <Form.Control
                        maxLength={12}
                        placeholder="Ingrese Contraseña"
                        id="je_contra"
                        //value={jefe.je_contra}
                        onChange={(e) => {
                          const val = e.target.value;
                          setJefe((jefe) => {
                            return { ...jefe, je_contra: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>
                        {errorJefe.je_contra}
                      </span>
                    </Col>
                  </FormGroup>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Activo:"
                        //checked={setJefe(jefe.je_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (jefe.je_estado === 0) {
                            newStatus = 1;
                          } else if (jefe.je_estado === 1) {
                            newStatus = 0;
                          }
                          setJefe((jefe) => {
                            return { ...jefe, je_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createJefe();
                  }}
                >
                  Guardar
                </Button>
                <Button
                  onClick={() => {
                    setshowModalVersion(false);

                    setErrorJefe((errorJefe) => {
                      return {
                        ...errorJefe,
                        codigo: "",
                        id: "",
                        nombre: "",
                        descripcion: "",
                        nombrevisible: "",
                        moto: "",
                        consumo: "",
                        transmision: "",
                        url_lading: "",
                        url_cotizador: "",
                      };
                    });

                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalVersionUpdate}
              // onHide={!showModalVersionUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualiza Version
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Nombre: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre"
                        id="ver_nombre"
                        value={version.ver_nombre}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.nombre}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Descripción:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Descripción"
                        id="ver_descripcion"
                        value={version.ver_descripcion}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_descripcion: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.descripcion}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Nombre Visible:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Nombre Visible"
                        id="ver_nombre_visible"
                        value={version.ver_nombre_visible}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_nombre_visible: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.nombrevisible}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Motor: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Motor"
                        id="ver_motor"
                        value={version.ver_motor}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_motor: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorJefe.moto}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Ver Consumo:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Consumo"
                        id="ver_consumo"
                        value={version.ver_consumo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_consumo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.consumo}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Ver Transmisión:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Transmisión"
                        id="ver_transmision"
                        value={version.ver_tranmision}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_tranmision: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.transmision}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Url Landing:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Landing"
                        id="url_landing"
                        value={version.ver_url_landing}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_landing: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.url_lading}
                      </span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Url Cotizador:
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Url Cotizador"
                        id="url_cotizador"
                        value={version.ver_url_cotizador}
                        onChange={(e) => {
                          const val = e.target.value;
                          setversion((version) => {
                            return { ...version, ver_url_cotizador: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorJefe.url_cotizador}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto:"
                        checked={setEstado(version.ver_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (version.ver_defecto === 0) {
                            newStatus = 1;
                          } else if (version.ver_defecto === 1) {
                            newStatus = 0;
                          }
                          setversion((version) => {
                            return { ...version, ver_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Estado"
                        checked={setEstado(version.ver_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (version.ver_estado === 0) {
                            newStatus = 1;
                          } else if (version.ver_estado === 1) {
                            newStatus = 0;
                          }
                          setversion((version) => {
                            return { ...version, ver_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                {/*
                <Button
                  onClick={() => {
                    updateVersion();
                  }}
                >
                  Actualizar
                </Button>
                */}
                <Button
                  onClick={() => {
                    setshowModalVersionUpdate(false);

                    setErrorJefe((errorJefe) => {
                      return {
                        ...errorJefe,
                        codigo: "",
                        id: "",
                        nombre: "",
                        descripcion: "",
                        nombrevisible: "",
                        moto: "",
                        consumo: "",
                        transmision: "",
                        url_lading: "",
                        url_cotizador: "",
                      };
                    });
                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalAnioCreate}
              // onHide={!showModalAnioCreate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Crear Año Nuevo
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Año: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Año"
                        id="ver_anio_anio"
                        value={anio.vea_anio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_anio: val };
                          });
                        }}
                      />
                      <span style={{ color: "red" }}>{erroanio.anio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Precio PVP:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio PVP"
                        id="ver_anio_precio"
                        value={anio.vea_precio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Bono: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Bono"
                        id="ver_anio_bono"
                        value={anio.vea_bono}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_bono: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>

                    <Col>
                      <Form.Label className="title-create">
                        Precio Final:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio Final"
                        id="ver_anio_precio_final"
                        value={anio.vea_precio_final}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio_final: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto:"
                        checked={setEstado(anio.vea_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_defecto === 0) {
                            newStatus = 1;
                          } else if (anio.vea_defecto === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createAnio();
                  }}
                >
                  Crear
                </Button>
                <Button
                  onClick={() => {
                    setshowModalAnioCreate(false);

                    seterroanio((erroanio) => {
                      return { ...erroanio, anio: "", precio: "" };
                    });
                    setversion((version) => {
                      return {
                        ...version,
                        ver_codigo: "",
                        ver_id: "",
                        ver_nombre: "",
                        ver_descripcion: "",
                        ver_nombre_visible: "",
                        ver_estado: 0,
                        ver_motor: "",
                        ver_consumo: "",
                        ver_tranmision: "",
                        ver_url_landing: "",
                        ver_url_cotizador: "",
                        ver_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalAnioUpdate}
              // onHide={!showModalAnioUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualizar Precios
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Precio PVP:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio PVP"
                        id="ver_anio_precio"
                        value={anio.vea_precio}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.precio}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Bono: </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Bono"
                        id="ver_anio_bono"
                        value={anio.vea_bono}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_bono: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{erroanio.bono}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Precio Final:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={255}
                        placeholder="Ingrese Precio Final"
                        id="ver_anio_precio_final"
                        value={anio.vea_precio_final}
                        onChange={(e) => {
                          const val = e.target.value;
                          setanio((anio) => {
                            return { ...anio, vea_precio_final: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {erroanio.precio_final}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Versión por Defecto"
                        checked={setEstado(anio.vea_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_defecto === 0) {
                            newStatus = 1;
                          } else if (anio.vea_defecto === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Estado"
                        checked={setEstado(anio.vea_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (anio.vea_estado === 0) {
                            newStatus = 1;
                          } else if (anio.vea_estado === 1) {
                            newStatus = 0;
                          }
                          setanio((anio) => {
                            return { ...anio, vea_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    updateAnio();
                  }}
                >
                  Actualizar
                </Button>
                <Button
                  onClick={() => {
                    setshowModalAnioUpdate(false);

                    seterroanio((erroanio) => {
                      return { ...erroanio, anio: "", precio: "" };
                    });

                    setanio((anio) => {
                      return {
                        ...anio,
                        vea_anio: "",
                        vea_precio: "",
                        vea_bono: "",
                        vea_precio_final: "",
                        vea_estado: 0,
                        vea_defecto: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalColorCreate}
              // onHide={!showModalColorCreate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Crear un Nuevo Color
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Control
                        as="select"
                        onChange={(e) => {
                          const val = e.target.value;
                          setcolor((color) => {
                            return { ...color, vac_color: val };
                          });
                        }}
                      >
                        <option value="-1">N/A</option>
                        {rowsAllColor}
                      </Form.Control>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color por Defecto"
                        checked={setEstado(color.vac_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_defecto === 0) {
                            newStatus = 1;
                          } else if (color.vac_defecto === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    createColor();
                  }}
                >
                  Crear Color
                </Button>
                <Button
                  onClick={() => {
                    setshowModalColorCreate(false);
                    setcolor((color) => {
                      return {
                        ...color,
                        vac_estado: 0,
                        vac_defecto: 0,
                        vac_color: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>

          <div>
            <Modal
              show={showModalColorUpdate}
              // onHide={!showModalColorUpdate}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Actualizar Color
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color por Defecto"
                        checked={setEstado(color.vac_defecto)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_defecto === 0) {
                            newStatus = 1;
                          } else if (color.vac_defecto === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_defecto: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Color Activo"
                        checked={setEstado(color.vac_estado)}
                        onChange={() => {
                          var newStatus = 0;
                          if (color.vac_estado === 0) {
                            newStatus = 1;
                          } else if (color.vac_estado === 1) {
                            newStatus = 0;
                          }
                          setcolor((color) => {
                            return { ...color, vac_estado: newStatus };
                          });
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </Modal.Body>

              <div className="text-center">
                <div className={isLoadingCreateVer} style={{ marginTop: 10 }}>
                  <Spinner
                    animation="border"
                    role="status"
                    variant="secondary"
                  ></Spinner>
                </div>
              </div>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    updateColor();
                  }}
                >
                  Actualizar Color
                </Button>
                <Button
                  onClick={() => {
                    setshowModalColorUpdate(false);
                    setcolor((color) => {
                      return {
                        ...color,
                        vac_estado: 0,
                        vac_defecto: 0,
                        vac_color: 0,
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
