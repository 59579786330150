import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Form,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as xlsx from "xlsx/xlsx.mjs";
import Swal from "sweetalert2";
import { Server } from "../../constants";
import "./cargaventas.css";

require("react-quill/dist/quill.snow.css");

function Content() {

  const [isLoadingCreate, setIsLoadingCreate] = useState("invisible");

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const readUploadFileBoletines = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        insertBoletin(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const insertBoletin = (datos) => {
    datos.forEach(async (element) => {
      let data2 = new FormData();
      data2.append("anio", element["anio"]);
      data2.append("boletin", element["boletin"]);
      data2.append("modelo", element["modelo"]);
      data2.append("version", element["version"]);
      data2.append("pvp", element["pvp"]);
      data2.append("mes", element["mes"]);
      data2.append("valor", element["valor"]);
      data2.append("mantenimiento", element["mantenimiento"]);
      data2.append("kilometraje", element["kilometraje"]);
      data2.append("matricula", element["matricula"]);
      await axios
        .post(Server + "/api/boletines/createBoletin", data2)
        .then(function (response) {
          if (response.data.success) {
            setIsLoadingCreate("invisible");
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setIsLoadingCreate("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    });
  };

  const readUploadFacturas = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setIsLoadingCreate("visible");
        insertFactura(json);
        setIsLoadingCreate("invisible");
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const insertFactura = (datos) => {
    setIsLoadingCreate("visible");
    datos.forEach(async (element) => {
      let data2 = new FormData();
      data2.append("ve_chasis", element["chasis_vh01"]);
      data2.append("ve_modelo", element["DescripcionModeloComercialFSC"]);
      data2.append("ve_fecha_wholesale", element["fecha_wholesale_formato"]);
      data2.append("wholesale", element["Numero Factura Wholesales"]);
      data2.append("fecha_retail", element["fecha_retail_formato"]);
      data2.append("retail", element["Numero Factura Retail"]);
      data2.append("dni_propietario", element["DocumentoPropietarioRetail"]);
      data2.append("nombre_propietario", element["NomprePropietarioRetail"]);
      data2.append(
        "direccion_propietario",
        element["DireccionPropietarioRetai"]
      );
      data2.append(
        "telefono_propietario",
        element["telefono_propietario_vh01"]
      );
      data2.append("mail_propietario", element["mail_1_propietario_vh01"]);
      data2.append("anio_modelo", element["Año Modelo"]);
      data2.append("modelo_agrupador", element["ModeloAgrupadorFSC"]);
      data2.append("placa", element["Placa"]);
      data2.append("precio", element["Precio Venta"]);
      data2.append("mantenimiento", element["Mantenimiento"]);
      data2.append("wholesale_origen", element["WholesaleOrigen"]);
      await axios
        .post(Server + "/api/facturas/createFactura", data2)
        .then(function (response) {
          if (response.data.success) {
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    });
    setIsLoadingCreate("invisible");
  };

  const marcaoEmpresa = () => {
    return (
      <div style={{ marginTop: 10 }}>
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Subir boletines y ventas</Card.Header>
            <Card.Body>
              <Form.Group controlId="formFile">
                <Form.Label>Boletines</Form.Label>
                <Form.Control
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFileBoletines}
                />
              </Form.Group>
              <Form.Group controlId="formFile">
                <Form.Label>Facturas</Form.Label>
                <Form.Control
                  type="file"
                  name="upload"
                  id="upload"
                  onChange={readUploadFacturas}
                />
              </Form.Group>
              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoadingCreate}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

      </div>
    );
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Bonos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {marcaoEmpresa()}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
