import React, { Fragment } from "react";
import cx from "classnames";
import Navbarmenu from "./Navbarmenu";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PerfectScrollbar from "react-perfect-scrollbar";
import Logo from "./Logo";

function AppSidebar() {
  /* const [enableMobileMenu, setEnableMobileMenu] = useState(null); */

  // const toggleMobileSidebar = () => {
  //     setEnableMobileMenu(true);
  // }

  return (
    <Fragment>
      {/* <div className="sidebar-mobile-overlay" onClick={toggleMobileSidebar}/> */}
      <ReactCSSTransitionGroup
        component="div"
        className={cx("app-sidebar", "bg-royal sidebar-text-light", {
          "sidebar-shadow": true,
        })}
        transitionName="SidebarAnimation"
        transitionAppear={true}
        transitionAppearTimeout={1500}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Logo />
        <PerfectScrollbar>
          <div className="app-sidebar__inner">
            <Navbarmenu />
          </div>
        </PerfectScrollbar>
        <div className={cx("app-sidebar-bg", "opacity-06")}></div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default AppSidebar;
