import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./css/login.css";
import { Modal, Button, Col, Row, Form, Spinner } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Swal from "sweetalert2";
import axios from "axios";
import Image from "react-bootstrap/Image";
import LogoHyundai from "../../images/assets/hyundai-logo.png";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Index() {
  const history = useHistory();
  const [usuario, setUsuario] = useState("");
  const [password, setPassword] = useState("");
  const [showModal, setshowModal] = useState(false);
  const [email, setemail] = useState();

  const [isLoading, setIsLoading] = useState("invisible");

  const handleLogin = async (e) => {
    e.preventDefault();
    let infoUsuario = [];
    const ls = new SecureLS({
      encodingType: "des",
      isCompression: false,
      encryptionSecret: "admin-hyundai",
    });

    setIsLoading("visible");
    await axios
      .get(
        Server+"/api/usuario/login/" +
          usuario +
          "/" +
          password
      )
      .then(function (response) {
        setIsLoading("invisible");

        if (response.data.success === true) {
          showMessage(response.data.message, "success");
          infoUsuario = response.data;
          ls.set("ask-hy", { infoUsuario });

          if (infoUsuario.data.US_PERMISOS[0].PER_CODIGO === 2) {
            history.push("/listadoempresa");
          } else {
            history.push("/dashboard");
          }
        } else {
          showMessage(response.data.message, "error");
        }

        //setDataCargo(response.data)
      })
      .catch(function (error) {
        setIsLoading("invisible");

        showMessage("Ocurrio un error intentalo de nuevo", "error");
      });

    // if (usuario === "admin@ad-a.com.ec" && password === "admin") {
    //   infoUsuario = {
    //     status: true,
    //     success: true,
    //     autenticado: true,
    //     data: {
    //       US_CODIGO: 8,
    //       US_CEDULA: "0987654321",
    //       US_NOMBRE: "Carlos",
    //       US_APELLIDO: "Fernandez",
    //       US_DIRECCION: "Uncovia",
    //       US_TELEFONO: "2900000",
    //       US_CELULAR: "0987654321",
    //       US_EMAIL: "demo@demo.com",
    //       US_CARGO: "Cargo 4",
    //       US_ROL: "Carga 2",
    //       US_AUTENTICADO: true,
    //       US_MODULO: [
    //         {
    //           MO_CODIGO: 1,
    //           MO_NOMBRE: "Principal",
    //           MO_DESCRIPCION: "ss",
    //           MO_MENU: [
    //             {
    //               MEN_CODIGO: 2,
    //               MEN_NOMBRE: "Home",
    //               MEN_DESCRIPCION: "Descripción deHome",
    //               MEN_ICONO: "icono 1",
    //               MEN_OPCIONES: [
    //                 {
    //                   OP_CODIGO: 11,
    //                   OP_NOMBRE: "nuevaOp4",
    //                   OP_URL: "/.op4",
    //                 },
    //                 {
    //                   OP_CODIGO: 7,
    //                   OP_NOMBRE: "opcion",
    //                   OP_URL: "/url/opcion",
    //                 },
    //                 {
    //                   OP_CODIGO: 15,
    //                   OP_NOMBRE: "op8",
    //                   OP_URL: "/op8",
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //         {
    //           MO_CODIGO: 2,
    //           MO_NOMBRE: "Vehículo",
    //           MO_DESCRIPCION: "Todo en Vehículo",
    //           MO_MENU: [
    //             {
    //               MEN_CODIGO: 2,
    //               MEN_NOMBRE: "Home",
    //               MEN_DESCRIPCION: "Descripción deHome",
    //               MEN_ICONO: "icono 1",
    //               MEN_OPCIONES: [
    //                 {
    //                   MEN_CODIGO: 4,
    //                   MEN_NOMBRE: "Catalogo",
    //                   MEN_DESCRIPCION: "Menu 3",
    //                   MEN_ICONO: "icono 3",
    //                   MEN_OPCIONES: [
    //                     {
    //                       OP_CODIGO: 14,
    //                       OP_NOMBRE: "op7",
    //                       OP_URL: "/op7",
    //                     },
    //                   ],
    //                 },
    //               ],
    //             },
    //           ],
    //         },
    //       ],
    //       US_PERMISOS: [
    //         {
    //           PER_CODIGO: 2,
    //           PER_NOMBRE: "Empresa",
    //           PER_DESCRIPCION: "Empresa",
    //         },
    //       ],
    //       US_EMPRESAS: [
    //         {
    //           EMP_CODIGO: 36,
    //           EMP_ID: "036",
    //           EMP_RAZON_SOCIAL: "GALARMOBIL S.A.",
    //           EMP_NOMBRE_COMERCIAL: "GALARMOBIL",
    //           EMP_DIRECCION: "KM 10 1/2 VIA SAMBRORONDON\n",
    //           EMP_TELEFONO: "042145581\n",
    //           EMP_EMAIL_EMPRESA: "rindaburo@galarmobil.com.ec\n",
    //           EMP_REPRESENTANTE: "ROLANDO INDABURO PALACIOS\n",
    //           EMP_RUC: "0992535776001",
    //           EMP_PAGINA_WEB: "www.galarmobil.com.ec",
    //         },
    //         {
    //           EMP_CODIGO: 2,
    //           EMP_ID: "002",
    //           EMP_RAZON_SOCIAL: "NEOHYUNDAI",
    //           EMP_NOMBRE_COMERCIAL: "NEOHYUNDAI",
    //           EMP_DIRECCION: "Av. Galo Plaza Lasso",
    //           EMP_TELEFONO: "(02) 603-0740",
    //           EMP_EMAIL_EMPRESA: "",
    //           EMP_REPRESENTANTE: "",
    //           EMP_RUC: "0190310647001",
    //           EMP_PAGINA_WEB: "",
    //         },
    //         {
    //           EMP_CODIGO: 18,
    //           EMP_ID: "018",
    //           EMP_RAZON_SOCIAL: "ECSYAUTO S.A.",
    //           EMP_NOMBRE_COMERCIAL: "ECSYAUTO",
    //           EMP_DIRECCION:
    //             "Av. Remigio Tamariz y Av. Solano, Edificio Office, 3er piso, oficina 1",
    //           EMP_TELEFONO: "072838771",
    //           EMP_EMAIL_EMPRESA: "ecsyauto@ecsyauto.com.ec",
    //           EMP_REPRESENTANTE: "Ivan Garcia",
    //           EMP_RUC: "0992249102001",
    //           EMP_PAGINA_WEB: "www.ecsyauto.com.ec",
    //         },
    //       ],
    //       US_EMPRESA_DEFAULT: null,
    //     },
    //     message: "Correcto",
    //   };
    //   ls.set("ask-hy", { infoUsuario });
    //   // console.log(infoUsuario.data.US_PERMISOS[0].PER_CODIGO)
    //   if (infoUsuario.data.US_PERMISOS[0].PER_CODIGO === 2) {
    //     history.push("/listadoempresa");
    //   } else {
    //     history.push("/dashboard");
    //   }
    // }

    // else {
    //   setUsuario("");
    //   setPassword("");
    //   infoUsuario = [];
    //   ls.removeAll();
    //   alert("Usuario Incorrecto");
    //   history.push("/");
    // }
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });
  const setEmailPass = async () => {
    setIsLoading("visible");
    await axios
      .get(Server+"/api/usuario/recuperarCuenta/" + email)
      .then(function (response) {
        setIsLoading("invisible");
        if (response.data.success === true) {
          showMessage(response.data.message, "success");
        } else {
          showMessage(response.data.message, "error");
        }

        setemail("");
        setshowModal(false);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  return (
    <div className="login-cont">
      <div>
        <Modal
          show={showModal}
          onHide={!showModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Recupera tu contraseña
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
              <Form.Label className="title-create">Correo: </Form.Label>
              <Form.Control
                maxLength={255}
                placeholder="Ingrese nombre"
                id="us_correo"
                value={email}
                onChange={(e) => {
                  setemail(e.target.value);
                }}
              />
            </Col>

            <div className="text-center">
              <div className={isLoading}>
                <Spinner animation="border" role="status"></Spinner>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setEmailPass()}>Enviar</Button>
            <Button
              onClick={() => {
                setshowModal(false);
                setemail("");
              }}
            >
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Container>
        <Row className="login-box">
          <Col md={4} className="login-form mx-auto my-auto p-md-3">
            <Row>
              <Col xs={6} md={7} className="mx-auto text-center p-3">
                <Image
                  src={LogoHyundai}
                  alt="logo hyundai"
                  fluid
                  className="text-center p-3"
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mx-auto">
                <Form onSubmit={handleLogin}>
                  <Form.Group>
                    <Form.Control
                      id="email"
                      name="email"
                      type="email"
                      placeholder="Ingrese su email"
                      value={usuario}
                      onChange={(event) => setUsuario(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Control
                      id="password"
                      name="password"
                      type="password"
                      placeholder="Ingrese su contraseña"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Button
                      variant="primary"
                      className="btn-hyundai"
                      type="submit"
                      block
                    >
                      Iniciar
                    </Button>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
            <Row></Row>
          </Col>
        </Row>
      </Container>
      <div className="text-center">
        <div className={isLoading}>
          <Spinner animation="border" role="status"></Spinner>
        </div>
      </div>
    </div>
  );
}

export default Index;
