import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Form,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as xlsx from 'xlsx/xlsx.mjs';
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import { Server } from "../../constants";

require("react-quill/dist/quill.snow.css");

function Content() {
  const [dataTableBonoEmpresa, setdataTableBonoEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [isLoadingBonos, setIsLoadingBonos] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [showofertaEmpresas, setshowofertaEmpresas] = useState(false);
  const [idAlmacen, setIdAlmacen] = useState("");

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const columnsEmpresa = [
    {
      dataField: "bo_id",
      text: "Codigo",
      headerStyle: { width: 65 },
      sort: true,
      dataVisible: false,
    },
    {
      dataField: "boletin",
      text: "Boletin",
      sort: true,
      headerStyle: { width: 135 },
      filter: textFilter()
    },
    {
      dataField: "bo_vin",
      text: "VIN",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "ve_modelo",
      text: "Modelo",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "valor",
      text: "Valor Bono",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => formatoMoneda(row, index),
    },
    {
      dataField: "bo_cumplimiento_comercial",
      text: "Comercial Porcentaje",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => formatoPorcentaje(row.bo_cumplimiento_comercial, index),
    },
    {
      dataField: "",
      text: "Comercial Valor",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => calcularComercialValor(row, index),
    },
    {
      dataField: "bo_cumplimiento_auditoria",
      text: "Auditoria Porcentaje",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => formatoPorcentaje(row.bo_cumplimiento_auditoria, index),
    },
    {
      dataField: "",
      text: "Auditoria Valor",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => calcularAuditoriaValor(row, index),
    },
    {
      dataField: "bo_fecha_create",
      text: "Factura creación",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "bo_estado",
      text: "Estado",
      sort: true,
      headerStyle: { width: 135 },
      filter: textFilter(),

    },
  ];

  const formatoPorcentaje = (row) => {
    var valor = row ? row : 0;
    return (
      <span>{Math.round(valor)} %</span>
    )
  }

  const formatoMoneda = (row) => {
    var valor = row.valor;
    return (
      <span>$ {parseFloat(valor.toFixed(2)).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    )
  }

  const calcularComercialValor = (row) => {
    //calcular valor comercial
    var valorComercial = row.valor * (row.bo_cumplimiento_comercial / 100);
    return (
      <span>$ {parseFloat(valorComercial.toFixed(2)).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    );
  };

  const calcularAuditoriaValor = (row) => {
    //calcular valor auditoria
    var valorAuditoria = row.valor * (row.bo_cumplimiento_auditoria / 100);
    return (
      <span>$ {parseFloat(valorAuditoria.toFixed(2)).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const getList = async (idAlmacen) => {
    setshowofertaEmpresas(false);
    if (idAlmacen !== "0") {
      setIsLoadingBonos("visible");
      await axios
        .get(
          Server + "/api/bonos/getBonosMarcaForEmpresa/" +
          idAlmacen
        )
        .then(function (response) {
          setIsLoadingBonos("invisible");
          setshowofertaEmpresas(true);
          setdataTableBonoEmpresa(response.data.data);
        })
        .catch(function (error) { })
        .then(function () { });
    }
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        editBono(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  }

  const editBono = async (datos) => {
    let lengthDatos = Object.keys(datos).length;
    let rows = [];
    let cont = 1;
    setIsLoading("visible");
    await datos.forEach(async element => {
      let data2 = new FormData();
      data2.append("bo_vin", element['VIN']);
      data2.append("bo_cumplimiento_comercial", element['CUMPLIMIENTO_COMERCIAL']);
      data2.append("bo_cumplimiento_auditoria", element['CUMPLIMIENTO_AUDITORIA']);
      data2.append("usuario", data.US_CODIGO);
      await axios
        .post(Server + "/api/bonos/editCumplimientoBono", data2)
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            rows.push({
              "VIN": element['VIN'],
              "CUMPLIMIENTO_COMERCIAL": element['CUMPLIMIENTO_COMERCIAL'],
              "CUMPLIMIENTO_AUDITORIA": element['CUMPLIMIENTO_AUDITORIA'],
              "MENSAJE": response.data.message
            });
            if (lengthDatos === cont) {
              generateExcel(rows);
              getList(idAlmacen);
            }
            cont++;
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setIsLoading("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    });
    setIsLoading("invisible");
  }

  const generateExcel = async (rows) => {
    /* generate worksheet and workbook */
    const worksheet = xlsx.utils.json_to_sheet(rows);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Bonos");

    /* fix headers */
    xlsx.utils.sheet_add_aoa(worksheet, [["VIN", "CUMPLIMIENTO_COMERCIAL", "CUMPLIMIENTO_AUDITORIA", "MENSAJE"]], { origin: "A1" });

    let nombreArchivo = "Cumplimiento_" + Date.now().toLocaleString() + ".xlsx";
    xlsx.writeFile(workbook, nombreArchivo, { compression: true });
  }

  const listofertaEmpresa = () => {
    if (showofertaEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">bonos</Card.Header>
            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="bo_id"
                  data={dataTableBonoEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                />
              </div>
              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoadingBonos}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server + "/api/empresas/getListEmpresasActivas"
        )
        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) { })
        .then(function () { });
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowofertaEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Subir listado de bonos para aprobación
              </Card.Header>
              <Card.Body>
                <Form.Group controlId="formFile" >
                  <Form.Label>Seleccione archivo para aprobar bonos</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                  />
                </Form.Group>
                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus bonos
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                    setIdAlmacen(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>
                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoadingBonos}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          {listofertaEmpresa()}
        </div >
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listofertaEmpresa()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Bonos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {marcaoEmpresa()}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
