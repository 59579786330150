import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import "react-datepicker/dist/react-datepicker.css";

require("react-quill/dist/quill.snow.css");

function Content() {

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div width="100%" height="100%">
          <PageTitle
            heading="Reporte Despachos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
        </div>
      </ReactCSSTransitionGroup>
      <iframe title="Supply Chain Management - SCM Hyundai Ecuador" width="100%" height="85%" src="https://app.powerbi.com/reportEmbed?reportId=d2a5a30b-d39c-45ba-aecf-9cd452259b43&autoAuth=true&ctid=93a310a3-4002-4839-be9f-804eea3661b6" frameborder="0" allowFullScreen="true"></iframe>
    </Fragment>
  );
}

export default Content;
