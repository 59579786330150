import React from "react";
import { Route, Redirect } from "react-router-dom";
import * as SecureLS from 'secure-ls';

function RutaLogged({ component: Component }) {

  const ls = new SecureLS({encodingType: 'des', isCompression: false, encryptionSecret: 'admin-hyundai'});
  const {infoUsuario} = ls.get('ask-hy')
  let auth = false;

  if (infoUsuario !== null && infoUsuario !== undefined) {
    let { autenticado } = infoUsuario;
    auth = autenticado;
  }

  return (
    <Route
      render={(props) =>
        auth === true ? <Component /> : <Redirect to="/" />
      }
    />
  );
}

export default RutaLogged;
