import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Button,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTablenoticasMarca, setdataTablenoticasMarca] = useState([]);
  const [dataTablenoticasMarcaEmpresa, setdataTablenoticasMarcaEmpresa] =
    useState([]);
  const [dataTableNoticiaEmpresa, setdataTableNoticiaEmpresa] = useState([]);

  const [showModal, setshowModal] = useState(false);
  const [tipo, settipo] = useState(1);
  const [isLoadingEstado, setisLoadingEstado] = useState("invisible");
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const columns = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOT_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsAempresas = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOT_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactiveforEmpresa(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "NOTE_NOMBRE_EMPRESA",
      text: "Empresa",
      sort: true,
    },

    {
      dataField: "NOTE_ESTADO",
      text: "Asignar",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.NOTE_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const activeDesactiveforEmpresa = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.NOT_ESTADO)}
        onChange={() => changeEstadoEmpresa(row, index)}
      />
    );
  };

  const changeEstado = async (row, index) => {
    if (row.NOTE_ESTADO === 1) {
      row.NOTE_ESTADO = 0;
    } else if (row.NOTE_ESTADO === 0) {
      row.NOTE_ESTADO = 1;
    }

    updateEstado(row, index);
  };

  const changeEstadoEmpresa = async (row, index) => {
    if (row.NOT_ESTADO === 1) {
      row.NOT_ESTADO = 0;
    } else if (row.NOT_ESTADO === 0) {
      row.NOT_ESTADO = 1;
    }

    updateEstadoEmpresa(row, index);
  };

  const updateEstadoEmpresa = (item, index) => {
    setisLoadingEstado("visible");

    const dataForm = new FormData();
    dataForm.append("note_noticia", item.NOT_CODIGO);
    dataForm.append("note_empresa", data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    dataForm.append("note_estado", item.NOT_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/empresas/asignarNoticia",
        dataForm
      )

      .then(function (response) {
        setisLoadingEstado("invisible");

        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTablenoticasMarcaEmpresa];
          newArr[index] = item;
          setdataTablenoticasMarcaEmpresa(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const updateEstado = (item, index) => {
    setisLoadingEstado("visible");

    const dataForm = new FormData();
    dataForm.append("note_noticia", item.NOTE_NOTICIA);
    dataForm.append("note_empresa", item.NOTE_EMPRESA);
    dataForm.append("note_estado", item.NOTE_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/empresas/asignarNoticia",
        dataForm
      )

      .then(function (response) {
        setisLoadingEstado("invisible");

        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTableNoticiaEmpresa];
          newArr[index] = item;
          setdataTableNoticiaEmpresa(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {});
  };

  const edit = (row, index) => {
    // setnoticiaempresa(noticiaempresa => {
    //   return { ...noticiaempresa,
    //                         note_noticia: row.NOT_CODIGO,
    //                         note_empresa:'0',
    //                        }});

    getAsignaNoticiaEm(row.NOT_CODIGO);
  };

  const getAsignaNoticiaEm = async (idNoticia) => {
    //setisLoadingTableMarca('visble')
    await axios
      .get(
        Server+"/api/empresas/getListActive/" + idNoticia
      )

      .then(function (response) {
        // setisLoadingTableMarca('invisible')
        setdataTableNoticiaEmpresa(response.data.data);
        setshowModal(true);
      })
      .catch(function (error) {
        //setisLoadingTableMarca('invisible')
      })
      .then(function () {});
  };

  const getNoticiasMarca = async () => {
    // setisLoadingTableMarca('visble')
    await axios
      .get(Server+"/api/empresas/getAllNoticiasMarca")

      .then(function (response) {
        // setisLoadingTableMarca('invisible')
        setdataTablenoticasMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  useEffect(() => {
    const getNoticiasMarcaEmpresa = async (idEmpresa) => {
      await axios
        .get(
          Server+"/api/empresas/getAllNoticiasMarcaForEmpresa/" +
            idEmpresa
        )

        .then(function (response) {
          setdataTablenoticasMarcaEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      // getOfertasMarca()
      // getDataEmpresas()

      settipo(1);
      getNoticiasMarca();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      // setshowofertaEmpresas(true)
      // getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO)
      settipo(2);
      getNoticiasMarcaEmpresa(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const typeAsignar = (typo) => {
    if (typo === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <BootstrapTable
            keyField="NOT_CODIGO"
            data={dataTablenoticasMarca}
            columns={columns}
            pagination={paginationFactory()}
          />

          {/* <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div> */}
        </div>
      );
    } else if (typo === 2) {
      return (
        <div style={{ marginTop: 10 }}>
          <BootstrapTable
            keyField="NOT_CODIGO"
            data={dataTablenoticasMarcaEmpresa}
            columns={columnsAempresas}
            pagination={paginationFactory()}
          />
        </div>
      );
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home / Asignar"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                <div style={{ marginTop: 10 }}>
                  <Card>
                    <Card.Header as="h5">Noticias de Marca</Card.Header>

                    <Card.Body>
                      {/* <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div> */}

                      {/* <div style={{marginTop:10}}>                                  
                  <BootstrapTable
                      keyField="NOT_CODIGO"
                      data={dataTablenoticasMarca}
                      columns={columns}
                      pagination={paginationFactory()}
                  />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>

        
              </div>



              <div style={{marginTop:10}}>                                  
                  <BootstrapTable
                      keyField="NOT_CODIGO"
                      data={dataTablenoticasMarcaEmpresa}
                      columns={columnsAempresas}
                      pagination={paginationFactory()}
                  />

             

        
              </div> */}

                      {typeAsignar(tipo)}
                    </Card.Body>
                  </Card>
                </div>

                <div>
                  <Modal
                    show={showModal}
                    // onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Seleccione una empresa para asignar Noticia?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="text-center">
                        <div className={isLoadingEstado}>
                          <Spinner
                            animation="border"
                            role="status"
                            variant="secondary"
                          ></Spinner>
                        </div>
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <BootstrapTable
                          keyField="NOTE_EMPRESA"
                          data={dataTableNoticiaEmpresa}
                          columns={columnsEmpresa}
                          pagination={paginationFactory()}
                        />

                        {/* <Form.Control as="select" value={noticiaempresa.note_empresa} onChange={e => {
                                              const val = e.target.value;
                                              setnoticiaempresa(noticiaempresa => {
                                                  return { ...noticiaempresa, note_empresa: val }
                                              });}}>

                        <option value="0">N/A</option>
                        {getRowsEmpresas}

                  </Form.Control> */}
                      </div>
                    </Modal.Body>

                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
