import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  Modal,
  FormGroup,
  Form,
  Spinner,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import axios from "axios";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [image, setimage] = useState("");
  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [image2, setimage2] = useState();
  const [foto, setfoto] = useState();
  const [isLoading, setIsLoading] = useState("invisible");

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const columns = [
    {
      dataField: "MOD_FOTO_PRINCIPAL",
      text: "Foto",
      formatter: (cell, row, index) => showFoto(row, index),
      sort: true,
    },
    {
      dataField: "MOD_CODIGO",
      text: "Código",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "MOD_NOMBRE",
      text: "Nombre",
      sort: true,
    },

    {
      dataField: "MOD_EDITAR",
      text: "Editar",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setIsLoading("visible");

    await axios
      .get(Server+"/api/vehiculos/getAllModelos")
      .then(function (response) {
        setIsLoading("invisible");

        setdataTable(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => editFoto(row, index)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editFoto = (row, index) => {
    setimage("");

    if (row.MOD_FOTO_PRINCIPAL !== null) {
      setimage(row.MOD_FOTO_PRINCIPAL);
    }

    setfoto(row);
    setshowModal(true);
  };

  const showFoto = (row, index) => {
    if (row.MOD_FOTO_PRINCIPAL !== null) {
      return <img style={{ width: 100 }} src={row.MOD_FOTO_PRINCIPAL} alt="" />;
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const modalFoto = (foto) => {
    if (foto !== "") {
      return (
        <img
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            height: "50%",
          }}
          src={foto}
          alt=""
        />
      );
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const selectFile = (event) => {
    setimage(URL.createObjectURL(event.target.files[0]));
    setimage2(event.target.files[0]);
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const updateFoto = () => {
    const data2 = new FormData();
    data2.append("file", image2);
    data2.append("code", foto.MOD_CODIGO);
    data2.append("usuario", data.US_CODIGO);
    axios
      .post(
        Server+"/api/vehiculos/uploadPhotoModelo",
        data2
      )
      .then(function (response) {
        if (response.data.success === true) {
          setshowModal(false);
          getData();
          showMessage(response.data.message, "success");
        } else {
          // showMessage(response.data.message, 'error')
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Vehiculo/ Catálogo"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Editar Foto
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <FormGroup as={Row}>{modalFoto(image)}</FormGroup>

                      <FormGroup as={Row}>
                        <label className="btn btn-default">
                          <input
                            type="file"
                            onChange={selectFile}
                            onClick={(e) => (e.target.value = null)}
                          />
                        </label>
                      </FormGroup>

                      <span style={{ color: "gray", fontSize: 12 }}>
                        El tamaño de la imagen debe ser 720px * 350px y formato
                        .png
                      </span>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button onClick={() => updateFoto()}>Actualizar</Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="text-center">
                <div className={isLoading} style={{ marginTop: 10 }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>

              <BootstrapTable
                keyField="MOD_CODIGO"
                data={dataTable}
                columns={columns}
              />
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
