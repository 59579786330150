import React  from "react";
import { Modal, Button, Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';



function ModalInfo(props) {


    const columns = [
        {
            
          dataField: "OP_CODIGO",
          text: "Codigo",
          sort: true
        },
        {
          dataField: "OP_NOMBRE",
          text: "Nombre",
          sort: true
        },
        {
          dataField: "OP_ESTADO",
          text: "Estado",
          formatter: (cell, row,index) => activeDesactive(row,index),
        }
      ]


     

      const activeDesactive = (row,index) =>{
        return(
            <input
                        type="checkbox"
                        name="something"
                        value="asa"
                        checked={setEstado(row.OP_ESTADO)}
                        onChange={() => changeEstado(row,index)}
                    />
        )
    }

    const setEstado = (estado) => {
        if (estado === 1) {
            return true
        } else if (estado === 0) {
            return false
        }

    }

    const changeEstado = async (itemModal,indexModal) => {
        props.updateEstadoModal(itemModal,indexModal)
    }

   





    return (


        <Modal
           
            show={props.show}
            // onHide={props.hideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Selección de Opciones para Menú
          </Modal.Title>
            </Modal.Header>
            <Modal.Body>

            <BootstrapTable
                    keyField="OP_CODIGO"
                    data={props.listItems}
                    columns={columns}
                />
                <div className="text-center">
                    <div className={"invisible"}>
                        <Spinner animation="border" role="status">
                        </Spinner>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onHide}>Cerrar</Button>
            </Modal.Footer>
        </Modal>
    );
}



export default ModalInfo;