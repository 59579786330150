import React, { Fragment, useState, useEffect, useCallback } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as xlsx from 'xlsx/xlsx.mjs';
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from "react-bootstrap-table2-paginator";
import { Server } from "../../constants";
require("react-quill/dist/quill.snow.css");

function Content() {
  const [dataTableBonoEmpresa, setdataTableBonoEmpresa] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [isLoadingFacturas, setIsLoadingFacturas] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [idAlmacen, setidAlmacen] = useState([]);
  const [showofertaEmpresas, setshowofertaEmpresas] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [filtroBono, setfiltroBono] = useState({
    boletin: "",
    vin: "",
    modelo: "",
    estado: "",
  });

  const columnsEmpresa = [
    {
      dataField: "bo_id",
      text: "Codigo",
      headerStyle: { width: 65 },
      sort: true,
      dataVisible: false,
    },
    {
      dataField: "boletin",
      text: "Boletin",
      sort: true,
      headerStyle: { width: 135 },
      filter: textFilter()
    },
    {
      dataField: "bo_vin",
      text: "VIN",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "ve_modelo",
      text: "Modelo",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "wholesale",
      text: "Factura WholeSale",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "bo_fecha_create",
      text: "Factura creación",
      sort: true,
      filter: textFilter()
    },
    {
      dataField: "bo_estado",
      text: "Estado",
      sort: true,
      headerStyle: { width: 135 },
      filter: textFilter(),
    },
    {
      dataField: "valor",
      text: "Valor Bono",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => formatoMoneda(row, index),
    },
    {
      dataField: "",
      text: "Auditoria Valor",
      sort: true,
      filter: textFilter(),
      formatter: (cell, row, index) => calcularAuditoriaValor(row, index),
    },
  ];

  const formatoMoneda = (row) => {
    var valor = row.valor;
    return (
      <span>$ {parseFloat(valor.toFixed(2)).toLocaleString('de-DE', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</span>
    )
  }

  const calcularAuditoriaValor = (row) => {
    //calcular valor auditoria
    var valorComercial = row.valor * (row.bo_cumplimiento_auditoria / 100);
    return (
      <span>$ {valorComercial.toFixed(2)}</span>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const getList = useCallback(async (idAlmacen) => {
    setshowofertaEmpresas(false);
    if (idAlmacen !== "0") {
      setIsLoading("visible");
      await axios
        .get(
          Server + "/api/bonos/getBonosMarcaForEmpresaWholesale/" + idAlmacen, {
          params: {
            boletin: filtroBono.boletin,
            vin: filtroBono.vin,
            modelo: filtroBono.modelo,
            estado: filtroBono.estado
          }
        }
        )
        .then(function (response) {
          setidAlmacen(idAlmacen);
          setIsLoading("invisible");
          setshowofertaEmpresas(true);
          setdataTableBonoEmpresa(response.data.data);
        })
        .catch(function (error) { })
        .then(function () { });
    }
  },[filtroBono]);

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_NOMBRE_COMERCIAL}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const readUploadFile = (e) => {
    setIsLoadingFacturas(true);
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        pagoBono(json);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
    setIsLoadingFacturas(false);
  }

  const pagoBono = async (datos) => {
    let lengthDatos = Object.keys(datos).length;
    let rows = [];
    let cont = 1;
    await datos.forEach(async element => {
      let data2 = new FormData();
      data2.append("bo_vin", element['VIN']);
      data2.append("bo_pago", element['NOTA_CREDITO']);
      data2.append("bo_empresa", idAlmacen);
      data2.append("usuario", data.US_CODIGO);
      await axios
        .post(Server + "/api/bonos/pagoBono", data2)
        .then(function (response) {
          console.log(response);
          if (response.data.success) {
            setIsLoading("visible");
            rows.push({
              "vin": element['vin'],
              "nota_credito": element['nota_credito'],
              "Mensaje": response.data.message
            });
            if (lengthDatos === cont) {
              generateExcel(rows);
              getList(idAlmacen);
            }
            cont++;
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setIsLoading("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    });
  }

  const generateExcel = async (rows) => {
    /* generate worksheet and workbook */
    const worksheet = xlsx.utils.json_to_sheet(rows);
    const workbook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(workbook, worksheet, "Bonos");

    /* fix headers */
    xlsx.utils.sheet_add_aoa(worksheet, [["VIN", "NOTA_CREDITO", "MENSAJE"]], { origin: "A1" });

    let nombreArchivo = "ReporteBono_" + Date.now().toLocaleString() + ".xlsx";
    xlsx.writeFile(workbook, nombreArchivo, { compression: true });
  }

  const listofertaEmpresa = () => {
    if (showofertaEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">bonos</Card.Header>
            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="bo_id"
                  data={dataTableBonoEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                  filter={filterFactory()}
                />
              </div>
              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server + "/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) { })
        .then(function () { });
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowofertaEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data,getList]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Subir pago de bonos
              </Card.Header>
              <Card.Body>
                <Form.Group controlId="formFile" >
                  <Form.Label>Archivo para pago de bonos</Form.Label>
                  <Form.Control
                    type="file"
                    name="upload"
                    id="upload"
                    onChange={readUploadFile}
                  />
                </Form.Group>
                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoadingFacturas}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus bonos
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    setidAlmacen(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">Filtros</Card.Header>
              <Card.Body>
                <Form>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Boletín:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={100}
                        placeholder="Ingrese Boletín"
                        id="boletin"
                        value={filtroBono.boletin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, boletin: val };
                          });
                        }}
                      />
                      
                    </Col>
                    <Col>
                      <Form.Label className="title-create">VIN: </Form.Label>
                      <Form.Control
                        maxLength={6}
                        placeholder="Ingrese VIN"
                        id="vin"
                        value={filtroBono.vin}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, vin: val };
                          });
                        }}
                      />
                      
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Modelo:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={100}
                        placeholder="Ingrese Modelo"
                        id="modelo"
                        value={filtroBono.modelo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, modelo: val };
                          });
                        }}
                      />
                      
                    </Col>
                    <Col>
                      <Form.Label className="title-create">
                        Estado:{" "}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        placeholder="Ingrese estado"
                        id="estado"
                        value={filtroBono.estado}
                        onChange={(e) => {
                          const val = e.target.value;
                          setfiltroBono((filtroBono) => {
                            return { ...filtroBono, estado: val };
                          });
                        }}
                      >
                        <option value="">ESCOJA UN ESTADO</option>
                        <option value="ABIERTO">ABIERTO</option>
                        <option value="PENDIENTE">PENDIENTE</option>
                        <option value="VALIDADO">VALIDADO</option>
                        <option value="PREAPROBADO">PREAPROBADO</option>
                        <option value="APROBADO">APROBADO</option>
                        <option value="APLICADO">APLICADO</option>
                        <option value="RECHAZADO">RECHAZADO</option>
                      </Form.Control>
                      
                    </Col>
                  </FormGroup>
                </Form>
              </Card.Body>

              <div className="text-center">
                <Button variant="primary" onClick={() => getList(idAlmacen)}>
                  Buscar bonos
                </Button>
              </div>
            </Card>
          </div>
          {listofertaEmpresa()}
        </div >
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listofertaEmpresa()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Bonos"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {marcaoEmpresa()}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
