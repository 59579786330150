import React, { Fragment } from "react";
import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import { useHistory } from "react-router-dom";
/* import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; */
import avatar1 from "../../images/assets/avatar.png";
import * as SecureLS from "secure-ls";
import axios from "axios";
import Swal from "sweetalert2";
import {Server} from "../../constants";

function UserBox() {
  const history = useHistory();
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;
  console.log(data);
  const empresa = data.US_EMPRESA_DEFAULT ? '- '+data.US_EMPRESA_DEFAULT.EMP_NOMBRE_COMERCIAL : '';
  

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const handleLogout = async () => {
    await axios
      .get(
        Server+"/api/usuario/logout/" +
          data.US_CODIGO_LOGEO
      )
      .then(function (response) {
        if (response.data.success) {
          ls.removeAll();
          history.push("/");
        } else {
          showMessage("Error en el server intentalo de Nuevo", "error");
        }
      })
      .catch(function (error) {
        showMessage("Error en el server intentalo de Nuevo", "error");
      })
      .then(function () {});
  };

  return (
    <Fragment>
      <div className="header-btn-lg pr-0">
        <div className="widget-content p-0">
          <div className="widget-content-wrapper">
            <div className="widget-content-left">
              <Dropdown className="menu-principal">
                <Dropdown.Toggle color="link" className="p-0">
                  <img
                    width={42}
                    className="rounded-circle"
                    src={avatar1}
                    alt=""
                  />
                  {/* <FontAwesomeIcon
                    className="ml-2 opacity-8"
                    icon={faAngleDown}
                  /> */}
                </Dropdown.Toggle>
                <Dropdown.Menu right className="rm-pointers dropdown-menu-lg">
                  <Nav vertical>
                    <NavItem className="nav-item-header">Configuración</NavItem>
                    <NavItem>
                      <NavLink to="/">Perfil</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/">Cambiar Contraseña</NavLink>
                    </NavItem>
                    <Dropdown.Divider />
                    <NavItem>
                      <NavLink onClick={handleLogout}>Salir</NavLink>
                    </NavItem>
                  </Nav>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="widget-content-left  ml-3 header-user-info">
              <div className="widget-heading">
                {data.US_NOMBRE} {data.US_APELLIDO}
              </div>
              <div className="widget-subheading">{data.US_CARGO} {empresa}</div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default UserBox;
