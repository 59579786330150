import React, { Fragment, useState } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import { Row, Col, Button, Form, Spinner } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import axios from "axios";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content(props) {
  const [items, setitems] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const getData = async (id) => {
    setIsLoading("visible");
    await axios
      .get(
        Server+"/api/vehiculos/getModelosOrderTipo/" + id
      )
      .then(function (response) {
        setitems(Array.from(response.data));
        setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const updateOrden = () => {
    if (items.length === 0) {
      showMessage("Tipo de Modelo no señalado", "error");
    } else {
      setIsLoading("visible");

      const dataForm = new FormData();
      dataForm.append("list_orden", JSON.stringify(items));
      dataForm.append("usuario", JSON.stringify(data.US_CODIGO));
      dataForm.append("_method", "PUT");

      axios
        .post(
          Server+"/api/vehiculos/setNuevoOrden",
          dataForm
        )

        .then(function (response) {
          setIsLoading("invisible");
          if (response.data.success === true) {
            showMessage(response.data.message, "success");
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  // useEffect(() => {

  //   getData()

  //   // setitems(getItems(10))

  // },[]);

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    background: isDragging ? "#002c5f" : "#c4c4c4",
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    padding: grid,
    width: props.width,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const items2 = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setitems(items2);
  };

  const listaAutostipo = (id) => {
    if (id === 0) {
      setitems([]);
    } else {
      getData(id);
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Vehiculo / Catálogo"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Form.Control
                  as="select"
                  onChange={(e) => listaAutostipo(e.target.value)}
                >
                  <option value="0">N/A</option>
                  <option value="1">Autos</option>
                  <option value="2">SUV</option>
                  <option value="3">VAN</option>
                </Form.Control>
              </div>

              <div className="text-center">
                <div className={isLoading} style={{ marginTop: 10 }}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>

              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {items.map((item, index) => (
                        <Draggable
                          key={item.MOD_CODIGO.toString()}
                          draggableId={item.MOD_CODIGO.toString()}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              {item.MOD_NOMBRE}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <div className="text-center">
                <Button variant="primary" onClick={() => updateOrden()}>
                  Guardar cambios
                </Button>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
