import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import { Row, Col } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import axios from "axios";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTable, setdataTable] = useState([]);
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const columns = [
    {
      dataField: "MOD_CODIGO",
      text: "Codigo",
      sort: true,
      headerStyle: { width: 125 },
    },
    {
      dataField: "MOD_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "MOD_DESCRIPCION",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "MOD_ACTIVO_SHOWROOM",
      text: "Estado",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },
  ];

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.MOD_ACTIVO_SHOWROOM === 1) {
      item.MOD_ACTIVO_SHOWROOM = 0;
    } else if (item.MOD_ACTIVO_SHOWROOM === 0) {
      item.MOD_ACTIVO_SHOWROOM = 1;
    }
    update(item, index);
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const update = (dataUpdate, index) => {
    const dataForm = new FormData();
    dataForm.append("mod_codigo", dataUpdate.MOD_CODIGO);
    dataForm.append("mod_estado", dataUpdate.MOD_ACTIVO_SHOWROOM);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    axios
      .post(
        Server+"/api/vehiculos/updateStatusVehiculo",
        dataForm
      )

      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = dataUpdate;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.MOD_ACTIVO_SHOWROOM)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  //Prueba
  const getData = async () => {
    await axios
      .get(Server+"/api/vehiculos/getAllModelos")
      .then(function (response) {
        setdataTable(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Vehiculo / Catálogo"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <BootstrapTable
                keyField="MOD_CODIGO"
                data={dataTable}
                columns={columns}
              />
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
