import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  FormGroup,
  Spinner,
  Card,
} from "react-bootstrap";
import * as ReactQuill from "react-quill";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import * as moment from "moment";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";
require("react-quill/dist/quill.snow.css");

function Content() {
  const [showModal, setshowModal] = useState(false);
  const [showModalUpdate, setshowModalUpdate] = useState(false);

  const [image2, setimage2] = useState("");
  const [startDate, setstartDate] = useState(new Date());
  const [dataTablenoticasMarca, setdataTablenoticasMarca] = useState([]);

  const [dataTablenoticasEmpresa, setdataTablenoticasEmpresa] = useState([]);
  const [isLoadingCreate, setisLoadingCreate] = useState("invisible");
  const [isLoadingTableMarca, setisLoadingTableMarca] = useState("invisible");
  const [isLoading, setIsLoading] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [idAlmacen, setidAlmacen] = useState([]);

  const [showNoticasEmpresas, setshowNoticasEmpresas] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [errorNoticia, seterrorNoticia] = useState({
    titulo: "",
    imagen: "",
    descripcion: "",
    url: "",
  });

  const [noticia, setnoticia] = useState({
    not_codigo: 0,
    not_url_imagen: "",
    not_fecha: new Date(),
    not_titulo: "",
    not_url: "",
    not_estado: 0,
    not_carrusel: 0,
    not_descripcion: "",
    not_tipo: 0,
    not_empresa: 0,
    note_codigo: 0,
  });

  const columns = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "ALM_EDITAR",
      text: "Editar",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "ALM_EDITAR",
      text: "Editar",
      headerStyle: { width: 125 },
      formatter: (cell, row, index) => editRowEmpresa(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    var parts = row.NOT_FECHA.split("-");
    setimage2("");
    setstartDate(new Date(parts[0], parts[1] - 1, parts[2]));
    setnoticia((noticia) => {
      return {
        ...noticia,
        not_tipo: 0,
        not_empresa: 0,
        not_url_imagen: row.NOT_URL_IMAGEN,
        not_fecha: row.NOT_FECHA,
        not_titulo: row.NOT_TITULO,
        not_url: row.NOT_URL,
        not_estado: row.NOT_CARRUSEL,
        not_carrusel: row.NOT_CARRUSEL,
        not_descripcion: row.NOT_DESCRIPCION,
        note_codigo: 0,
        not_codigo: row.NOT_CODIGO,
      };
    });

    setshowModalUpdate(true);
  };

  const editRowEmpresa = (row, index) => {
    return (
      <Button
        onClick={() => {
          editEmpresa(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editEmpresa = (row, index) => {
    var parts = row.NOT_FECHA.split("-");
    setimage2("");
    setstartDate(new Date(parts[0], parts[1] - 1, parts[2]));
    setnoticia((noticia) => {
      return {
        ...noticia,
        not_empresa: idAlmacen,
        note_codigo: row.NOTE_CODIGO,
        not_url_imagen: row.NOT_URL_IMAGEN,
        not_fecha: row.NOT_FECHA,
        not_titulo: row.NOT_TITULO,
        not_url: row.NOT_URL,
        not_estado: row.NOTE_CARRUSEL,
        not_carrusel: row.NOTE_CARRUSEL,
        not_descripcion: row.NOT_DESCRIPCION,
        not_codigo: row.NOT_CODIGO,
      };
    });

    setshowModalUpdate(true);
  };

  const contentNoticia = (value) => {
    setnoticia((noticia) => {
      return { ...noticia, not_descripcion: value };
    });
  };

  const modalFoto = (foto) => {
    if (foto !== "") {
      return (
        <img
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            height: "40%",
          }}
          src={foto}
          alt=""
        />
      );
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const selectFile = (event) => {
    setimage2(event.target.files[0]);
    //setimage(URL.createObjectURL(event.target.files[0]))
    const val = URL.createObjectURL(event.target.files[0]);
    setnoticia((noticia) => {
      return { ...noticia, not_url_imagen: val };
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const createNoticia = async () => {
    seterrorNoticia((errorNoticia) => {
      return {
        ...errorNoticia,
        imagen: "",
        descripcion: "",
        url: "",
        titulo: "",
      };
    });

    if (
      image2 !== null &&
      noticia.not_titulo !== "" &&
      noticia.not_url !== "" &&
      noticia.not_descripcion !== "" &&
      noticia.not_url_imagen !== ""
    ) {
      const data2 = new FormData();
      data2.append("file", image2);
      data2.append("not_fecha", noticia.not_fecha);
      data2.append("not_titulo", noticia.not_titulo);
      data2.append("not_url", noticia.not_url);
      data2.append("not_estado", noticia.not_estado);
      data2.append("not_carrusel", noticia.not_carrusel);
      data2.append("not_descripcion", noticia.not_descripcion);
      data2.append("not_tipo", noticia.not_tipo);
      data2.append("not_empresa", noticia.not_empresa);
      data2.append("note_codigo", noticia.note_codigo);
      data2.append("usuario", data.US_CODIGO);

      setisLoadingCreate("visible");

      await axios
        .post(Server+"/api/empresas/CreateNoticia", data2)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreate("invisible");
            setshowModal(false);
            showMessage(response.data.message, "success");

            if (noticia.not_tipo === 0) {
              getNoticiasMarca();
            } else if (noticia.not_tipo === 1) {
              getList(noticia.not_empresa);
            }
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreate("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (image2 === null || image2 === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, imagen: "Imagen Requerida" };
        });
      }

      if (noticia.not_descripcion === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, descripcion: "Campo Requerido" };
        });
      }

      if (noticia.not_url === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, url: "Campo Requerido" };
        });
      }

      if (noticia.not_titulo === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, titulo: "Campo Requerido" };
        });
      }

      showMessage("Campos Vacios Por favor Completarlos", "error");
    }
  };

  const updateNoticia = async () => {
    seterrorNoticia((errorNoticia) => {
      return {
        ...errorNoticia,
        imagen: "",
        descripcion: "",
        url: "",
        titulo: "",
      };
    });

    var newImagen = 0;

    if (image2 !== "") {
      newImagen = 1;
    }

    var desaAc = 0;

    if (noticia.not_carrusel !== noticia.not_estado) {
      if (noticia.not_carrusel === 1) {
        desaAc = 1;
      } else if (noticia.not_carrusel === 0) {
        desaAc = 2;
      }
    }

    if (
      noticia.not_titulo !== "" &&
      noticia.not_url !== "" &&
      noticia.not_descripcion !== "" &&
      noticia.not_url_imagen !== ""
    ) {
      const data2 = new FormData();
      data2.append("file", image2);
      data2.append("not_fecha", noticia.not_fecha);
      data2.append("not_titulo", noticia.not_titulo);
      data2.append("not_url", noticia.not_url);
      data2.append("not_estado", noticia.not_estado);
      data2.append("not_carrusel", noticia.not_carrusel);
      data2.append("not_descripcion", noticia.not_descripcion);
      data2.append("not_url_imagen", noticia.not_url_imagen);
      data2.append("not_tipo", noticia.not_tipo);
      data2.append("not_new_imagen", newImagen);
      data2.append("not_activado_a_des", desaAc);
      data2.append("not_empresa", noticia.not_empresa);
      data2.append("not_codigo", noticia.not_codigo);
      data2.append("note_codigo", noticia.note_codigo);
      data2.append("usuario", data.US_CODIGO);

      setisLoadingCreate("visible");

      await axios
        .post(Server+"/api/empresas/updateNoticia", data2)

        .then(function (response) {
          if (response.data.success) {
            setisLoadingCreate("invisible");
            setshowModalUpdate(false);
            showMessage(response.data.message, "success");

            if (noticia.not_empresa === 0) {
              getNoticiasMarca();
            } else {
              getList(noticia.not_empresa);
            }
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          setisLoadingCreate("invisible");
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (image2 === null || image2 === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, imagen: "Imagen Requerida" };
        });
      }

      if (noticia.not_descripcion === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, descripcion: "Campo Requerido" };
        });
      }

      if (noticia.not_url === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, url: "Campo Requerido" };
        });
      }

      if (noticia.not_titulo === "") {
        seterrorNoticia((errorNoticia) => {
          return { ...errorNoticia, titulo: "Campo Requerido" };
        });
      }

      showMessage("Campos Vacios Por favor Completarlos", "error");
    }
  };

  const getNoticiasMarca = async () => {
    setisLoadingTableMarca("visble");
    await axios
      .get(Server+"/api/empresas/getNoticiasMarca")

      .then(function (response) {
        setisLoadingTableMarca("invisible");
        setdataTablenoticasMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const getList = async (idAlmacen) => {
    // setshowTable(false)

    setshowNoticasEmpresas(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getNoticiasMarcaForEmpresa/" +
            idAlmacen
        )

        .then(function (response) {
          setidAlmacen(idAlmacen);
          setIsLoading("invisible");
          setshowNoticasEmpresas(true);
          setdataTablenoticasEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const listNoticasEmpresa = () => {
    if (showNoticasEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias</Card.Header>
            <Card.Body>
              <Button
                variant="primary"
                onClick={() => {
                  setshowModal(true);
                  setnoticia((noticia) => {
                    return {
                      ...noticia,
                      not_tipo: 1,
                      not_empresa: idAlmacen,
                      not_url_imagen: "",
                      not_fecha: moment(new Date()).format("yyyy-MM-DD"),
                      not_titulo: "",
                      not_url: "",
                      not_estado: 0,
                      not_carrusel: 0,
                      note_codigo: 0,
                      not_descripcion: "",
                    };
                  });
                }}
              >
                Crear Noticia
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                />
              </div>

              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getNoticiasMarca();
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowNoticasEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias de Marca</Card.Header>

            <Card.Body>
              <Button
                variant="primary"
                onClick={() => {
                  setshowModal(true);
                  setnoticia((noticia) => {
                    return {
                      ...noticia,
                      not_tipo: 0,
                      not_url_imagen: "",
                      not_fecha: moment(new Date()).format("yyyy-MM-DD"),
                      not_titulo: "",
                      not_url: "",
                      not_estado: 0,
                      not_carrusel: 0,
                      note_codigo: 0,
                      not_descripcion: "",
                    };
                  });
                }}
              >
                Crear Noticia
              </Button>

              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
                />

                <div className="text-center">
                  <div
                    className={isLoadingTableMarca}
                    style={{ marginTop: 10 }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus Noticias
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {listNoticasEmpresa()}
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listNoticasEmpresa()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />

          {marcaoEmpresa()}

          {/* <div style={{marginTop:10}}>
        <Card >
              <Card.Header as="h5">Noticias de Marca</Card.Header>
              
              <Card.Body>
              <Button variant="primary" onClick={() => {setshowModal(true)
                                                  setnoticia(noticia => {
                                                    return { ...noticia,  not_tipo: 0,
                                                                          not_url_imagen: "",
                                                                          not_fecha:moment(new Date()).format("yyyy-MM-DD"),
                                                                          not_titulo: "",
                                                                          not_url: "",
                                                                          not_estado: 0,
                                                                          not_carrusel: 0,
                                                                          note_codigo:0,
                                                                          not_descripcion: "" }}); 
                                                
                                                }} >Crear Noticia</Button>



                <div style={{marginTop:10}}>                                  
              <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
              />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>

        
              </div>

              </Card.Body>
          </Card>

          <div style={{marginTop:10}}>

            <Card>
            <Card.Header as="h5">Elija una empresa Para Ver sus Noticias</Card.Header>
            <Card.Body>

              <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                      <option value="0">N/A</option>
                      {getRowsEmpresas}

                </Form.Control>

                <div style={{marginTop:5}} className="text-center">
                <div className={isLoading}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            </div>
                </Card.Body>
                </Card>
              </div>


            {listNoticasEmpresa()}

      </div> */}

          <Row>
            <div>
              <Modal
                show={showModal}
                // onHide={!showModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Crear Noticia
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Titulo: </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese un Titulo"
                        id="not_titulo"
                        value={noticia.not_titulo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setnoticia((noticia) => {
                            return { ...noticia, not_titulo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorNoticia.titulo}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Form.Label className="title-create">
                      Imagen de Noticia:{" "}
                    </Form.Label>

                    <FormGroup>{modalFoto(noticia.not_url_imagen)}</FormGroup>

                    <FormGroup>
                      <label className="btn btn-default">
                        <input
                          type="file"
                          onChange={selectFile}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </label>
                    </FormGroup>
                    <span style={{ color: "gray", fontSize: 12 }}>
                      El tamaño de la imagen debe ser 1200px * 700px y formato
                      .jpg
                    </span>

                    <span style={{ color: "red" }}>{errorNoticia.imagen}</span>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Activar en el Carrusel"
                        checked={setEstado(noticia.not_carrusel)}
                        onChange={() => {
                          var newStatus = 0;
                          if (noticia.not_carrusel === 0) {
                            newStatus = 1;
                          } else if (noticia.not_carrusel === 1) {
                            newStatus = 0;
                          }
                          setnoticia((noticia) => {
                            return { ...noticia, not_carrusel: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Fecha: </Form.Label>
                      <FormGroup>
                        <DatePicker
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            const val = moment(date).format("yyyy-MM-DD");

                            setstartDate(date);
                            setnoticia((noticia) => {
                              return { ...noticia, not_fecha: val };
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Url Noticia:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={3000}
                        placeholder="Ingrese Url Noticia"
                        id="not_url_notivcia"
                        value={noticia.not_url}
                        onChange={(e) => {
                          const val = e.target.value;
                          setnoticia((noticia) => {
                            return { ...noticia, not_url: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorNoticia.url}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col md="12" lg="12">
                      <Form.Label className="title-create">
                        Descripción de la Noticia{" "}
                      </Form.Label>
                      <ReactQuill
                        value={noticia.not_descripcion}
                        onChange={contentNoticia}
                      />

                      <span style={{ color: "red" }}>
                        {errorNoticia.descripcion}
                      </span>
                    </Col>
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => createNoticia()}>Crear Noticia</Button>
                  <Button
                    onClick={() => {
                      setshowModal(false);
                      seterrorNoticia((errorNoticia) => {
                        return {
                          ...errorNoticia,
                          imagen: "",
                          descripcion: "",
                          url: "",
                          titulo: "",
                        };
                      });

                      setnoticia((noticia) => {
                        return {
                          ...noticia,
                          not_tipo: 0,
                          not_url_imagen: "",
                          not_fecha: new Date(),
                          not_titulo: "",
                          not_url: "",
                          not_estado: 0,
                          not_carrusel: 0,
                          note_codigo: 0,
                          not_descripcion: "",
                        };
                      });
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>

                <div className="text-center">
                  <div className={isLoadingCreate} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Modal>
            </div>

            <div>
              <Modal
                show={showModalUpdate}
                // onHide={!showModalUpdate}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              >
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    Actualizar Noticia
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">Titulo: </Form.Label>
                      <Form.Control
                        maxLength={300}
                        placeholder="Ingrese un Titulo"
                        id="not_titulo"
                        value={noticia.not_titulo}
                        onChange={(e) => {
                          const val = e.target.value;
                          setnoticia((noticia) => {
                            return { ...noticia, not_titulo: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>
                        {errorNoticia.titulo}
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Form.Label className="title-create">
                      Imagen de Noticia:{" "}
                    </Form.Label>

                    <FormGroup>{modalFoto(noticia.not_url_imagen)}</FormGroup>

                    <FormGroup>
                      <label className="btn btn-default">
                        <input
                          type="file"
                          onChange={selectFile}
                          onClick={(e) => (e.target.value = null)}
                        />
                      </label>
                    </FormGroup>
                    <span style={{ color: "gray", fontSize: 12 }}>
                      El tamaño de la imagen debe ser 1200 * 700
                    </span>

                    <span style={{ color: "red" }}>{errorNoticia.imagen}</span>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Check
                        className="title-create"
                        type="checkbox"
                        label="Activar en el Carrusel"
                        checked={setEstado(noticia.not_carrusel)}
                        onChange={() => {
                          var newStatus = 0;
                          if (noticia.not_carrusel === 0) {
                            newStatus = 1;
                          } else if (noticia.not_carrusel === 1) {
                            newStatus = 0;
                          }
                          setnoticia((noticia) => {
                            return { ...noticia, not_carrusel: newStatus };
                          });
                        }}
                      />
                    </Col>

                    <Col>
                      <Form.Label className="title-create">Fecha: </Form.Label>
                      <FormGroup>
                        <DatePicker
                          selected={startDate}
                          dateFormat="dd/MM/yyyy"
                          onChange={(date) => {
                            const val = moment(date).format("yyyy-MM-DD");

                            setstartDate(date);
                            setnoticia((noticia) => {
                              return { ...noticia, not_fecha: val };
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </FormGroup>

                  <FormGroup as={Row}>
                    <Col>
                      <Form.Label className="title-create">
                        Url Noticia:{" "}
                      </Form.Label>
                      <Form.Control
                        maxLength={3000}
                        placeholder="Ingrese Url Noticia"
                        id="not_url_notivcia"
                        value={noticia.not_url}
                        onChange={(e) => {
                          const val = e.target.value;
                          setnoticia((noticia) => {
                            return { ...noticia, not_url: val };
                          });
                        }}
                      />

                      <span style={{ color: "red" }}>{errorNoticia.url}</span>
                    </Col>
                  </FormGroup>

                  <FormGroup>
                    <Col md="12" lg="12">
                      <Form.Label className="title-create">
                        Descripción de la Noticia{" "}
                      </Form.Label>
                      <ReactQuill
                        value={noticia.not_descripcion}
                        onChange={contentNoticia}
                      />

                      <span style={{ color: "red" }}>
                        {errorNoticia.descripcion}
                      </span>
                    </Col>
                  </FormGroup>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => updateNoticia()}>
                    Actualizar Noticia
                  </Button>
                  <Button
                    onClick={() => {
                      setshowModalUpdate(false);

                      seterrorNoticia((errorNoticia) => {
                        return {
                          ...errorNoticia,
                          imagen: "",
                          descripcion: "",
                          url: "",
                          titulo: "",
                        };
                      });
                      setnoticia((noticia) => {
                        return {
                          ...noticia,
                          not_tipo: 0,
                          not_url_imagen: "",
                          not_fecha: new Date(),
                          not_titulo: "",
                          not_url: "",
                          not_estado: 0,
                          not_carrusel: 0,
                          note_codigo: 0,
                          not_descripcion: "",
                        };
                      });
                    }}
                  >
                    Cerrar
                  </Button>
                </Modal.Footer>

                <div className="text-center">
                  <div className={isLoadingCreate} style={{ marginTop: 10 }}>
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </Modal>
            </div>
          </Row>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
