import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Modal,
  Button,
  FormGroup,
  Form,
  Card,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Server } from "../../constants";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function Content() {
  const [privilegios_nombre, setprivilegios_nombre] = useState("");
  const [privilegios_descripcion, setprivilegios_descripcion] = useState("");
  const [dataTable, setdataTable] = useState([]);
  const [showModal, setshowModal] = useState(false);
  const [privilegios, setprivilegios] = useState();

  const [index, setIndex] = useState();

  const columns = [
    {
      dataField: "PRI_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "PRI_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "PRI_DESCRIPCION",
      text: "Descripción",
    },
    {
      dataField: "PRI_ESTADO",
      text: "Estado",
      formatter: (cell, row, index) => activeDesactive(row, index),
      sort: true,
    },

    {
      dataField: "ROL_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const changeEstado = async (item, index) => {
    if (item.PRI_ESTADO === 1) {
      item.PRI_ESTADO = 0;
    } else if (item.PRI_ESTADO === 0) {
      item.PRI_ESTADO = 1;
    }
    update(item, index);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const editprivilegios = (row, index) => {
    setprivilegios_nombre(row.PRI_NOMBRE);
    setprivilegios_descripcion(row.PRI_DESCRIPCION);
    setprivilegios(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDataprivilegios = () => {
    privilegios.PRI_NOMBRE = privilegios_nombre;
    privilegios.PRI_DESCRIPCION = privilegios_descripcion;
    setprivilegios_nombre("");
    setprivilegios_descripcion("");
    setshowModal(false);
    update(privilegios, index);
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editprivilegios(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const activeDesactive = (row, index) => {
    return (
      <input
        type="checkbox"
        name="something"
        value="asa"
        checked={setEstado(row.PRI_ESTADO)}
        onChange={() => changeEstado(row, index)}
      />
    );
  };

  const createprivilegios = async () => {
    if (privilegios_nombre !== "" && privilegios_descripcion !== "") {
      await axios
        .post(Server+"/api/privilegios/create", {
          pri_nombre: privilegios_nombre,
          pri_descripcion: privilegios_descripcion,
        })
        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");

            setprivilegios_descripcion("");
            setprivilegios_nombre("");

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {});
    } else {
      showMessage("Campos incompletos", "error");
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    // setIsLoading("visible")
    await axios
      .get(Server+"/api/privilegios/get_all_privilegios")
      .then(function (response) {
        setdataTable(response.data);
        // setIsLoading("invisible")
      })
      .catch(function (error) {})
      .then(function () {}); 
      return;
  };

  const update = (dataUpdate, index) => {
    axios
      .put(Server+"/api/privilegios/updatePrivilegio", {
        pri_id: dataUpdate.PRI_CODIGO,
        pri_nombre: dataUpdate.PRI_NOMBRE,
        pri_descripcion: dataUpdate.PRI_DESCRIPCION,
        pri_estado: dataUpdate.PRI_ESTADO,
      })
      .then(function (response) {
        if (response.data.success === true) {
          showMessage(response.data.message, "success");

          let newArr = [...dataTable];
          newArr[index] = dataUpdate;
          setdataTable(newArr);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar Privilegios
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Nombre:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={150}
                              type="text"
                              placeholder="Nombre de Privilegios"
                              value={privilegios_nombre}
                              onChange={(event) =>
                                setprivilegios_nombre(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Form.Label column sm="2">
                            {" "}
                            Descripción:{" "}
                          </Form.Label>
                          <Col sm="10">
                            <Form.Control
                              maxLength={100}
                              type="text"
                              placeholder="Descripción de Privilegios"
                              value={privilegios_descripcion}
                              onChange={(event) =>
                                setprivilegios_descripcion(event.target.value)
                              }
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDataprivilegios()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setprivilegios_nombre("");
                          setprivilegios_descripcion("");
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de Privilegios</h3>
                <Card>
                  <Card.Header as="h5">Creción de "Privilegios" </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Nombre:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={150}
                            type="text"
                            placeholder="Nombre del Privilegio"
                            value={privilegios_nombre}
                            onChange={(event) =>
                              setprivilegios_nombre(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Form.Label column sm="2">
                          {" "}
                          Descripción:{" "}
                        </Form.Label>
                        <Col sm="10">
                          <Form.Control
                            maxLength={100}
                            type="text"
                            placeholder="Descripción del Privilegios"
                            value={privilegios_descripcion}
                            onChange={(event) =>
                              setprivilegios_descripcion(event.target.value)
                            }
                          />
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createprivilegios()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Privilegios"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="PRI_CODIGO"
                      data={dataTable}
                      columns={columns}
                    />
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
