import React, { Fragment } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import { Row, Col,Form } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import LogoHyundai from "../../images/assets/hyundai-logo.png";


function Content() {
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Dashboard"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
            <Col xs={6} md={7} className="mx-auto text-center p-3">
                <Image
                  src={LogoHyundai}
                  alt="logo hyundai"
                  fluid
                  className="text-center p-3"
                />
              </Col>

              <div className="text-center">
              <Form.Label className="title-create" style={{fontSize:40,color:'#002c5f'}} >Bienvenidos al Portal </Form.Label>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
