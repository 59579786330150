import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Card,
  FormGroup,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";

import axios from "axios";
import Swal from "sweetalert2";
import paginationFactory from "react-bootstrap-table2-paginator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTable, setdataTable] = useState([]);
  const [isLoadingTable, setisLoadingTable] = useState("invisible");
  const [isLoadingActualizar, setisLoadingActualizar] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [empresa, setempresa] = useState({
    emp_codigo: "",
    emp_id: "",
    emp_facebook: "",
    emp_instagram: "",
    emp_tiktok: "",
    emp_twitter: "",
    emp_linkedin: "",
    emp_youtube: "",
  });

  const columns = [
    {
      dataField: "EMP_RAZON_SOCIAL",
      text: "Razon Social",
      sort: true,
    },
    {
      dataField: "EMP_DIRECCION",
      text: "Dirección",
      sort: true,
    },

    {
      dataField: "EMP_FACEBOOK",
      text: "Facebook",
      sort: true,
    },
    {
      dataField: "EMP_INSTAGRAM",
      text: "Instagram",
      sort: true,
    },

    {
      dataField: "EMP_TIKTOK",
      text: "Tiktok",
      sort: true,
    },
    {
      dataField: "EMP_TWITTER",
      text: "Twitter",
      sort: true,
    },

    {
      dataField: "EMP_LINKEDIN",
      text: "Linkedin",
      sort: true,
    },

    {
      dataField: "EMP_YOUTUBE",
      text: "Youtube",
      sort: true,
    },

    {
      dataField: "EMP_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setempresa((empresa) => {
      return {
        ...empresa,
        emp_codigo: row.EMP_CODIGO,
        emp_id: row.EMP_ID,
        emp_facebook: row.EMP_FACEBOOK !== null ? row.EMP_FACEBOOK : "",
        emp_instagram: row.EMP_INSTAGRAM !== null ? row.EMP_INSTAGRAM : "",
        emp_tiktok: row.EMP_TIKTOK !== null ? row.EMP_TIKTOK : "",
        emp_twitter: row.EMP_TWITTER !== null ? row.EMP_TWITTER : "",
        emp_linkedin: row.EMP_LINKEDIN !== null ? row.EMP_LINKEDIN : "",
        emp_youtube: row.EMP_YOUTUBE !== null ? row.EMP_YOUTUBE : "",
      };
    });

    setshowModal(true);
  };

  const getEmpresas = async () => {
    setisLoadingTable("visible");
    await axios
      .get(Server+"/api/empresas/getListEmpresas")
      .then(function (response) {
        setisLoadingTable("invisible");

        setdataTable(response.data.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const getEmpresasForid = async (idempresa) => {
    setisLoadingTable("visible");
    await axios
      .get(
        Server+"/api/empresas/getListEmpresasForId/" +
          idempresa
      )
      .then(function (response) {
        setisLoadingTable("invisible");

        setdataTable(response.data.data);
      })
      .catch(function (error) {
        setisLoadingTable("invisible");
      })
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const updateEmpresa = async () => {
    const dataForm = new FormData();
    dataForm.append("emp_codigo", empresa.emp_codigo);
    dataForm.append("emp_id", empresa.emp_id);
    dataForm.append("emp_facebook", empresa.emp_facebook);
    dataForm.append("emp_instagram", empresa.emp_instagram);
    dataForm.append("emp_tiktok", empresa.emp_tiktok);
    dataForm.append("emp_twitter", empresa.emp_twitter);
    dataForm.append("emp_linkedin", empresa.emp_linkedin);
    dataForm.append("emp_youtube", empresa.emp_youtube);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    setisLoadingActualizar("visible");
    await axios
      .post(
        Server+"/api/empresas/updateEmpresaRedes",
        dataForm
      )

      .then(function (response) {
        setisLoadingActualizar("invisible");

        if (response.data.success) {
          showMessage(response.data.message, "success");

          setempresa((empresa) => {
            return {
              ...empresa,
              emp_codigo: "",
              emp_id: "",
              emp_facebook: "",
              emp_instagram: "",
              emp_tiktok: "",
              emp_twitter: "",
              emp_linkedin: "",
              emp_youtube: "",
            };
          });

          setshowModal(false);

          if (data.US_PERMISOS[0].PER_CODIGO === 1) {
            getEmpresas();
          } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
            getEmpresasForid(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
          }

          //getData()
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Campos incompletos", "error");
      });
  };

  useEffect(() => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      getEmpresasForid(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Empresa/ Empresa"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <Card>
                  <Card.Header as="h5">
                    Redes Sociales de "Empresas"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="EMP_CODIGO"
                      data={dataTable}
                      columns={columns}
                      pagination={paginationFactory()}
                    />

                    <div className="text-center">
                      <div className={isLoadingTable} style={{ marginTop: 10 }}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div>
                <Modal
                  show={showModal}
                  // onHide={!showModal}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                      Empresa Redes Sociales
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="text-center">
                      <div className={isLoadingActualizar}>
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>

                    <div style={{ marginTop: 10 }}>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Facebook:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Facebook"
                              id="empresa.emp_facebook"
                              value={empresa.emp_facebook}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_facebook: val };
                                });
                              }}
                            />
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Instagram:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Instagram"
                              id="empresa.emp_instagram"
                              value={empresa.emp_instagram}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return {
                                    ...empresa,
                                    emp_instagram: val,
                                  };
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Tiktok:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={100}
                              placeholder="Tiktok"
                              id="emp_tiktok"
                              value={empresa.emp_tiktok}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_tiktok: val };
                                });
                              }}
                            />
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Twitter:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Twitter"
                              id="emp_twitter"
                              value={empresa.emp_twitter}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_twitter: val };
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Linkedin:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Linkedin"
                              id="emp_linkedin"
                              value={empresa.emp_linkedin}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_linkedin: val };
                                });
                              }}
                            />
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Youtube:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={50}
                              placeholder="Youtube"
                              id="emp_youtube"
                              value={empresa.emp_youtube}
                              onChange={(e) => {
                                const val = e.target.value;
                                setempresa((empresa) => {
                                  return { ...empresa, emp_youtube: val };
                                });
                              }}
                            />
                          </Col>
                        </FormGroup>
                      </Form>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      onClick={() => {
                        updateEmpresa();
                      }}
                    >
                      Actualizar
                    </Button>
                    <Button
                      onClick={() => {
                        setshowModal(false);

                        setempresa((empresa) => {
                          return {
                            ...empresa,
                            emp_codigo: "",
                            emp_id: "",
                            emp_facebook: "",
                            emp_instagram: "",
                            emp_tiktok: "",
                            emp_twitter: "",
                            emp_linkedin: "",
                            emp_youtube: "",
                          };
                        });
                      }}
                    >
                      Cerrar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
