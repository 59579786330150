import React from "react";
import Dashboard from "../components/dashboard/Dashboard";
import ResizeDetector from 'react-resize-detector';

function ControlPanel() {
  return (
    <ResizeDetector handleWidth handleHeight>
      {({ width, height }) => <Dashboard width={width} height={height}/>}
    </ResizeDetector>
  );
}

export default ControlPanel;
