import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [model, setmodel] = useState({
    mod_codigo: 0,
    mod_id: "",
    mod_nombre: "",
    mod_descripcion: "",
    mod_categoria: "",
    mod_cat_vehiculo: "",
  });

  const [error, seterror] = useState({
    codigo: "",
    id: "",
    nombre: "",
    descripcion: "",
    categoria: "",
    cateogoriaVehiculo: "",
  });

  const [dataTable, setdataTable] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [showModal, setshowModal] = useState(false);

  const columns = [
    {
      dataField: "MOD_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "MOD_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "MOD_DESCRIPCION",
      text: "Descripción",
      sort: true,
    },
    {
      dataField: "MOD_TIPO_MODELO",
      text: "Modelo",
      formatter: (cell, row, index) => typeModelo(row, index),
      sort: true,
    },

    {
      dataField: "MOD_CATVEHICULO",
      text: "Categoria",
      formatter: (cell, row, index) => typeCategoria(row, index),
      sort: true,
    },
    {
      dataField: "MOD_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  useEffect(() => {
    getData();
  }, []);

  const typeModelo = (row, index) => {
    if (row.MOD_TIPO_MODELO === 1) {
      return (
        <h3 style={{ fontSize: 14 }} className="text-center">
          Autos
        </h3>
      );
    } else if (row.MOD_TIPO_MODELO === 2) {
      return (
        <h3 style={{ fontSize: 14 }} className="text-center">
          SUV
        </h3>
      );
    } else if (row.MOD_TIPO_MODELO === 3) {
      return (
        <h3 style={{ fontSize: 14 }} className="text-center">
          VAN
        </h3>
      );
    }
  };

  const typeCategoria = (row, index) => {
    if (row.MOD_CATVEHICULO === 1) {
      return (
        <h3 style={{ fontSize: 14 }} className="text-center">
          Livianos
        </h3>
      );
    } else if (row.MOD_CATVEHICULO === 2) {
      return (
        <h3 style={{ fontSize: 14 }} className="text-center">
          Pesados
        </h3>
      );
    }
  };

  const editModelo = (row, index) => {
    setmodel((model) => {
      return {
        ...model,
        mod_codigo: row.MOD_CODIGO,
        mod_id: row.MOD_ID,
        mod_nombre: row.MOD_NOMBRE,
        mod_descripcion: row.MOD_DESCRIPCION,
        mod_categoria: row.MOD_CATVEHICULO,
        mod_cat_vehiculo: row.MOD_CATVEHICULO,
      };
    });

    // setpermisos_nombre(row.PER_NOMBRE)
    // setpermisos_descripcion(row.PER_DESCRIPCION)
    // setpermisos(row)
    // setIndex(index)
    setshowModal(true);
  };

  const update = (dataUpdate) => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        nombre: "",
        descripcion: "",
        categoria: "",
        cateogoriaVehiculo: "",
      };
    });

    const dataForm = new FormData();
    dataForm.append("mod_categoria", dataUpdate.mod_categoria);
    dataForm.append("mod_codigo", dataUpdate.mod_codigo);
    dataForm.append("mod_id", dataUpdate.mod_id);
    dataForm.append("mod_nombre", dataUpdate.mod_nombre);
    dataForm.append("mod_descripcion", dataUpdate.mod_descripcion);
    dataForm.append("mod_cat_vehiculo", dataUpdate.mod_cat_vehiculo);
    dataForm.append("usuario", data.US_CODIGO);
    dataForm.append("_method", "PUT");

    if (model.mod_nombre !== "" && model.mod_descripcion !== "") {
      axios
        .post(
          Server+"/api/vehiculos/updateModelo",
          dataForm
        )
        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            setshowModal(false);
            setmodel((model) => {
              return {
                ...model,
                mod_codigo: 0,
                mod_id: "",
                mod_nombre: "",
                mod_descripcion: "",
                mod_categoria: "",
                mod_cat_vehiculo: "",
              };
            });
            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      if (model.mod_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (model.mod_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }

      showMessage("Datos Incompletos", "error");
    }
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editModelo(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const getData = async () => {
    setIsLoading("visible");

    await axios
      .get(Server+"/api/vehiculos/getAllModelos")
      .then(function (response) {
        setIsLoading("invisible");

        setdataTable(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const createModel = async () => {
    seterror((error) => {
      return {
        ...error,
        codigo: "",
        id: "",
        nombre: "",
        descripcion: "",
        categoria: "",
        cateogoriaVehiculo: "",
      };
    });

    const dataForm = new FormData();
    dataForm.append("mod_categoria", model.mod_categoria);
    dataForm.append("mod_codigo", model.mod_codigo);
    dataForm.append("mod_id", model.mod_id);
    dataForm.append("mod_nombre", model.mod_nombre);
    dataForm.append("mod_descripcion", model.mod_descripcion);
    dataForm.append("mod_cat_vehiculo", model.mod_cat_vehiculo);
    dataForm.append("usuario", data.US_CODIGO);

    if (
      model.mod_cat_vehiculo !== "" &&
      model.mod_cat_vehiculo !== "0" &&
      model.mod_categoria !== "" &&
      model.mod_categoria !== "0" &&
      model.mod_codigo !== "" &&
      model.mod_id !== "" &&
      model.mod_nombre !== "" &&
      model.mod_descripcion !== ""
    ) {
      await axios
        .post(
          Server+"/api/vehiculos/createModel",
          dataForm
        )

        .then(function (response) {
          if (response.data.success) {
            showMessage(response.data.message, "success");

            setmodel((model) => {
              return {
                ...model,
                mod_codigo: 0,
                mod_id: "",
                mod_nombre: "",
                mod_descripcion: "",
                mod_categoria: "",
                mod_cat_vehiculo: "",
              };
            });

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          showMessage("Error en el Intentalo de nuevo", "error");
        });
    } else {
      if (model.mod_cat_vehiculo === "" && model.mod_cat_vehiculo === "0") {
        seterror((error) => {
          return { ...error, categoria: "Seleccione una Categoria" };
        });
      }

      if (model.mod_categoria === "" && model.mod_categoria === "0") {
        seterror((error) => {
          return { ...error, cateogoriaVehiculo: "Seleccione una Categoria" };
        });
      }

      if (model.mod_codigo === "") {
        seterror((error) => {
          return { ...error, codigo: "Campo requerido" };
        });
      }

      if (model.mod_id === "") {
        seterror((error) => {
          return { ...error, id: "Campo requerido" };
        });
      }

      if (model.mod_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (model.mod_descripcion === "") {
        seterror((error) => {
          return { ...error, descripcion: "Campo requerido" };
        });
      }

      showMessage("Datos Incompletos", "error");
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <h3 className="text-center">Gestión de "MODELOS"</h3>
                <Card>
                  <Card.Header as="h5">Creción de NUEVO MODELO </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Código:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese Código"
                            id="mod_codigo"
                            value={model.mod_codigo}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_codigo: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.codigo}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">ID: </Form.Label>
                          <Form.Control
                            maxLength={6}
                            placeholder="Ingrese ID"
                            id="mod_id"
                            value={model.mod_id}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_id: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.id}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese Nombre"
                            id="mod_nombre"
                            value={model.mod_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_nombre: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Descripción:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese Descripción"
                            id="mod_descripcion"
                            value={model.mod_descripcion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_descripcion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.descripcion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Categoria:{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={model.mod_categoria}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_categoria: val };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            <option value="1">Autos</option>
                            <option value="2">SUV</option>
                            <option value="6">VAN</option>
                          </Form.Control>
                          <span style={{ color: "red" }}>
                            {error.categoria}
                          </span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Categoria de Vehiculo:{" "}
                          </Form.Label>
                          <Form.Control
                            as="select"
                            value={model.mod_cat_vehiculo}
                            onChange={(e) => {
                              const val = e.target.value;
                              setmodel((model) => {
                                return { ...model, mod_cat_vehiculo: val };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            <option value="1">Livianos</option>
                            <option value="2">Pesados</option>
                          </Form.Control>
                          <span style={{ color: "red" }}>
                            {error.cateogoriaVehiculo}
                          </span>
                        </Col>
                      </FormGroup>
                    </Form>
                  </Card.Body>

                  <div className="text-center">
                    <Button variant="primary" onClick={() => createModel()}>
                      Guardar cambios
                    </Button>
                  </div>
                </Card>
              </div>

              <Card>
                <Card.Header as="h5">Listado general de "Modelos"</Card.Header>
                <Card.Body>
                  <BootstrapTable
                    keyField="MOD_CODIGO"
                    data={dataTable}
                    columns={columns}
                  />

                  <div className="text-center">
                    <div className={isLoading}>
                      <Spinner animation="border" role="status"></Spinner>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <div>
            <Modal
              show={showModal}
              // onHide={!showModal}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                  Editar Modelos
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  <Card>
                    <Card.Body>
                      <Form>
                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Nombre:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={100}
                              placeholder="Ingrese Nombre"
                              id="mod_nombre"
                              value={model.mod_nombre}
                              onChange={(e) => {
                                const val = e.target.value;
                                setmodel((model) => {
                                  return { ...model, mod_nombre: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>{error.nombre}</span>
                          </Col>
                          <Col>
                            <Form.Label className="title-create">
                              Descripción:{" "}
                            </Form.Label>
                            <Form.Control
                              maxLength={100}
                              placeholder="Ingrese Descripción"
                              id="mod_descripcion"
                              value={model.mod_descripcion}
                              onChange={(e) => {
                                const val = e.target.value;
                                setmodel((model) => {
                                  return { ...model, mod_descripcion: val };
                                });
                              }}
                            />
                            <span style={{ color: "red" }}>
                              {error.descripcion}
                            </span>
                          </Col>
                        </FormGroup>

                        <FormGroup as={Row}>
                          <Col>
                            <Form.Label className="title-create">
                              Categoria:{" "}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={model.mod_categoria}
                              onChange={(e) => {
                                const val = e.target.value;
                                setmodel((model) => {
                                  return { ...model, mod_categoria: val };
                                });
                              }}
                            >
                              <option value="1">Autos</option>
                              <option value="2">SUV</option>
                              <option value="3">VAN</option>
                            </Form.Control>
                          </Col>

                          <Col>
                            <Form.Label className="title-create">
                              Categoria de Vehiculo:{" "}
                            </Form.Label>
                            <Form.Control
                              as="select"
                              value={model.mod_cat_vehiculo}
                              onChange={(e) => {
                                const val = e.target.value;
                                setmodel((model) => {
                                  return { ...model, mod_cat_vehiculo: val };
                                });
                              }}
                            >
                              <option value="1">Livianos</option>
                              <option value="2">Pesados</option>
                            </Form.Control>
                          </Col>
                        </FormGroup>
                      </Form>
                    </Card.Body>
                  </Card>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() => {
                    update(model);
                  }}
                >
                  Actualizar
                </Button>
                <Button
                  onClick={() => {
                    setshowModal(false);
                    setmodel((model) => {
                      return {
                        ...model,
                        mod_codigo: 0,
                        mod_id: "",
                        mod_nombre: "",
                        mod_descripcion: "",
                        mod_categoria: "",
                        mod_cat_vehiculo: "",
                      };
                    });
                  }}
                >
                  Cerrar
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
