import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Container,
  Card,
  Spinner,
  Form,
  Button,
  Modal,
  FormGroup,
} from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [dataTablenoticasMarca, setdataTablenoticasMarca] = useState([]);
  const [isLoadingTableMarca, setisLoadingTableMarca] = useState("invisible");
  const [getRowsEmpresas, setRowsEmpresas] = useState([]);
  const [isLoading, setIsLoading] = useState("invisible");
  const [showNoticasEmpresas, setshowNoticasEmpresas] = useState(false);
  const [dataTablenoticasEmpresa, setdataTablenoticasEmpresa] = useState([]);
  const [image2, setimage2] = useState("");
  const [isLoadingCreate, setisLoadingCreate] = useState("invisible");

  const [showModal, setshowModal] = useState(false);

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const [data] = useState(infoUsuario.data);

  const [noticia, setnoticia] = useState({
    not_codigo: 0,
    not_url_actualidad: "",
    note_codigo: 0,
    not_empresa: "0",
  });

  const columns = [
    {
      dataField: "NOT_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOT_ESTADO",
      text: "Noticia de Actualidad",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const columnsEmpresa = [
    {
      dataField: "NOTE_CODIGO",
      text: "Codigo",
      headerStyle: { width: 125 },
      sort: true,
    },
    {
      dataField: "NOT_TITULO",
      text: "Titulo",
      sort: true,
    },

    {
      dataField: "NOTE_ESTADO_ACTUALIDAD",
      text: "Noticia de Actualidad",
      headerStyle: { width: 200 },
      formatter: (cell, row, index) => editRowEmpresa(row, index),
      sort: true,
    },
  ];

  const modalFoto = (foto) => {
    if (foto !== "") {
      return (
        <img
          style={{
            marginLeft: 10,
            marginRight: 10,
            width: "90%",
            height: "40%",
          }}
          src={foto}
          alt=""
        />
      );
    } else {
      return <h1 style={{ fontSize: 12 }}>No existe Foto</h1>;
    }
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          edit(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const edit = (row, index) => {
    setimage2("");
    setnoticia((noticia) => {
      return {
        ...noticia,
        not_codigo: row.NOT_CODIGO,
        not_empresa: "0",
        note_codigo: 0,
        not_url_actualidad: row.NOT_FOTO_ACTUALIDAD,
      };
    });

    setshowModal(true);
  };

  const editRowEmpresa = (row, index) => {
    return (
      <Button
        onClick={() => {
          editEmpresa(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editEmpresa = (row, index) => {
    setimage2("");
    setnoticia((noticia) => {
      return {
        ...noticia,
        not_codigo: row.NOT_CODIGO,
        not_empresa: row.NOTE_EMPRESA,
        note_codigo: row.NOTE_CODIGO,
        not_url_actualidad: row.NOT_FOTO_ACTUALIDAD,
      };
    });

    setshowModal(true);
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  const updateActualidad = async () => {
    var newImagen = 0;

    if (image2 !== "") {
      newImagen = 1;
    }

    const data2 = new FormData();
    data2.append("file", image2);
    data2.append("not_new_imagen", newImagen);
    data2.append("not_codigo", noticia.not_codigo);
    data2.append("note_codigo", noticia.note_codigo);
    data2.append("not_empresa", noticia.not_empresa);
    data2.append("not_url_actualidad", noticia.not_url_actualidad);
    data2.append("usuario", data.US_CODIGO);

    setisLoadingCreate("visible");

    await axios
      .post(
        Server+"/api/empresas/activarActualidad",
        data2
      )

      .then(function (response) {
        if (response.data.success) {
          setisLoadingCreate("invisible");
          setshowModal(false);
          showMessage(response.data.message, "success");

          if (noticia.not_empresa === 0) {
            getNoticiasMarca();
          } else {
            getList(noticia.not_empresa);
          }
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        setisLoadingCreate("invisible");
        showMessage("Error en el Intentalo de nuevo", "error");
      });
  };

  const getList = async (idAlmacen) => {
    setshowNoticasEmpresas(false);

    if (idAlmacen !== "0") {
      setIsLoading("visible");

      await axios
        .get(
          Server+"/api/empresas/getNoticiasMarcaForEmpresa/" +
            idAlmacen
        )

        .then(function (response) {
          setIsLoading("invisible");
          setshowNoticasEmpresas(true);
          setdataTablenoticasEmpresa(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    }
  };

  const listNoticasEmpresa = () => {
    if (showNoticasEmpresas) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias</Card.Header>
            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasEmpresa}
                  columns={columnsEmpresa}
                  pagination={paginationFactory()}
                />
              </div>

              <div style={{ marginTop: 5 }} className="text-center">
                <div className={isLoading}>
                  <Spinner animation="border" role="status"></Spinner>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      );
    }
  };

  const getNoticiasMarca = async () => {
    setisLoadingTableMarca("visble");
    await axios
      .get(Server+"/api/empresas/getNoticiasMarca")

      .then(function (response) {
        setisLoadingTableMarca("invisible");
        setdataTablenoticasMarca(response.data.data);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const setEmpresaRows = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.EMP_CODIGO} value={row.EMP_CODIGO}>
        {row.EMP_NOMBRE_COMERCIAL}
      </option>
    ));
    setRowsEmpresas(rows);
  };

  const selectFile = (event) => {
    setimage2(event.target.files[0]);
    const val = URL.createObjectURL(event.target.files[0]);
    setnoticia((noticia) => {
      return { ...noticia, not_url_actualidad: val };
    });
  };

  useEffect(() => {
    const getDataEmpresas = async () => {
      await axios
        .get(
          Server+"/api/empresas/getListEmpresasActivas"
        )

        .then(function (response) {
          setEmpresaRows(response.data.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      getNoticiasMarca();
      getDataEmpresas();
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      setshowNoticasEmpresas(true);
      getList(data.US_EMPRESA_DEFAULT.EMP_CODIGO);
    }
  }, [data]);

  const marcaoEmpresa = () => {
    if (data.US_PERMISOS[0].PER_CODIGO === 1) {
      return (
        <div style={{ marginTop: 10 }}>
          <Card>
            <Card.Header as="h5">Noticias de Marca</Card.Header>

            <Card.Body>
              <div style={{ marginTop: 10 }}>
                <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
                />

                <div className="text-center">
                  <div
                    className={isLoadingTableMarca}
                    style={{ marginTop: 10 }}
                  >
                    <Spinner
                      animation="border"
                      role="status"
                      variant="secondary"
                    ></Spinner>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <div style={{ marginTop: 10 }}>
            <Card>
              <Card.Header as="h5">
                Elija una empresa Para Ver sus Noticias
              </Card.Header>
              <Card.Body>
                <Form.Control
                  as="select"
                  onChange={(e) => {
                    getList(e.target.value);
                  }}
                >
                  <option value="0">N/A</option>
                  {getRowsEmpresas}
                </Form.Control>

                <div style={{ marginTop: 5 }} className="text-center">
                  <div className={isLoading}>
                    <Spinner animation="border" role="status"></Spinner>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>

          {listNoticasEmpresa()}
        </div>
      );
    } else if (data.US_PERMISOS[0].PER_CODIGO === 2) {
      return <div>{listNoticasEmpresa()}</div>;
    }
  };

  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Principal/ Home"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          <Container fluid={true} className="content-body">
            <Row>
              <Col md="12" lg="12">
                {marcaoEmpresa()}
                {/* <div style={{marginTop:10}}>
        <Card >
              <Card.Header as="h5">Noticias de Marca</Card.Header>
              
              <Card.Body>
            
                <div style={{marginTop:10}}>                                  
              <BootstrapTable
                  keyField="NOT_CODIGO"
                  data={dataTablenoticasMarca}
                  columns={columns}
                  pagination={paginationFactory()}
              />

                <div className="text-center">
                          <div className={isLoadingTableMarca} style={{marginTop: 10}}>
                                                <Spinner animation="border" role="status" variant="secondary" >
                                                </Spinner>
                  </div>
                </div>

        
              </div>

              </Card.Body>
          </Card>

          <div style={{marginTop:10}}>

            <Card>
            <Card.Header as="h5">Elija una empresa Para Ver sus Noticias</Card.Header>
            <Card.Body>

              <Form.Control as="select" onChange={e => {getList(e.target.value)}}>

                      <option value="0">N/A</option>
                      {getRowsEmpresas}

                </Form.Control>

                <div style={{marginTop:5}} className="text-center">
                <div className={isLoading}>
                    <Spinner animation="border" role="status">
                    </Spinner>
                </div>
            </div>
                </Card.Body>
                </Card>
              </div>

              {listNoticasEmpresa()}


           

      </div> */}

                <div>
                  <Modal
                    show={showModal}
                    // onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Asignar como Noticia de Actualidad
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <FormGroup>
                        <Form.Label className="title-create">
                          Imagen de Noticia de Actualidad:{" "}
                        </Form.Label>

                        <FormGroup>
                          {modalFoto(noticia.not_url_actualidad)}
                        </FormGroup>

                        <FormGroup>
                          <label className="btn btn-default">
                            <input
                              type="file"
                              onChange={selectFile}
                              onClick={(e) => (e.target.value = null)}
                            />
                          </label>
                        </FormGroup>

                        <span style={{ color: "gray", fontSize: 12 }}>
                          El tamaño de la imagen debe ser 600px * 600px y
                          formato .jpg
                        </span>
                      </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        onClick={() => {
                          updateActualidad();
                        }}
                      >
                        Designar como Noticia Actualidad
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>

                    <div className="text-center">
                      <div
                        className={isLoadingCreate}
                        style={{ marginTop: 10 }}
                      >
                        <Spinner
                          animation="border"
                          role="status"
                          variant="secondary"
                        ></Spinner>
                      </div>
                    </div>
                  </Modal>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
