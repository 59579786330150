import React, { Fragment, useState, useEffect } from "react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import PageTitle from "../Layout/PageTitle";
import {
  Row,
  Col,
  Button,
  FormGroup,
  Form,
  Card,
  Spinner,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusSquare, faEdit } from "@fortawesome/free-solid-svg-icons";
import ModalInfoSelection from "../../components/screens/ModalInfoSelection";
import * as SecureLS from "secure-ls";
import {Server} from "../../constants";

function Content() {
  const [usuario, setUsuario] = useState({
    us_cedula: "",
    us_nombre: "",
    us_apellido: "",
    us_direccion: "",
    us_telefono: "",
    us_celular: "",
    us_email: "",
    us_usuario: "",
    us_contraseña: "",
    us_rep_contrasena: "",
    us_cargo: "",
    us_estado: 0,
  });

  const [error, seterror] = useState({
    nombre: "",
    usuario: "",
    cedula: "",
    direccion: "",
    telefonocon: "",
    apellido: "",
    correo: "",
    celular: "",
  });

  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { data } = infoUsuario;

  const [usuarios, setusuarios] = useState();
  const [index, setIndex] = useState();

  const [dataTableUser, setdataTableUser] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [getRowsModal, setRowsModal] = useState([]);
  const [getRowsCargo, setRowsCargo] = useState([]);

  const [showModal, setshowModal] = useState(false);

  const [isLoading, setIsLoading] = useState("invisible");

  const columns = [
    {
      dataField: "US_CODIGO",
      text: "Codigo",
      sort: true,
    },
    {
      dataField: "US_CEDULA",
      text: "Cédula",
      sort: true,
    },
    {
      dataField: "US_NOMBRE",
      text: "Nombre",
      sort: true,
    },
    {
      dataField: "US_APELLIDO",
      text: "Apellido",
      sort: true,
    },
    {
      dataField: "US_DIRECCION",
      text: "Dirección",
      sort: true,
    },

    {
      dataField: "US_TELEFONO",
      text: "Telefono",
      sort: true,
    },
    {
      dataField: "US_EMAIL",
      text: "Email",
      sort: true,
    },
    {
      dataField: "US_USUARIO",
      text: "Usuario",
      sort: true,
    },
    {
      dataField: "US_CONTRASENIA",
      text: "Contraseña",
      sort: true,
    },
    {
      dataField: "CAR_NOMBRE",
      text: "Cargo",
      sort: true,
    },
    {
      dataField: "US_ADD_ROL",
      formatter: (cell, row, index) => addRol(row, index),
      text: "Agregar Rol",
      sort: true,
    },
    {
      dataField: "US_ADD_PERMISOS",
      formatter: (cell, row, index) => addPermisos(row, index),
      text: "Agregar Permisos",
      sort: true,
    },

    {
      dataField: "US_ADD_EMPRESAS",
      formatter: (cell, row, index) => addEmpresas(row, index),
      text: "Agregar Empresas",
      sort: true,
    },

    {
      dataField: "US_EDITAR",
      text: "Editar",
      formatter: (cell, row, index) => editRow(row, index),
      sort: true,
    },
  ];

  const updateTableRol = async (row) => {
    await axios
      .get(
        Server+"/api/usuario/getUserRol/" + row.US_CODIGO
      )

      .then(function (response) {
        updateRowsRol(response.data.data, row);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const editRow = (row, index) => {
    return (
      <Button
        onClick={() => {
          editUsuario(row, index);
        }}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faEdit} />
      </Button>
    );
  };

  const editUsuario = (row, index) => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        usuario: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    setUsuario((usuario) => {
      return {
        ...usuario,
        us_cedula: row.US_CEDULA,
        us_nombre: row.US_NOMBRE,
        us_apellido: row.US_APELLIDO,
        us_direccion: row.US_DIRECCION,
        us_telefono: row.US_TELEFONO,
        us_celular: row.US_CELULAR,
        us_email: row.US_EMAIL,
        us_usuario: row.US_USUARIO,
        us_contraseña: row.US_CONTRASENIA,
        us_cargo: row.US_CARGO,
      };
    });
    setusuarios(row);
    setIndex(index);
    setshowModal(true);
  };

  const setDatamodulos = () => {
    usuarios.US_NOMBRE = usuario.us_nombre;
    usuarios.US_CEDULA = usuario.us_cedula;
    usuarios.US_APELLIDO = usuario.us_apellido;
    usuarios.US_DIRECCION = usuario.us_direccion;
    usuarios.US_TELEFONO = usuario.us_telefono;
    usuarios.US_CELULAR = usuario.us_celular;
    usuarios.US_EMAIL = usuario.us_email;
    usuarios.US_USUARIO = usuario.us_usuario;
    usuarios.US_CONTRASENIA = usuario.us_contraseña;
    usuarios.US_CARGO = usuario.us_cargo;
    usuarios.US_ESTADO = usuario.us_estado;

    seterror((error) => {
      return {
        ...error,
        nombre: "",
        usuario: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    setUsuario((usuario) => {
      return {
        ...usuario,
        us_cedula: "",
        us_nombre: "",
        us_apellido: "",
        us_direccion: "",
        us_telefono: "",
        us_celular: "",
        us_email: "",
        us_usuario: "",
        us_contraseña: "",
        us_cargo: "",
      };
    });

    setshowModal(false);
    update(usuarios, index);
  };

  const update = (dataUpdate, index) => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        usuario: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    if (
      usuario.us_cedula !== "" &&
      usuario.us_nombre !== "" &&
      usuario.us_apellido !== "" &&
      usuario.us_direccion !== "" &&
      usuario.us_telefono !== "" &&
      usuario.us_celular !== "" &&
      usuario.us_email !== "" &&
      usuario.us_usuario !== "" &&
      usuario.us_contraseña !== ""
    ) {
      const dataForm = new FormData();
      dataForm.append("us_id", dataUpdate.US_CODIGO);
      dataForm.append("us_cedula", dataUpdate.US_CEDULA);
      dataForm.append("us_nombre", dataUpdate.US_NOMBRE);
      dataForm.append("us_apellido", dataUpdate.US_APELLIDO);
      dataForm.append("us_direccion", dataUpdate.US_DIRECCION);
      dataForm.append("us_telefono", dataUpdate.US_TELEFONO);
      dataForm.append("us_celular", dataUpdate.US_CELULAR);
      dataForm.append("us_email", dataUpdate.US_EMAIL);
      dataForm.append("us_usuario", dataUpdate.US_USUARIO);
      dataForm.append("us_cargo", dataUpdate.US_CARGO);
      dataForm.append("us_estado", 1);
      dataForm.append("us_contrasena", dataUpdate.US_CONTRASENIA);
      dataForm.append("usuario", data.US_CODIGO);
      dataForm.append("_method", "PUT");

      axios
        .post(
          Server+"/api/usuario/updateUsuario",
          dataForm
        )

        .then(function (response) {
          if (response.data.success === true) {
            showMessage(response.data.message, "success");
            seterror((error) => {
              return {
                ...error,
                nombre: "",
                usuario: "",
                cedula: "",
                direccion: "",
                telefonocon: "",
                apellido: "",
                celular: "",
                correo: "",
              };
            });

            getData();
          } else {
            showMessage(response.data.message, "error");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      if (usuario.us_nombre === "") {
        seterror((error) => {
          return { ...error, nombre: "Campo requerido" };
        });
      }

      if (usuario.us_apellido === "") {
        seterror((error) => {
          return { ...error, apellido: "Campo requerido" };
        });
      }

      if (usuario.us_usuario === "") {
        seterror((error) => {
          return { ...error, usuario: "Campo requerido" };
        });
      }

      if (usuario.us_cedula === "") {
        seterror((error) => {
          return { ...error, cedula: "Campo requerido" };
        });
      }

      if (usuario.us_email === "") {
        seterror((error) => {
          return { ...error, correo: "Campo requerido" };
        });
      }

      if (usuario.us_direccion === "") {
        seterror((error) => {
          return { ...error, direccion: "Campo requerido" };
        });
      }

      if (usuario.us_telefono === "") {
        seterror((error) => {
          return { ...error, telefonocon: "Campo requerido" };
        });
      }

      if (usuario.us_celular === "") {
        seterror((error) => {
          return { ...error, celular: "Campo requerido" };
        });
      }
      showMessage("Campos incompletos", "error");
    }
  };

  const updateTablePermisos = async (row) => {
    await axios
      .get(
        Server+"/api/usuario/getListUserPermisos/" +
          row.US_CODIGO
      )

      .then(function (response) {
        updateRowsPermisos(response.data.data, row);
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const updateTableEmpresas = async (row) => {
    await axios
      .get(
        Server+"/api/usuario/getListUserEmpresas/" +
          row.US_CODIGO
      )

      .then(function (response) {
        if (response.data.success) {
          updateRowsEmpresas(response.data.data, row);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const addRol = (row, index) => {
    return (
      <Button
        onClick={() => updateTableRol(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
    );
  };

  const addPermisos = (row, index) => {
    return (
      <Button
        onClick={() => updateTablePermisos(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
    );
  };

  const addEmpresas = (row, index) => {
    return (
      <Button
        onClick={() => updateTableEmpresas(row)}
        color="primary"
        variant="contained"
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
    );
  };

  const selectRow = {
    mode: "radio",
    clickToSelect: true,

    onSelect: (row, isSelect) => {},
  };

  const setEstado = (estado) => {
    if (estado === 1) {
      return true;
    } else if (estado === 0) {
      return false;
    }
  };

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
  });

  const showMessage = (msn, type) => {
    Toast.fire({
      title: msn,
      // text: msn,
      icon: type,
      confirmButtonText: "Entendido",
    });
  };

  useEffect(() => {
    getData();

    const getCargo = async () => {
      await axios
        .get(Server+"/api/cargo/getallcargos")
        .then(function (response) {
          setDataCargo(response.data);
        })
        .catch(function (error) {})
        .then(function () {});
    };

    getCargo();
  }, []);

  const updateRowsRol = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        type="radio"
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        name="formHorizontalRadios"
        id="formHorizontalRadios2"
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoRol(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const updateRowsPermisos = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        type="radio"
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        name="formHorizontalRadios"
        id="formHorizontalRadios2"
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoPermisos(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const updateRowsEmpresas = (data, rowUser) => {
    const rows = data.map((row, index) => (
      <Form.Check
        key={row.OP_CODIGO}
        label={row.OP_NOMBRE}
        checked={setEstado(row.OP_ESTADO)}
        onChange={() => changeEstadoEmpresas(row, index, rowUser, data)}
      />
    ));

    setRowsModal(rows);
    setIsModalVisible(true);
  };

  const changeEstadoRol = async (row, index2, rowUser, data2) => {
    const dataForm = new FormData();
    dataForm.append("usr_usuario", rowUser.US_CODIGO);
    dataForm.append("usr_rol", row.OP_CODIGO);
    dataForm.append("usr_estado", 1);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(Server+"/api/usuario/activeRolUser", dataForm)

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data2.forEach((row2, index) => {
            if (data2[index].OP_CODIGO === row.OP_CODIGO) {
              data2[index].OP_ESTADO = 1;
            } else {
              data2[index].OP_ESTADO = 0;
            }
          });
          updateRowsRol(data2, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Error de Sercidor Intentalo de Nuevo", "error");
      });
  };

  const changeEstadoPermisos = async (row, index2, rowUser, data1) => {
    if (row.OP_ESTADO === 1) {
      row.OP_ESTADO = 0;
    } else if (row.OP_ESTADO === 0) {
      row.OP_ESTADO = 1;
    }

    const dataForm = new FormData();
    dataForm.append("uspe_usuario", rowUser.US_CODIGO);
    dataForm.append("uspe_permiso", row.OP_CODIGO);
    dataForm.append("uspe_estado", row.OP_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/usuario/activeUserPermisos",
        dataForm
      )

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data1.forEach((row2, index) => {
            if (data1[index].OP_CODIGO === row.OP_CODIGO) {
              data1[index].OP_ESTADO = 1;
            } else {
              data1[index].OP_ESTADO = 0;
            }
          });

          updateRowsPermisos(data1, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Campos incompletos", "error");
      });
  };

  const changeEstadoEmpresas = async (row, index2, rowUser, data1) => {
    if (row.OP_ESTADO === 1) {
      row.OP_ESTADO = 0;
    } else if (row.OP_ESTADO === 0) {
      row.OP_ESTADO = 1;
    }

    const dataForm = new FormData();
    dataForm.append("use_usuario", rowUser.US_CODIGO);
    dataForm.append("use_empresa", row.OP_CODIGO);
    dataForm.append("use_estado", row.OP_ESTADO);
    dataForm.append("usuario", data.US_CODIGO);

    axios
      .post(
        Server+"/api/usuario/activeUserEmpresa",
        dataForm
      )

      .then(function (response) {
        if (response.data.success) {
          showMessage(response.data.message, "success");

          data1[index2].OP_ESTADO = row.OP_ESTADO;

          updateRowsEmpresas(data1, rowUser);
        } else {
          showMessage(response.data.message, "error");
        }
      })
      .catch(function (error) {
        showMessage("Campos incompletos", "error");
      });
  };

  const setDataCargo = (data) => {
    const rows = data.map((row, index) => (
      <option key={row.CAR_CODIGO} value={row.CAR_CODIGO}>
        {row.CAR_NOMBRE}
      </option>
    ));

    setRowsCargo(rows);
  };

  const getData = async () => {
    setIsLoading("visible");
    await axios
      .get(Server+"/api/usuario/getallUser")
      .then(function (response) {
        setdataTableUser(response.data);
        setIsLoading("invisible");
      })
      .catch(function (error) {})
      .then(function () {});
  };

  const createUsuario = async () => {
    seterror((error) => {
      return {
        ...error,
        nombre: "",
        usuario: "",
        cedula: "",
        direccion: "",
        telefonocon: "",
        apellido: "",
        celular: "",
        correo: "",
      };
    });

    if (usuario.us_cargo === 0) {
      showMessage("Selecciona un Cargo", "error");
    } else {
      if (usuario.us_contraseña === usuario.us_rep_contrasena) {
        if (
          usuario.us_cedula !== "" &&
          usuario.us_nombre !== "" &&
          usuario.us_apellido !== "" &&
          usuario.us_direccion !== "" &&
          usuario.us_telefono !== "" &&
          usuario.us_celular !== "" &&
          usuario.us_email !== "" &&
          usuario.us_usuario !== "" &&
          usuario.us_contraseña !== ""
        ) {
          const dataForm = new FormData();
          dataForm.append("us_cedula", usuario.us_cedula);
          dataForm.append("us_nombre", usuario.us_nombre);
          dataForm.append("us_apellido", usuario.us_apellido);
          dataForm.append("us_direccion", usuario.us_direccion);
          dataForm.append("us_telefono", usuario.us_telefono);
          dataForm.append("us_celular", usuario.us_celular);
          dataForm.append("us_email", usuario.us_email);
          dataForm.append("us_usuario", usuario.us_usuario);
          dataForm.append("us_cargo", usuario.us_cargo);
          dataForm.append("us_estado", 1);
          dataForm.append("us_contrasena", usuario.us_contraseña);
          dataForm.append("usuario", data.US_CODIGO);

          await axios
            .post(Server+"/api/usuario/create", dataForm)

            .then(function (response) {
              if (response.data.success) {
                showMessage(response.data.message, "success");

                setUsuario((usuario) => {
                  return {
                    ...usuario,
                    us_cedula: "",
                    us_nombre: "",
                    us_apellido: "",
                    us_direccion: "",
                    us_telefono: "",
                    us_celular: "",
                    us_email: "",
                    us_usuario: "",
                    us_contraseña: "",
                    us_cargo: "",
                  };
                });

                getData();
              } else {
                showMessage(response.data.message, "error");
              }
            })
            .catch(function (error) {
              showMessage("Campos incompletos", "error");
            });
        } else {
          if (usuario.us_nombre === "") {
            seterror((error) => {
              return { ...error, nombre: "Campo requerido" };
            });
          }

          if (usuario.us_apellido === "") {
            seterror((error) => {
              return { ...error, apellido: "Campo requerido" };
            });
          }

          if (usuario.us_usuario === "") {
            seterror((error) => {
              return { ...error, usuario: "Campo requerido" };
            });
          }

          if (usuario.us_cedula === "") {
            seterror((error) => {
              return { ...error, cedula: "Campo requerido" };
            });
          }

          if (usuario.us_email === "") {
            seterror((error) => {
              return { ...error, correo: "Campo requerido" };
            });
          }

          if (usuario.us_direccion === "") {
            seterror((error) => {
              return { ...error, direccion: "Campo requerido" };
            });
          }

          if (usuario.us_telefono === "") {
            seterror((error) => {
              return { ...error, telefonocon: "Campo requerido" };
            });
          }

          if (usuario.us_celular === "") {
            seterror((error) => {
              return { ...error, celular: "Campo requerido" };
            });
          }
          showMessage("Campos incompletos", "error");
        }
      } else {
        showMessage("Contraseña no coincide", "error");
      }
    }
  };
  return (
    <Fragment>
      <ReactCSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <div>
          <PageTitle
            heading="Administracion/ Configuracion"
            icon="pe-7s-user icon-gradient bg-mean-fruit"
          />
          {/* <Container fluid={true} className="content-body"> */}
          <Row>
            <Col md="12" lg="12">
              <div>
                <div>
                  <Modal
                    show={showModal}
                    // onHide={!showModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <Modal.Header>
                      <Modal.Title id="contained-modal-title-vcenter">
                        Editar Usuario
                      </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese nombre"
                            id="us_nombre"
                            value={usuario.us_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_nombre: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese apellido"
                            id="us_apellido"
                            value={usuario.us_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_apellido: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese un nombre de usuario"
                            id="us_usuario"
                            value={usuario.us_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_usuario: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.usuario}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Cargo:{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_cargo: val };
                              });
                            }}
                          >
                            {/* <option value="0">N/A</option> */}
                            {getRowsCargo}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Cédula"
                            id="us_cedula"
                            value={usuario.us_cedula}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_cedula: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.cedula}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Correo electrónico:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Correo electrónico"
                            id="us_email"
                            type="email"
                            value={usuario.us_email}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_email: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.correo}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Ingrese una contraseña"
                            id="us_contraseña"
                            value={usuario.us_contraseña}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_contraseña: val };
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Repita Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Repita la contraseña"
                            id="us_rep_contrasena"
                            value={usuario.us_rep_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_rep_contrasena: val };
                              });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={300}
                            placeholder="Ingrese una dirección"
                            id="us_direccion"
                            value={usuario.us_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_direccion: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Teléfono convencional:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese teléfono"
                            id="us_telefono"
                            value={usuario.us_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_telefono: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.telefonocon}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Celular:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={15}
                            placeholder="Ingrese celular"
                            id="us_celular"
                            value={usuario.us_celular}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_celular: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.celular}</span>
                        </Col>
                      </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setDatamodulos()}>
                        Actualizar
                      </Button>
                      <Button
                        onClick={() => {
                          setshowModal(false);
                          setUsuario((usuario) => {
                            return {
                              ...usuario,
                              us_cedula: "",
                              us_nombre: "",
                              us_apellido: "",
                              us_direccion: "",
                              us_telefono: "",
                              us_celular: "",
                              us_email: "",
                              us_usuario: "",
                              us_contraseña: "",
                              us_cargo: "",
                            };
                          });
                        }}
                      >
                        Cerrar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </div>
                <div>
                  <ModalInfoSelection
                    show={isModalVisible}
                    onHide={() => setIsModalVisible(false)}
                    row={1}
                    listItems={getRowsModal}
                    selectRow={selectRow}
                  >
                    {" "}
                  </ModalInfoSelection>
                </div>
                <div></div>
                <h3 className="text-center">Gestión de "Usuarios"</h3>
                <Card>
                  <Card.Header as="h5">Creción de NUEVO USUARIO </Card.Header>
                  <Card.Body>
                    <Form>
                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Nombre:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese nombre"
                            id="us_nombre"
                            value={usuario.us_nombre}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_nombre: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.nombre}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Apellido:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese apellido"
                            id="us_apellido"
                            value={usuario.us_apellido}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_apellido: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>{error.apellido}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Usuario:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Ingrese un nombre de usuario"
                            id="us_usuario"
                            value={usuario.us_usuario}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_usuario: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.usuario}</span>
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Cargo:{" "}
                          </Form.Label>

                          <Form.Control
                            as="select"
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_cargo: val };
                              });
                            }}
                          >
                            <option value="0">N/A</option>
                            {getRowsCargo}
                          </Form.Control>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Cédula:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Cédula"
                            id="us_cedula"
                            value={usuario.us_cedula}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_cedula: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.cedula}</span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Correo electrónico:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={100}
                            placeholder="Correo electrónico"
                            id="us_email"
                            type="email"
                            value={usuario.us_email}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_email: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.correo}</span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Ingrese una contraseña"
                            id="us_contraseña"
                            value={usuario.us_contraseña}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_contraseña: val };
                              });
                            }}
                          />
                        </Col>

                        <Col>
                          <Form.Label className="title-create">
                            Repita Contraseña:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={250}
                            placeholder="Repita la contraseña"
                            id="us_rep_contrasena"
                            value={usuario.us_rep_contrasena}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_rep_contrasena: val };
                              });
                            }}
                          />
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Dirección:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese una dirección"
                            id="us_direccion"
                            value={usuario.us_direccion}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_direccion: val };
                              });
                            }}
                          />

                          <span style={{ color: "red" }}>
                            {error.direccion}
                          </span>
                        </Col>
                      </FormGroup>

                      <FormGroup as={Row}>
                        <Col>
                          <Form.Label className="title-create">
                            Teléfono convencional:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={13}
                            placeholder="Ingrese teléfono"
                            id="us_telefono"
                            value={usuario.us_telefono}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_telefono: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>
                            {error.telefonocon}
                          </span>
                        </Col>
                        <Col>
                          <Form.Label className="title-create">
                            Celular:{" "}
                          </Form.Label>
                          <Form.Control
                            maxLength={15}
                            placeholder="Ingrese celular"
                            id="us_celular"
                            value={usuario.us_celular}
                            onChange={(e) => {
                              const val = e.target.value;
                              setUsuario((usuario) => {
                                return { ...usuario, us_celular: val };
                              });
                            }}
                          />
                          <span style={{ color: "red" }}>{error.celular}</span>
                        </Col>
                      </FormGroup>

                      <div className="text-center">
                        <Button
                          variant="primary"
                          onClick={() => createUsuario()}
                        >
                          Guardar cambios
                        </Button>
                      </div>
                    </Form>
                  </Card.Body>
                </Card>

                <br></br>

                <Card>
                  <Card.Header as="h5">
                    Listado general de "Usuario"
                  </Card.Header>
                  <Card.Body>
                    <BootstrapTable
                      keyField="US_CODIGO"
                      data={dataTableUser}
                      columns={columns}
                    />

                    <div className="text-center">
                      <div className={isLoading}>
                        <Spinner animation="border" role="status"></Spinner>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </Col>
          </Row>
          {/* </Container> */}
        </div>
      </ReactCSSTransitionGroup>
    </Fragment>
  );
}

export default Content;
