import React, { Fragment } from "react";
import MetisMenu from "react-metismenu";
import * as SecureLS from "secure-ls";

function Navbarmenu() {
  const ls = new SecureLS({
    encodingType: "des",
    isCompression: false,
    encryptionSecret: "admin-hyundai",
  });
  const { infoUsuario } = ls.get("ask-hy");
  const { US_MODULO } = infoUsuario.data;

  return (
    <Fragment>
      {US_MODULO.map((modulo) => (
        <Fragment key={modulo.MO_CODIGO}>
          <h5 className="app-sidebar__heading">{modulo.MO_NOMBRE}</h5>
          {modulo.MO_MENU.map((menu) => (
            <Fragment key={menu.MEN_CODIGO}>
              <MetisMenu
                key={menu.MEN_CODIGO}
                content={[
                  {
                    icon: menu.MEN_ICONO,
                    label: menu.MEN_NOMBRE,
                    content: menu.MEN_OPCIONES,
                  },
                ]}
                activeLinkFromLocation
                className="vertical-nav-menu"
                iconNamePrefix=""
                classNameStateIcon="pe-7s-angle-down"
              />
            </Fragment>
          ))}
        </Fragment>
      ))}
    </Fragment>
  );
}

export default Navbarmenu;
